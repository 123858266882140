import React, { useState, useEffect, useRef } from 'react';
import { Input, List, Avatar, Badge, Spin, Card } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { BASE_URL, BASE_URL_CB } from '../../config';

const DeveloperSearch = ({ developers = [], onSelectDeveloper }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredDevelopers, setFilteredDevelopers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const searchRef = useRef(null);

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (!query) {
      setFilteredDevelopers([]);
      setDropdownVisible(false);
      return;
    }

    setLoading(true);
    setDropdownVisible(true);

    // Filter developers by name
    const results = developers.filter((dev) =>
      dev.name.toLowerCase().includes(query)
    );

    setFilteredDevelopers(results);
    setLoading(false);
  };

  const handleSelect = (developer) => {
    if (onSelectDeveloper) {
      onSelectDeveloper(developer);
    }
    setDropdownVisible(false); // Close dropdown on select
    setSearchQuery(''); // Clear search query after selection
  };

  return (
    <div style={{ width: '100%', position: 'relative' }} ref={searchRef}>
      Search for a developer shop
      <Input
        placeholder="Search developers shop by name.."
        value={searchQuery}
        onChange={handleSearch}
        onFocus={() => searchQuery && setDropdownVisible(true)} // Show dropdown if query exists
        allowClear
        style={{ marginBottom: '20px' }}
      />

      {/* Dropdown */}
      {dropdownVisible && (
        <Card style={{marginBottom:'20px'}}>
          {loading ? (
            <div>
              <Spin size="small" />
            </div>
          ) : filteredDevelopers.length > 0 ? (
            <List
              itemLayout="horizontal"
              dataSource={filteredDevelopers}
              renderItem={(dev) => (
                  <Link
                    to={`/marketplace/developer/${dev.owner_id}`}
                    style={{ textDecoration: 'none', width: '100%' }}
                  >
 {dev.name}{' '}
                  </Link>
              )}
            />
          ) : (
            <div style={{ textAlign: 'center', color: '#999', padding: '10px' }}>
              No developers found
            </div>
          )}
        </Card>
      )}
    </div>
  );
};

export default DeveloperSearch;
