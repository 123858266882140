import React, { useState, useEffect, useRef  } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Button, Space, List, message, Avatar, Popconfirm, Mentions, Typography, Tag, Row, Col } from 'antd';
import { CaretUpOutlined, CaretDownOutlined, EyeOutlined, UserOutlined } from '@ant-design/icons';
import axios from 'axios';
import axiosInstance from '../axiosConfig';
import { BASE_URL, BASE_URL_CB } from '../config';

const { Option } = Mentions;
const { Title, Text, Link } = Typography;

const ReleaseDetailPage = () => {
  const [release, setRelease] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [editCommentId, setEditCommentId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editorVisible, setEditorVisible] = useState(false);
  const [user, setUser] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const { releaseId } = useParams();
  const hasIncrementedView = useRef(false); // Ref to ensure view increment happens only once

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get('/auth/user', {
        });
        if (response.data) {
          setUser(response.data);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchReleaseDetail = async () => {
      try {
        const response = await axiosInstance.get(`/releases/${releaseId}`);
        setRelease(response.data);
      } catch (error) {
        console.error('Error fetching release details:', error);
      }
    };

    const fetchComments = async () => {
      try {
        const response = await axiosInstance.get(`/releases/${releaseId}/comments`);
        setComments(response.data);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    const fetchUsersForMentions = async () => {
      try {
        const response = await axiosInstance.get(`/releases/${releaseId}/users`);
        setUsersList(response.data);
      } catch (error) {
        console.error('Error fetching users for mentions:', error);
      }
    };

    fetchUser();
    fetchReleaseDetail();
    fetchComments();
    fetchUsersForMentions();
  }, [releaseId]);

  useEffect(() => {
    const incrementViewCount = async () => {
      if (releaseId && !hasIncrementedView.current) {
        try {
          await axios.put(`/releases/${releaseId}/views`, {}, { withCredentials: true });
          hasIncrementedView.current = true; // Ensure this is only called once
        } catch (error) {
          console.error('Error incrementing view count:', error);
        }
      }
    };

    incrementViewCount();
  }, [releaseId]);

  const handleVote = async (voteType) => {
    try {
      const response = await axiosInstance.post(
        `/releases/${releaseId}/vote`,
        { vote_type: voteType },
      );
      message.success(`You have ${voteType}d the release.`);
      setRelease(response.data); // Update release data after voting
    } catch (error) {
      console.error('Error submitting vote:', error);
      message.error('Failed to submit vote. Please log in.');
    }
  };

  const handleCommentSubmit = async () => {
    try {
      await axiosInstance.post(
        `/releases/${releaseId}/comments`,
        { comment: newComment },
      );
      setNewComment('');
      setEditorVisible(false);
      message.success('Comment added successfully!');
      const response = await axiosInstance.get(`/releases/${releaseId}/comments`);
      setComments(response.data);
    } catch (error) {
      console.error('Error submitting comment:', error);
      message.error('Failed to submit comment. Please log in.');
    }
  };

  const handleEditClick = (comment) => {
    setEditCommentId(comment.id);
    setNewComment(comment.comment);
    setIsEditing(true);
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await axiosInstance.delete(`/releases/${releaseId}/comments/${commentId}`, {
      });
      message.success('Comment deleted successfully.');
      const response = await axiosInstance.get(`/releases/${releaseId}/comments`);
      setComments(response.data);
    } catch (error) {
      console.error('Error deleting comment:', error);
      message.error('Failed to delete comment.');
    }
  };

  // Function to render embedded video if available
  const renderVideo = () => {
    const url = release?.description;
    const youtubeRegex = /(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const vimeoRegex = /vimeo\.com\/(\d+)/;

    const youtubeMatch = url?.match(youtubeRegex);
    const vimeoMatch = url?.match(vimeoRegex);

    if (youtubeMatch) {
      const videoId = youtubeMatch[1];
      return (
        <iframe
          width="100%"
          height="400"
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          style={{ borderRadius: '10px', overflow: 'hidden' }}
        ></iframe>
      );
    }

    if (vimeoMatch) {
      const videoId = vimeoMatch[1];
      return (
        <iframe
          width="100%"
          height="400"
          src={`https://player.vimeo.com/video/${videoId}`}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Vimeo video"
          style={{ borderRadius: '10px', overflow: 'hidden' }}
        ></iframe>
      );
    }

    return null;
  };

  return (
    <Row gutter={[10, 10]} style={{ paddingLeft: '15vw', paddingRight: '15vw', marginTop: '20px' }}>
      <Card title={
        <Space>
          <Avatar shape='square' src={release?.user_img_url ? `${BASE_URL}${release?.user_img_url}` : null}
              icon={!release?.user_img_url && <UserOutlined />}
              size={64} />
          <Space style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Title level={3} style={{ margin: '0px' }}>{release?.name}</Title>
            <Text type="secondary">By <Link to=''>{release?.nickname}</Link> on {new Date(release?.created_at).toLocaleDateString()}</Text>
          </Space>
        </Space>}
        extra={<Button type="primary" onClick={() => window.open(release?.source_url, '_blank')}>
          View on {release?.source === 'tebex' ? 'Tebex' : 'GitHub'}
        </Button>} style={{ marginTop: '20px' }}>

        <Row gutter={[20, 20]}>
          <Col md={14}>
            <Card style={{ height: '500px', overflow: 'auto' }}><div dangerouslySetInnerHTML={{ __html: release?.description }} /></Card>
            <div style={{ marginTop: '10px', width: '100%' }}>
              <Button onClick={() => handleVote('upvote')}>Upvote</Button>
              <Button onClick={() => handleVote('downvote')}>Downvote</Button>
              {!editorVisible && (
                <Button onClick={() => setEditorVisible(true)}>Start New Comment</Button>
              )}
              <div style={{ float: 'right' }}>
                <Tag color="default">
                  <EyeOutlined /> {release?.views}
                </Tag>
                <Tag color="green">
                  <CaretUpOutlined /> {release?.upvotes}
                </Tag>
                <Tag color="red">
                  <CaretDownOutlined /> {release?.downvotes}
                </Tag>
              </div>
            </div>
          </Col>

          <Col md={10}>
            <Card title="Release Details">
              <Tag>{release?.source === 'tebex' ? 'Tebex' : 'GitHub'}</Tag>
              <Tag>{release?.category}</Tag>
              {release?.price && parseFloat(release.price) !== 0 ? (
                <Tag color="purple">Paid: {release.price} {release.currency}</Tag>
              ) : (
                <Tag color="green">Free</Tag>
              )}
              {release?.subcategory && <Tag>{release.subcategory}</Tag>}
            </Card>
            {renderVideo()}
            {release?.image && (
              <img
                src={`${release.image}`}
                alt={release.name}
                style={{ width: '100%', maxHeight: '400px', objectFit: 'cover', borderRadius: '10px' }}
              />
            )}
          </Col>
        </Row>
      </Card>

      {editorVisible && (
        <>
          <Mentions
            style={{ width: '100%', minHeight: '100px', padding: '10px' }}
            value={newComment}
            onChange={(value) => setNewComment(value)}
            placeholder="Add your comment and mention users with '@'"
          >
            {usersList.map((user) => (
              <Option key={user.id} value={user.display}>
                {user.display}
              </Option>
            ))}
          </Mentions>
          <Button onClick={handleCommentSubmit} type="primary" style={{ marginTop: '10px' }}>
            Submit Comment
          </Button>
        </>
      )}

      <Col md={24}>
        <Card>
          <List
            itemLayout="horizontal"
            dataSource={comments}
            renderItem={(comment) => (
              <List.Item
                actions={user && user.userId === comment.user_id
                  ? [
                    <Button onClick={() => handleEditClick(comment)}>Edit</Button>,
                    <Popconfirm
                      title="Are you sure you want to delete this comment?"
                      onConfirm={() => handleDeleteComment(comment.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button danger>Delete</Button>
                    </Popconfirm>,
                  ]
                  : null}
              >
                <List.Item.Meta
                  avatar={<Avatar src={`${BASE_URL}${comment.user_img_url}`} />}
                  title={comment.display_name}
                  description={<div dangerouslySetInnerHTML={{ __html: comment.comment }} />}
                />
              </List.Item>
            )}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default ReleaseDetailPage;
