import React from 'react';
import { Card, Typography, Button } from 'antd';

const { Title, Paragraph } = Typography;

const UserControlPanel = ({ onSwitchToDeveloper }) => (
  <Card style={{ maxWidth: 800, margin: '50px auto', textAlign: 'center' }}>
    <Title level={2}>Welcome to your Control Panel</Title>
    <Paragraph>
      As a normal user, you can browse, vote, and participate in the community. If you want to become a developer, click
      the button below to set up your profile.
    </Paragraph>
    <Button type="primary" onClick={onSwitchToDeveloper}>
      Switch to Developer
    </Button>
  </Card>
);

export default UserControlPanel;
