import React, { useState, useEffect } from 'react';
import { Typography, Form, Input, Button, Checkbox, Card, Row, Col, Upload, Tag, Select, message, Space, Tabs , Flex, Badge, Descriptions, List, Tooltip, Table, Divider, Alert} from 'antd';
import { PlusOutlined, LoadingOutlined, CopyrightCircleTwoTone, ShopFilled, ShopOutlined, CheckOutlined } from '@ant-design/icons';
import axios from 'axios';
import DeveloperCreationForm from './DeveloperCreationForm';  
import Title from 'antd/es/skeleton/Title';
import { UserOutlined, CheckCircleOutlined, CloseCircleOutlined,CrownOutlined,CreditCardOutlined, TrophyOutlined, UsergroupAddOutlined, BarChartOutlined, GithubOutlined, CaretUpOutlined, SettingOutlined, DiscordFilled, ApiOutlined,SafetyCertificateOutlined, StarOutlined,FileDoneOutlined, FireOutlined} from '@ant-design/icons';
import ShopManager from './controlpanel/ShopManager'; 
import axiosInstance from '../../../axiosConfig';
import { BASE_URL, BASE_URL_CB } from '../../../config';
import Description from './controlpanel/Description';
import tabItems from './controlpanel/tabItems';

const { Option } = Select; 
const { TextArea } = Input;
// Debounce function to delay API calls
const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

// Function to check uniqueness (for Tebex and nickname)
const checkUniqueData = async (field, value) => {
  try {
    const response = await axiosInstance.post('/check-unique', {
      field,
      value,
    });
    if (response.data.exists) {
      return Promise.reject(new Error(`${field} is already in use.`));
    }
    return Promise.resolve();
  } catch (error) {
    console.error(`Error checking unique ${field}:`, error);
    throw new Error('Failed to check uniqueness.');
  }
};


const ControlPanel = () => {
  const [developerData, setDeveloperData] = useState({
    nickname: '',
    motto: '',
    total_votes: '',
    is_premium:'',
    total_vote_ever: '',
    overall_rating: '',
    category: [],
    games: [],
    tags: [],
    user_img_url: '',
    profile_bg_url: '',
    ranking_img_url: '',
    tebex_url: '',
    github_url: '',
    discord_url: '',
    discord_channel_id: '',
    is_available_for_hire: '',
    verified: '',
    is_team: '',
    team_members: '',
  });
  const [activeTabKey, setActiveTabKey] = useState('1'); // Initial active tab key
  const [developerExists, setDeveloperExists] = useState(true);
  const [user, setUser] = useState({ userId: '', username: '' });
  const [loadingDeveloper, setLoadingDeveloper] = useState(false);
  const [loadingDiscord, setLoadingDiscord] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState({});
  const [loadingGitHub, setloadingGitHub] = useState({});
  const [formDeveloper] = Form.useForm();
  const [formGitHub] = Form.useForm(); // GitHub Form instance
  const [formTebex] = Form.useForm(); // Tebex Form instance
  const [formDiscord] = Form.useForm();
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputTeamVisible, setInputTeamVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const botInviteLink = `https://discord.com/oauth2/authorize?client_id=1287858555663290489&permissions=1689504842935360&integration_type=0&scope=applications.commands+bot`;
  const [nicknameValidating, setNicknameValidating] = useState(false);
  const [tebexUrlValidating, setTebexUrlValidating] = useState(false);
  const [nicknameError, setNicknameError] = useState(null);
  const [tebexUrlError, setTebexUrlError] = useState(null);
  const [nicknameSuccess, setNicknameSuccess] = useState(null);
  const [tebexUrlSuccess, setTebexUrlSuccess] = useState(null);
  const [loadingTebex, setLoadingTebex] = useState(false); // Added for Tebex loading
  const [messageApi, contextHolder] = message.useMessage();
  const { Title, Text, Link, Paragraph  } = Typography;
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);

  useEffect(() => {
    const fetchUserAndDeveloperData = async () => {
      try {
        const userResponse = await axiosInstance.get('/auth/user', { withCredentials: true });
        console.log('Fetched user data:', userResponse.data);
    
        // Map the response to ensure `userId` exists
        setUser({
          userId: userResponse.data.userId || userResponse.data.id, // Use `id` if `userId` is missing
          username: userResponse.data.username,
        });
    
        fetchDeveloperData(); // Load additional developer data
      } catch (error) {
        console.error('Error fetching user data:', error);
        handleLogout();
      }
    };
    fetchUserAndDeveloperData();
  }, []);
  

  useEffect(() => {
    const fetchDeveloperData = async () => {
        try {
            const response = await axiosInstance.get('/developer');

            setDeveloperData(response.data);
            setSelectedTeamMembers(
                response.data.team_members
                    ? response.data.team_members.split(',').map((nickname) => ({ id: nickname, nickname }))
                    : []
            );
        } catch (error) {
            message.error('Failed to fetch developer data');
        }
    };

    fetchDeveloperData();
}, []);


  
  const handleAddTeamMember = async (nickname) => {
    try {
        if (!developerData.id) {
            message.error('Developer ID is missing');
            return;
        }

        const response = await axiosInstance.post('/developer/team_members', {
            developerId: developerData.id, // Pass the developer ID
            userId: nickname, // Pass the user ID (or nickname)
        });

        if (response.status === 200) {
            setSelectedTeamMembers((prev) => [...prev, { id: nickname, nickname }]);
            setDeveloperData((prev) => ({
                ...prev,
                team_members: response.data.team_members, // Update the team_members string
            }));
            message.success('Team member added successfully');
        }
    } catch (error) {
        message.error(error.response?.data?.error || 'Error adding team member');
    }
};

const handleRemoveTeamMember = async (teamMemberNickname) => {
  try {
      if (!developerData.nickname) {
          message.error('Developer nickname is missing');
          return;
      }

      const response = await axiosInstance.delete(`/developer/team_members/${teamMemberNickname}`, {
          params: {
              developerNickname: developerData.nickname, // Pass developerNickname as a query parameter
          },
      });

      if (response.status === 200) {
          // Update the team members in both state variables
          setSelectedTeamMembers((prev) => prev.filter((member) => member.nickname !== teamMemberNickname));
          setDeveloperData((prev) => ({
              ...prev,
              team_members: response.data.team_members, // Update the team_members string
          }));
          message.success('Team member removed successfully');
      }
  } catch (error) {
      message.error(error.response?.data?.error || 'Error removing team member');
  }
};

  const fetchDeveloperData = async () => {
    try {
      const response = await axiosInstance.get('/developer', {
       
      });
      setDeveloperExists(true);
      setDeveloperData(response.data);
      setTags(response.data.tags ? response.data.tags.split(',') : []);
      formDeveloper.setFieldsValue({
        ...response.data,
        category: response.data.category ? response.data.category.split(',') : [],
        games: response.data.games ? response.data.games.split(',') : [],
      });
      formDiscord.setFieldsValue(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setDeveloperExists(false);
      } else {
        console.error('Error fetching developer data:', error);
      }
    }
  };
  


 const showMessage = (messageApi, type, content) => {
    messageApi.open({
      type,
      content,
    });
  };

  const handleSaveDeveloper = async (values) => {
    setLoadingDeveloper(true);
  
    try {
      // Merge existing team_members if not provided
      values.team_members = values.team_members || developerData.team_members;
  
      // Handle tags, category, and games
      values.tags = tags.join(',');
      values.category = values.category ? values.category.join(',') : '';
      values.games = values.games ? values.games.join(',') : '';
  
      // Update the developer data in the backend
      await axiosInstance.put('/developer', values);
  
      // Update local state
      setDeveloperData((prev) => ({
        ...prev,
        ...values,
      }));
  
      showMessage(messageApi, 'success', 'Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      showMessage(messageApi, 'error', 'Failed to update profile.');
    } finally {
      setLoadingDeveloper(false);
    }
  };
  
  
  

  const handleSaveDiscord = async (values) => {
    setLoadingDiscord(true);
    try {
      await axiosInstance.put('/developer', values, {
      
      });
      showMessage(messageApi, 'success', 'Discord information updated successfully!');
    } catch (error) {
      if (error.response && error.response.data.exists) {
        showMessage(messageApi, 'error', `${error.response.data.field} is already in use.`);
      } else {
        console.error('Error updating Discord information:', error);
        showMessage(messageApi, 'error', 'Error updating Discord information');
      }
    } finally {
      setLoadingDiscord(false);
    }
  };
  

  const handleLogout = async () => {
    try {
      await axiosInstance.post('/auth/logout', {}, );
      setUser(null);
      window.location.href = '/';
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };
  

  const isTokenExpired = (decodedToken) => {
    if (!decodedToken || !decodedToken.exp) return true;
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  };

  const handleTagClose = (removedTag) => {
    setTags(tags.filter(tag => tag !== removedTag));
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleInputConfirmMember = async () => {
    if (!inputValue.trim()) {
        message.error("Member nickname cannot be empty.");
        return;
    }

    if (!developerData.nickname) {
        message.error("Your developer nickname is missing.");
        return;
    }

    // Check for duplicates
    if (selectedTeamMembers.some((member) => member.nickname === inputValue.trim())) {
        message.warning("This member is already added.");
        return;
    }

    try {
        const response = await axiosInstance.post("/developer/team_members", {
            developerNickname: developerData.nickname, // Current developer's nickname
            teamMemberNickname: inputValue.trim(), // New member's nickname
        });

        if (response.status === 200) {
            // Update the team members in the UI
            setSelectedTeamMembers((prev) => [...prev, { nickname: inputValue.trim() }]);
            setDeveloperData((prev) => ({
                ...prev,
                team_members: response.data.team_members, // Update the team_members string
            }));
            message.success("Team member added successfully.");
        }
    } catch (error) {
        console.error("Error response:", error.response?.data || error.message);
        message.error(error.response?.data?.error || "Error adding team member.");
    } finally {
        setInputValue(""); // Clear the input field
        setInputVisible(false); // Hide the input field
    }
};


  const handleUploadChange = (key, { fileList }) => {
    // Update fileList state
    setFileList((prev) => ({
      ...prev,
      [key]: fileList,
    }));

    // If the file is uploaded successfully
    if (fileList.length && fileList[0].status === 'done') {
      const url = fileList[0].response.url; // Assuming server returns { url: 'image_url' }

      // Immediately update the developerData state to show the new image
      setDeveloperData((prevData) => ({
        ...prevData,
        [key]: url,
      }));
      showMessage(messageApi, 'success', 'Image uploaded successfully!');
    }
  };

  const [fileList, setFileList] = useState({
    ranking_img_url: [],
    user_img_url: [],
    profile_bg_url: []
  });

  const uploadProps = (key) => ({
    fileList: fileList[key],
    name: 'file',
    action: '/upload', // Backend upload URL
    data: { type: key },
    onChange: (info) => handleUploadChange(key, info),
    maxCount: 1,  // Limit the upload to 1 file
    showUploadList: false,  // Hide the file list
    withCredentials: true, // Use cookies for authentication
  });
  

  const handleFinish = () => {
    setDeveloperExists(true);
    fetchDeveloperData(); // No need to pass token explicitly
  };
  
  
  // Tebex Link Validation Function
  const validateTebexUrl = debounce(async (value) => {
    setTebexUrlValidating(true);
    const regex = /^https?:\/\/[a-zA-Z0-9\-]+\.tebex\.io(\/[a-zA-Z0-9\-\/]*)?$/;
    if (!regex.test(value)) {
      setTebexUrlError('Tebex link must be in the format https://subdomain.tebex.io or https://subdomain.tebex.io/subfolder');
      setTebexUrlValidating(false);
      return;
    }

    try {
      await checkUniqueData('tebex_url', value);
      setTebexUrlError(null);
      setTebexUrlSuccess('Tebex link can be used.');
    } catch (error) {
      setTebexUrlError(error.message);
      setTebexUrlSuccess(null);
    }
    setTebexUrlValidating(false);
  }, 1000);

  // Nickname Validation Function (Similar to Tebex)
  const debouncedCheckNickname = debounce(async (value) => {
    setNicknameValidating(true);
    try {
      await checkUniqueData('nickname', value);
      setNicknameError(null);
      setNicknameSuccess('Nickname can be used.');
    } catch (error) {
      setNicknameError(error.message);
      setNicknameSuccess(null);
    }
    setNicknameValidating(false);
  }, 1000);

  const data = [
    { label: ' GitHub profile link ', value: developerData.github_url },
    { label: ' GitHub public token', value: developerData.github_token },
    { label: ' Tebex store link', value: developerData.tebex_url },
    { label: ' Tebex public token', value: developerData.tebex_token },
    { label: ' Discord link', value: developerData.discord_url },
    { label: ' Discord Channel ID', value: developerData.discord_channel_id },
    { label: ' Discord Server Guild ID', value: developerData.discord_guild_id },
    { label: ' Discord Vote BOT in server', value: developerData.discord_bot_invited },
    { label: ' At least 250 members in discord', value: developerData.discord_total_members >= 250 },
    { label: ' At least 25 customers in discord', value: developerData.discord_total_customers >= 50 },
    { label: ' Public support email', value: developerData.public_email },
    { label: ' At least 10 Release', value: developerData.total_release_count >= 10 },
    { label: ' At least 500 total votes', value: developerData.total_vote_ever >= 500 },
    { label: ' Select at least 1 game', value: developerData.games.length > 0 },
    { label: ' Select at least 1 category', value: developerData.category.length > 0 },
    { label: ' Be active and give support', value: 'active_support' },
    
  ];
  const allConfirmed = data.every(item => item.value);
  
  const handleVerificationRequest = async () => {
    try {
      const response = await axiosInstance.post(
        '/verification/request',
        {
          developer_id: developerData.id,
          developer_username: developerData.nickname,
          developer_contact: developerData.email || 'contact@example.com',
        },
      );
      console.log(response.data.message);
      message.success(response.data.message);
    } catch (error) {
      console.error('Error submitting verification request:', error);
      message.error('Failed to submit verification request.');
    }
  };
  
  
  
  const data_premium = [
    {
      key: '1',
      feature: 'Featured developers',
      normalUser: <CheckCircleOutlined style={{color:'green'}}/>,
      premiumUser: <span><CheckCircleOutlined style={{color:'green'}}/> + 50% chance</span>,
    },
    {
      key: '2',
      feature: 'Release of the month',
      normalUser: <CloseCircleOutlined style={{color:'red'}} />,
      premiumUser: <CheckCircleOutlined style={{color:'green'}}/>,
    },
    {
      key: '3',
      feature: 'Profile & Ranking background',
      normalUser: <CloseCircleOutlined style={{color:'red'}} />,
      premiumUser: <CheckCircleOutlined style={{color:'green'}}/>,
    },
    {
      key: '4',
      feature: 'Custom discord voting bot',
      normalUser: <CloseCircleOutlined style={{color:'red'}} />,
      premiumUser: <CheckCircleOutlined style={{color:'green'}}/>,
    },
    {
      key: '5',
      feature: 'Rotational ADS',
      normalUser: <CloseCircleOutlined style={{color:'red'}} />,
      premiumUser: <CheckCircleOutlined style={{color:'green'}}/>,
    },
    {
      key: '6',
      feature: 'Shop manager',
      normalUser: <CloseCircleOutlined style={{color:'red'}} />,
      premiumUser: <span><CheckCircleOutlined style={{color:'green'}}/> <span style={{float:'right'}}>You must be <Tag color='green'><CheckCircleOutlined /> Verified</Tag> </span></span>,
    },
    {
      key: '7',
      feature: 'Voting Boost',
      normalUser: <Tag color='red'>+ 0 Votes monthly</Tag>,
      premiumUser: <Tag color='green'>+ 100 Votes monthly</Tag>,
    },
    {
      key: '8',
      feature: 'Contract fee',
      normalUser: '5%',
      premiumUser: '1%',
    },
    {
      key: '8',
      feature: 'Support',
      normalUser: 'Standard Support',
      premiumUser: 'Priority Support',
    },
  ];

  // Define columns for the table
  const columns_premium = [
    {
      title: 'Feature',
      dataIndex: 'feature',
      key: 'feature',
    },
    {
      title: 'Normal User',
      dataIndex: 'normalUser',
      key: 'normalUser',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Premium User',
      dataIndex: 'premiumUser',
      key: 'premiumUser',
      render: (text) => <span>{text}</span>,
    },
  ];
  

  
// Badge images mapping
const badgeImages = {
  "Top 1": "./uploads/achievements/win.gif",
  "Top 10": "https://i.ibb.co/nc2Q15z/award-9663888.png",
  "ROTM": "https://i.ibb.co/8B8tD1t/cinema-9663884.png",
  "Verified": "https://cdn-icons-png.flaticon.com/512/5972/5972778.png",
  // Add more badges as needed
};

const onChange = (key) => {
  console.log(key);
};









  return developerExists ? (
    <>
      {contextHolder}
      <Row gutter={[20,20]} style={{marginLeft:'0px',marginRight:'0px',marginTop:'50px', marginBottom:'50px'}}>
      <Description
      
        developerData={developerData}
        setActiveTabKey={setActiveTabKey}
        badgeImages={badgeImages}
      />


<Tabs
  style={{ width: '100vw', paddingLeft: '15vw', paddingRight: '15vw' }}
  items={tabItems({
    formDeveloper,
    handleSaveDeveloper,
    developerData,
    setActiveTabKey,
    loadingDeveloper,
    handleSaveDiscord,
    formDiscord,
    loadingDiscord,
    botInviteLink,
    // Pass missing props here
    nicknameValidating,
    nicknameError,
    nicknameSuccess,
    setDeveloperData,
    debouncedCheckNickname,
    tags,
    handleTagClose,
    inputVisible,
    inputValue,
    setInputValue,
    handleInputConfirm,
    setInputVisible,
    uploadProps,
    messageApi,
    BASE_URL,
    tebexUrlValidating,
    tebexUrlError,
    tebexUrlSuccess,
    validateTebexUrl,
    loadingTebex,
    allConfirmed,
    handleVerificationRequest,
    data,
    user,
    columns_premium,
    data_premium,
    handleAddTeamMember,
    handleRemoveTeamMember,
    selectedTeamMembers,
    handleInputConfirmMember,
    setInputTeamVisible,
    inputTeamVisible,
  })}
  activeKey={activeTabKey}
  onChange={(key) => setActiveTabKey(key)}
/>



  
      </Row>
    </>
  ) : (
    <DeveloperCreationForm onFinish={handleFinish} />
  );
};

export default ControlPanel;
