import React, { useState, useEffect, useContext } from 'react';

import { useParams, useNavigate } from 'react-router-dom'; // Import useParams and useNavigate
import { Card, Menu, Row, Col, Typography, Button, Divider, Tag, Tooltip, Empty, Steps, message,  Popconfirm, Input, Select, Avatar, Badge, List, InputNumber  } from 'antd';
import { ShopOutlined, UserOutlined, CopyrightCircleTwoTone, GiftTwoTone, CopyOutlined, GiftFilled, CarryOutFilled, CloseCircleOutlined, CheckCircleOutlined, ShopFilled  } from '@ant-design/icons';
import axios from 'axios';
import axiosInstance from '../axiosConfig';
import { BASE_URL, BASE_URL_CB } from '../config';
import DeveloperDropdown from './components/DeveloperDropdown';
import { ThemeContext } from './components/utils/ThemeContext';


const { Title, Text, Link } = Typography;
const { Search } = Input;
const { Step } = Steps;
const { Option } = Select;

const Marketplace = (setUserBalance ) => {
  const { isDarkMode } = useContext(ThemeContext);
  const { developerId } = useParams();
  const [shopView, setShopView] = useState('mainShop'); 
  const [mainShopItems, setMainShopItems] = useState([]);
  const [developerShops, setDeveloperShops] = useState([]);
  const [developerItems, setDeveloperItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [filter, setFilter] = useState('All'); 
  const [searchQuery, setSearchQuery] = useState(''); 
  const [priceMode, setPriceMode] = useState('min');
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [inStockFilter, setInStockFilter] = useState(false);
  const [discountFilter, setDiscountFilter] = useState(0);
  const [giftBalanceFilter, setGiftBalanceFilter] = useState(null);
  const [linkedReleaseFilter, setLinkedReleaseFilter] = useState(false);
  const darkGradient = `linear-gradient(0deg, rgba(0, 0, 0, 0.8) 10%, rgba(0, 0, 0, 1) 100%)`;
  const lightGradient = `linear-gradient(0deg, rgba(255, 255, 255, 0.8) 10%, rgba(255, 255, 255, 1) 100%)`;



  const navigate = useNavigate();

  const fetchUserData = async () => {
    try {
      const userResponse = await axiosInstance.get('/auth/user'); 
      if (userResponse.status === 200) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false); 
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setIsLoggedIn(false); 
    }
  };

  const fetchMainShopItems = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/shop-items/main-shop');
      const items = response.data;
      console.log("Main Shop Items:", items);
      setMainShopItems(items);
  
      if (items.length > 0) {
        const prices = items.map((item) => item.price_in_coins);
        setMinPrice(Math.min(...prices));
        setMaxPrice(Math.max(...prices));
      } else {
        setMinPrice(0);
        setMaxPrice(0);
      }
    } catch (error) {
      console.error('Error fetching main shop items:', error);
    } finally {
      setLoading(false);
    }
  };
  

const handlePurchase = async (itemId) => {
  try {
    const response = await axiosInstance.post('/shop-items/purchase', { shop_item_id: itemId });
    message.success(response.data.message);


    if (setUserBalance) {
      const coinResponse = await axiosInstance.get('/shop-items/user/coins');
      setUserBalance(coinResponse.data.coins);
    }

    fetchMainShopItems();
  } catch (error) {
    console.error('Error during purchase:', error);

  }
};

const fetchDeveloperItems = async (developerId) => {
  setLoading(true);
  console.log("Fetching items for Developer ID:", developerId);
  try {
    const response = await axiosInstance.get(`/shop-items/owner/${developerId}`);
    const items = response.data;
    setDeveloperItems(items);

    if (items.length > 0) {
      const prices = items.map((item) => item.price_in_coins);
      setMinPrice(Math.min(...prices));
      setMaxPrice(Math.max(...prices));
    } else {
      setMinPrice(0);
      setMaxPrice(0);
    }
  } catch (error) {
    console.error('Error fetching developer items:', error);
  } finally {
    setLoading(false);
  }
};

  
  const fetchDeveloperShops = async () => {
    try {
      const response = await axiosInstance.get('/shop-items/owner');
      setDeveloperShops(response.data);
    } catch (error) {
      console.error('Error fetching developer shops:', error);
    }
  };

  
  useEffect(() => {
    fetchMainShopItems();
    fetchDeveloperShops();
    fetchUserData();
  }, []); // Empty array ensures it runs once on mount

  useEffect(() => {
    fetchDeveloperShops();
    if (developerId) {
      setShopView(developerId);
      fetchDeveloperItems(developerId);
    } else {
      setShopView('mainShop');
      fetchMainShopItems();
    }
  }, [developerId]); // Re-fetch items when developerId changes
  

  const handleMenuClick = (key) => {
    console.log("Selected Developer ID:", key);
    setFilter('All'); // Reset filter to default value
    setShopView(key);
    if (key === 'mainShop') {
      fetchMainShopItems();
    } else {
      fetchDeveloperItems(key);
    }
  };
  
  const handlePriceChange = (value) => {
    if (priceMode === 'min') {
      setMinPrice(value || 0); // Update min price
    } else if (priceMode === 'max') {
      setMaxPrice(value || 0); // Update max price
    }
  };
  
  const handleModeChange = (value) => {
    setPriceMode(value); // Update the mode to 'min' or 'max'
  };

  
  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const applyFilter = (items) => {
    let filteredItems = items;
  
    // Apply item type filter
    if (filter !== 'All') {
      filteredItems = filteredItems.filter((item) =>
        filter === 'giftcard'
          ? item.item_type === 'balance_card'
          : item.item_type === 'discount_code'
      );
    }
  
    // Apply search query filter
    if (searchQuery) {
      filteredItems = filteredItems.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  
    // Apply min and max price filter
    filteredItems = filteredItems.filter(
      (item) =>
        (!minPrice || item.price_in_coins >= minPrice) &&
        (!maxPrice || item.price_in_coins <= maxPrice)
    );
  
    // Apply in-stock filter
    if (inStockFilter) {
      filteredItems = filteredItems.filter((item) => item.stock > 0);
    }
  
    // Apply discount percentage filter
    if (discountFilter > 0) {
      filteredItems = filteredItems.filter(
        (item) => item.item_type === 'discount_code' && item.discount_balance >= discountFilter
      );
    }
  
    // Apply gift balance filter
    if (giftBalanceFilter !== null) {
      filteredItems = filteredItems.filter(
        (item) =>
          item.item_type === 'balance_card' && item.gift_card_balance >= giftBalanceFilter
      );
    }
  
    // Apply linked release filter
    if (linkedReleaseFilter === true) {
      filteredItems = filteredItems.filter((item) => item.linked_release);
    } else if (linkedReleaseFilter === 'notLinked') {
      filteredItems = filteredItems.filter((item) => !item.linked_release);
    }
  
    return filteredItems;
  };
  
  
  
  
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const selectBefore = (
    <Select
      value={priceMode}
      onChange={handleModeChange} // Change mode on selection
      style={{ width: 100 }}
    >
      <Option value="min">Min</Option>
      <Option value="max">Max</Option>
    </Select>
  );
  


  
  const renderItems = (items) => {
    if (!items || items.length === 0) {
      return <Empty description="No items available" />;
    }

    const filteredItems = applyFilter(items);
  
    const getDeveloperName = (owner_id) => {
      const dev = developerShops.find((dev) => dev.owner_id === owner_id);
      return dev ? dev.name : 'Unknown Developer';
    };

    console.log("Developer Shops:", developerShops);
console.log("Current Shop View:", shopView); 

    return (
      
      <Row gutter={[20, 20]} style={{marginTop:'50px'}}>
        
        {filteredItems.map((item) => (
  <Col span={8} key={item.id}>
    <Card
    style={{height:'100%', display:'flex', flexDirection:'column', justifyContent:'space-between', }}
    bodyStyle={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.8)), url(${item.linked_releases_image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      color: 'white',
      height:'100%',      
    }}
      hoverable
      title={<Text>{item.name}</Text>}
      actions={[
        <div style={{ padding: '0 10px' }}>
          <Popconfirm
            title={`Are you sure you want to buy ${item.name} for ${item.price_in_coins} coins?`}
            onConfirm={() => handlePurchase(item.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="large" style={{ width: '100%' }}>
              Buy for <strong>{item.price_in_coins}</strong>
              <CopyrightCircleTwoTone />
            </Button>
          </Popconfirm>
        </div>,
      ]}
    >
      <Row>
      <Col md={24}>
      <Text >Linked package: </Text>

      <Link href={`/release/${item.linked_release}`}>
      {item.linked_releases_name}
      </Link>
      </Col>
      <Col md={24}>
      <Text style={{fontSize:'12px', fontWeight:'400', color:'#888'}}>Developer: {getDeveloperName(item.owner_id)}</Text>
      </Col>
      <Divider></Divider>
      <Col md={24}>
      <Text type="secondary" style={{fontSize:'12px'}}>{item.description}</Text>
      </Col>


     
      <Divider />
      <Col md={24}>
      {item.item_type === 'discount_code' ? (
        <Tag style={{width:'fit-content'}} color='green'>{item.discount_balance}% Discount</Tag>
      ) : (
        <Tag style={{width:'fit-content'}} color='blue'>{item.gift_card_balance}€ Gift Card</Tag>
      )}
            <Tag style={{width:'fit-content'}}>{item.stock} in Stock</Tag>
            <Tag style={{width:'fit-content'}}>Price: € {item.linked_releases_price}</Tag>
            </Col>
            </Row>
    </Card>
  </Col>
))}

      </Row>
    );
  };

  return (
    <Row gutter={[50, 20]} style={{ padding: '2vw 10vw', marginTop: '50px' }}>
{/* Sidebar Menu */}
<Col span={6}>


  <Row gutter={[20,20]}>
    <Col  md={24}>
    <Card>
  <DeveloperDropdown
          developers={developerShops || []} // Fallback to an empty array
          onSelectDeveloper={(developerId) => handleMenuClick(developerId)}
        />
    <Menu
      mode="inline"
      defaultSelectedKeys={['mainShop']}
      onClick={(e) => handleMenuClick(e.key)}
    >
      {/* Main Shop */}
      <Menu.ItemGroup
        key="featuredShops"
        title={
          <Text>
            Marketplace
          </Text>
        }
      >
      <Menu.Item key="mainShop" icon={<ShopOutlined />} onClick={() => navigate('/marketplace')}>
  Top Modders
</Menu.Item>
</Menu.ItemGroup>
      {/* Featured Shops Section */}
      <Menu.Divider />
      <Menu.ItemGroup
        key="featuredShops"
        title={
          <Text>
            Featured Shops
          </Text>
        }
      >
        {/* Quasar */}
        <Menu.Item key="quasar">
          <img
            src="https://www.quasar-store.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ficon.d3957389.png&w=256&q=75"
            width={20}
            alt="Quasar"
            style={{ marginRight: '10px', borderRadius: '5px' }}
          />
          Quasar
        </Menu.Item>

        {/* Redutzu */}
        <Menu.Item key="redutzu">
          <img
            src="https://cdn.tebex.io/webstore/1093448/images/1093448-947a21a052a452908fc99d006eb5d5df38954891.png"
            width={20}
            alt="Redutzu"
            style={{ marginRight: '10px', borderRadius: '5px' }}
          />
          Redutzu
        </Menu.Item>

        {/* ScriptForge */}
        <Menu.Item key="scriptForge">
          <img
            src="https://cdn.tebex.io/store/1336907/templates/171688/assets/eceb08c47fafe3ece1c0961eecc926c60f148a11.png"
            width={20}
            alt="ScriptForge"
            style={{ marginRight: '10px', borderRadius: '5px' }}
          />
          ScriptForge
        </Menu.Item>

        {/* MoonStore */}
        <Menu.Item key="moonStore">
          <img
            src="https://dunb17ur4ymx4.cloudfront.net/webstore/logos/732e2499dba9daf31d1e5b29a6fef0737f24284c.png"
            width={20}
            alt="MoonStore"
            style={{ marginRight: '10px', borderRadius: '5px' }}
          />
          MoonStore
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  </Card>
    </Col>

    <Col md={24}>
    <Card
  style={{
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `${isDarkMode ? darkGradient : lightGradient}, url("https://albertadentalimplants.ca/wp-content/uploads/2022/10/Placeholder-Ads-.jpg")`,
    height: '150px',
  }}
  title={
    <div style={{ display: 'flex', alignItems: 'center' }}>
      Advertisement Slot
    </div>
  }
  extra={
    <Button size='small' type="default">
      Go to website
    </Button>
  }
>
Your ads here!<br></br>
      <Text type='secondary'>Second line</Text>
    </Card>
    </Col>

    <Col md={24}>
    <Card
  style={{
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `${isDarkMode ? darkGradient : lightGradient}, url("https://albertadentalimplants.ca/wp-content/uploads/2022/10/Placeholder-Ads-.jpg")`,
    height: '150px',
  }}
  title={
    <div style={{ display: 'flex', alignItems: 'center' }}>
      Advertisement Slot
    </div>
  }
  extra={
    <Button size='small' type="default">
      Go to website
    </Button>
  }
>
Your ads here!<br></br>
      <Text type='secondary'>Second line</Text>
    </Card>
    </Col>

    <Col md={24}>
    <Card
  style={{
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `${isDarkMode ? darkGradient : lightGradient}, url("https://albertadentalimplants.ca/wp-content/uploads/2022/10/Placeholder-Ads-.jpg")`,
    height: '150px',
  }}
  title={
    <div style={{ display: 'flex', alignItems: 'center' }}>
      Advertisement Slot
    </div>
  }
  extra={
    <Button size='small' type="default">
      Go to website
    </Button>
  }
>
Your ads here!<br></br>
      <Text type='secondary'>Second line</Text>
    </Card>
    </Col>
    
    <Col md={24}>
    <Card
  style={{
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `${isDarkMode ? darkGradient : lightGradient}, url("https://albertadentalimplants.ca/wp-content/uploads/2022/10/Placeholder-Ads-.jpg")`,
    height: '150px',
  }}
  title={
    <div style={{ display: 'flex', alignItems: 'center' }}>
      Advertisement Slot
    </div>
  }
  extra={
    <Button size='small' type="default">
      Go to website
    </Button>
  }
>
Your ads here!<br></br>
      <Text type='secondary'>Second line</Text>
    </Card>
    </Col>
  </Row>
</Col>




      {/* Shop Items Display */}
      <Col span={18}>
        <Card style={{marginBottom:'50px'}}>
        <Col md={24}>
<Text strong style={{ fontSize: '20px', marginBottom: '1rem', display: 'block', textAlign: 'center', color: '#fff' }}>
    Start Earning Rewards in 3 Simple Steps:
  </Text>
  <Row gutter={[20,20]} justify="center" >
    <Col xs={24} md={8}>
      <Card bordered={false} style={{ textAlign: 'center', backgroundColor: '#252525', borderRadius: '8px', color: '#fff' }}>
      <CarryOutFilled style={{ fontSize: '32px' }} />
        <Text strong style={{ display: 'block', fontSize: '16px', marginTop: '0.5rem' }}>1. Get Active</Text>
        <Text type="secondary" style={{ color: '#d1d1d1' }}>Show your support by voting and completing daily tasks.</Text>
      </Card>
    </Col>
    <Col xs={24} md={8}>
      <Card bordered={false} style={{ textAlign: 'center', backgroundColor: '#252525', borderRadius: '8px', color: '#fff' }}>
        <CopyrightCircleTwoTone style={{ fontSize: '32px' }} />
        <Text strong style={{ display: 'block', fontSize: '16px', marginTop: '0.5rem' }}>2. Earn Coins</Text>
        <Text type="secondary" style={{ color: '#d1d1d1' }}>Collect Vote coins with every action, turning your support into rewards.</Text>
      </Card>
    </Col>
    <Col xs={24} md={8}>
      <Card bordered={false} style={{ textAlign: 'center', backgroundColor: '#252525', borderRadius: '8px', color: '#fff' }}>
      <GiftFilled style={{ fontSize: '32px' }} />
        <Text strong style={{ display: 'block', fontSize: '16px', marginTop: '0.5rem' }}>3. Get Rewards</Text>
        <Text type="secondary" style={{ color: '#d1d1d1' }}>Spend your coins on exclusive items, discounts, and premium content.</Text>
      </Card>
    </Col>

  </Row>

</Col>
        </Card>
        <Card>
          <Row style={{ textAlign: 'center', marginBottom: '20px'}}>
 <Col md={24}>
        <Title level={3} style={{ textAlign: 'center', margin:'0px',marginBottom: '20px' }}>
        {shopView === 'mainShop' ? (
          <>
    <Title style={{margin:'0px'}} level={3}> <ShopOutlined /> Marketplace</Title>
    <Divider></Divider>

<Row gutter={[20, 20]}>
  <Col md={10}>
    <Search
      placeholder={'Search for item in all Marketplace...'}
      value={searchQuery}
      onChange={handleSearchChange}
      allowClear
    />
  </Col>
  <Col md={8}>
  <InputNumber
  addonBefore={selectBefore} 
  addonAfter="Coins" 
  value={priceMode === 'min' ? minPrice : maxPrice} 
  onChange={handlePriceChange}
  placeholder={`Enter ${priceMode} price`} 
  style={{ width: '100%' }}
/>
  </Col>
  <Col md={6}>
    <Select
      value={filter}
      onChange={handleFilterChange}
      style={{ width: '100%' }}
      options={[
        { value: 'All', label: 'All items' },
        { value: 'giftcard', label: 'Gift Cards' },
        { value: 'discountcode', label: 'Discount Codes' },
      ]}
    />
  </Col>
  <Col md={6}>
    <Select
      placeholder="test"
      value={inStockFilter}
      onChange={(value) => setInStockFilter(value)}
      style={{ width: '100%' }}
      options={[
        { value: false, label: 'All Stock' },
        { value: true, label: 'In Stock' },
      ]}
    />
  </Col>
  <Col md={6}>
    <Select
      value={discountFilter}
      onChange={(value) => setDiscountFilter(value)}
      style={{ width: '100%' }}
      options={[
        { value: 0, label: 'All discounts' },
        { value: 5, label: '5% Discount' },
        { value: 10, label: '10% Discount' },
        { value: 20, label: '20% Discount' },
        { value: 30, label: '30% Discount' },
        { value: 40, label: '40% Discount' },
        { value: 50, label: '50% Discount' },
        { value: 60, label: '60% Discount' },
        { value: 70, label: '70% Discount' },
        { value: 80, label: '80% Discount' },
        { value: 90, label: '90% Discount' },
        { value: 100, label: '100% Discount' },
      ]}
    />
  </Col>
  <Col md={6}>
  <Select
  value={linkedReleaseFilter}
  onChange={(value) => setLinkedReleaseFilter(value)}
  style={{ width: '100%' }}
  options={[
    { value: false, label: 'All Packages' },
    { value: true, label: 'Linked Packages Only' },
    { value: 'notLinked', label: 'Unlinked Packages Only' },
  ]}
  
/>

  </Col>
  {filter !== 'discountcode' && (
    <Col md={6}>
      <InputNumber
       addonBefore="Card Balance"
        value={giftBalanceFilter}
        onChange={(value) => setGiftBalanceFilter(value)}
        placeholder="0"
        style={{ width: '100%' }}
      />
    </Col>
  )}
</Row>
   
    </>
  ) : (
    <>
      <img 
        src={`${BASE_URL}${developerShops.find((dev) => String(dev.owner_id) === String(shopView))?.profile_img_url || '/uploads/default/profile_bg.webp'}`} 
        alt="Developer Profile" 
        style={{ width: '100%', height: '100px', objectFit:'cover', borderRadius: '8px', marginRight: '10px' }} 
      />
      {`${developerShops.find((dev) => String(dev.owner_id) === String(shopView))?.name || 'Unknown Developer'}'s Shop`}
      <Divider></Divider>
      <Row gutter={[20, 20]}>
  <Col md={10}>
    <Search
      placeholder={'Search for item in all Marketplace...'}
      value={searchQuery}
      onChange={handleSearchChange}
      allowClear
    />
  </Col>
  <Col md={8}>
  <InputNumber
  addonBefore={selectBefore} 
  addonAfter="Coins" 
  value={priceMode === 'min' ? minPrice : maxPrice} 
  onChange={handlePriceChange}
  placeholder={`Enter ${priceMode} price`} 
  style={{ width: '100%' }}
/>
  </Col>
  <Col md={6}>
    <Select
      value={filter}
      onChange={handleFilterChange}
      style={{ width: '100%' }}
      options={[
        { value: 'All', label: 'All items' },
        { value: 'giftcard', label: 'Gift Cards' },
        { value: 'discountcode', label: 'Discount Codes' },
      ]}
    />
  </Col>
  <Col md={6}>
    <Select
      placeholder="test"
      value={inStockFilter}
      onChange={(value) => setInStockFilter(value)}
      style={{ width: '100%' }}
      options={[
        { value: false, label: 'All Stock' },
        { value: true, label: 'In Stock' },
      ]}
    />
  </Col>
  <Col md={6}>
    <Select
      value={discountFilter}
      onChange={(value) => setDiscountFilter(value)}
      style={{ width: '100%' }}
      options={[
        { value: 0, label: 'All discounts' },
        { value: 5, label: '5% Discount' },
        { value: 10, label: '10% Discount' },
        { value: 20, label: '20% Discount' },
        { value: 30, label: '30% Discount' },
        { value: 40, label: '40% Discount' },
        { value: 50, label: '50% Discount' },
        { value: 60, label: '60% Discount' },
        { value: 70, label: '70% Discount' },
        { value: 80, label: '80% Discount' },
        { value: 90, label: '90% Discount' },
        { value: 100, label: '100% Discount' },
      ]}
    />
  </Col>
  <Col md={6}>
  <Select
  value={linkedReleaseFilter}
  onChange={(value) => setLinkedReleaseFilter(value)}
  style={{ width: '100%' }}
  options={[
    { value: false, label: 'Linked package and any package' },
    { value: true, label: 'Usable on linked package' },
    { value: 'notLinked', label: 'Usable on any developer package' }, 
  ]}
/>

  </Col>
  {filter !== 'discountcode' && (
    <Col md={6}>
      <InputNumber
       addonBefore="Card Balance"
        value={giftBalanceFilter}
        onChange={(value) => setGiftBalanceFilter(value)}
        placeholder="0"
        style={{ width: '100%' }}
      />
    </Col>
  )}
</Row>

    </>
  )}
</Title>

</Col>

<Col md={24}>
  {loading ? (
    <Empty description="Loading..." />
  ) : (
    renderItems(shopView === 'mainShop' ? mainShopItems : developerItems)
  )}
</Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Marketplace;