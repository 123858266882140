import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, Select, Card, Space, Tag, Typography, Divider, List, Alert, Upload, message, Table, Spin, AutoComplete } from 'antd';
import { SettingOutlined, DiscordFilled, ApiOutlined, CheckCircleOutlined, TrophyOutlined, UsergroupAddOutlined, BarChartOutlined, SafetyCertificateOutlined,CopyrightCircleTwoTone, FileDoneOutlined, ShopOutlined, FireOutlined, StarOutlined, CloseCircleOutlined, PlusOutlined, GithubOutlined, CaretUpOutlined, CrownOutlined, CreditCardOutlined } from '@ant-design/icons';
import axiosInstance from '../../../../axiosConfig';
import ShopManager from './ShopManager';

const { Option } = Select;
const { TextArea } = Input;
const { Title, Text, Paragraph, Link } = Typography;


const tabItems = ({ 
    formDeveloper,
    handleSaveDeveloper,
    developerData,
    setActiveTabKey,
    loadingDeveloper,
    handleSaveDiscord,
    formDiscord,
    loadingDiscord,
    botInviteLink,
    nicknameValidating,
    nicknameError,
    nicknameSuccess,
    setDeveloperData,
    debouncedCheckNickname,
    tags,
    handleTagClose,
    inputVisible,
    inputValue,
    setInputValue,
    handleInputConfirm,
    setInputVisible,
    handleUploadChange,
    uploadProps,
    messageApi,
    BASE_URL,
    tebexUrlValidating,
    tebexUrlError,
    tebexUrlSuccess,
    validateTebexUrl,
    loadingTebex,
    allConfirmed,
    handleVerificationRequest,
    data,
    user,
    columns_premium,
    data_premium,
    handleRemoveTeamMember,
    selectedTeamMembers,
    handleInputConfirmMember,
    setInputTeamVisible,
    inputTeamVisible,
}) => [


        {
          key: '1',
          label: <Text><SettingOutlined /> Profile Settings</Text>,
          children:        
      <Row>
        <Col>
        
        </Col>
        <Col>
        
       <Form
              form={formDeveloper}
              layout="vertical"
              onFinish={handleSaveDeveloper}
              initialValues={developerData}
            >
              <Title level={4} style={{marginTop:'0px'}}><SettingOutlined /> Profile Settings</Title>
      <Row gutter={[40,0]}>
      
      <Col md={6}>
              <Form.Item
                name="nickname"
                label="Nickname"
                validateStatus={nicknameValidating ? 'validating' : nicknameError ? 'error' : nicknameSuccess ? 'success' : ''}
                help={nicknameError || nicknameSuccess || ''}
                rules={[{ required: true, message: 'Please input your nickname!' }]}
              >
                <Input
                maxLength={15}
                  placeholder="Enter your nickname"
                  onChange={(e) => {
                    setDeveloperData({ ...developerData, nickname: e.target.value });
                    debouncedCheckNickname(e.target.value);
                  }}
                />
              </Form.Item>
      </Col>
      <Col md={6}>
      <Form.Item
      name="public_email"
      label="Public support e-mail"
      rules={[
        {
          type: 'email',
          message: 'Please enter a valid email address!',
        },
        {
          required: false,
          message: 'Please provide your public support e-mail!',
        },
      ]}
    >
      <Input
        maxLength={50} // Adjusted length for realistic email limits
        placeholder="Enter your public support e-mail"
        value={developerData.public_email}
        onChange={(e) => {
          setDeveloperData({ ...developerData, public_email: e.target.value });
        }}
      />
    </Form.Item>
    
      </Col>
      <Col md={6}>
      <Form.Item name="is_team" label="Team">
  <Select
    placeholder="Select availability"
    onChange={(value) => {
      setDeveloperData((prevData) => ({
        ...prevData,
        is_team: value, // Update the is_team field in developerData
      }));
    }}
  >
    <Select.Option value={0}>No</Select.Option>
    <Select.Option value={1}>Yes</Select.Option>
  </Select>
</Form.Item>


      </Col>
      <Col md={6}>
      <Form.Item name="is_available_for_hire" label="Availability" >
      <Select placeholder="Select availability">
        <Select.Option value={1}>Available for Hire</Select.Option>
        <Select.Option value={0}>Not Available for Hire</Select.Option>
      </Select>
    </Form.Item>
      </Col>
      <Col md={24}>
  {developerData?.is_team === 1 && ( // Check if the "Team" is set to "Yes" (value is 1)
    <Form.Item name="team_members" label="Team Members">
      <Space wrap>
        {selectedTeamMembers.map((member) => (
          <Tag closable key={member.id} onClose={() => handleRemoveTeamMember(member.id)}>
            {member.nickname}
          </Tag>
        ))}
        {inputTeamVisible ? (
          <Input
            type="text"
            size="small"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onBlur={handleInputConfirmMember}
            onPressEnter={handleInputConfirmMember}
            style={{ width: 150 }}
          />
        ) : (
          <Button size="small" onClick={() => setInputTeamVisible(true)}>
            + Add Member
          </Button>
        )}
      </Space>
    </Form.Item>
  )}
</Col>

      <Col md={12}>  
              <Form.Item name="category" label="Category">
                <Select
                  mode="multiple"
                  placeholder="Select category"
                  initalvalues={developerData.category}
                >
                  <Option value="Script">Script</Option>
                  <Option value="Maps">Maps</Option>
                  <Option value="Cars">Cars</Option>
                  <Option value="Models">Models</Option>
                  <Option value="Other">Other</Option>
                </Select>
              </Form.Item>
      </Col>
      
      
      <Col md={12}>
              <Form.Item name="games" label="Games">
                <Select
                  mode="multiple"
                  placeholder="Select games"
                  initalvalues={developerData.games}
                >
                  <Option value="FiveM">FiveM</Option>
                  <Option value="RedM">RedM</Option>
                  <Option value="Minecraft">Minecraft</Option>
                </Select>
              </Form.Item>
      </Col>  
    
       <Col md={12}>  
              <Form.Item name="motto" label="Motto">
                <TextArea  type="textarea" placeholder="Enter your motto, max 220 char."   rows={4} maxLength={220}/>
              </Form.Item>
      </Col>
    
    
       <Col md={12}>
              <Form.Item name="tags" label="Tags">
                <Space wrap>
                  {tags.map((tag) => (
                    <Tag closable key={tag} onClose={() => handleTagClose(tag)}>
                      {tag}
                    </Tag>
                  ))}
                  {inputVisible && (
                    <Input
                      type="text"
                      size="small"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onBlur={handleInputConfirm}
                      onPressEnter={handleInputConfirm}
                      style={{ width: 78 }}
                    />
                  )}
                  {!inputVisible && (
                    <Button size="small" onClick={() => setInputVisible(true)}>
                      + New Tag
                    </Button>
                  )}
                </Space>
              </Form.Item>
      </Col>
    
              </Row>
            </Form>
            
            <Col md={12}> 
            
       <Form
              form={formDeveloper} 
              layout="vertical"
              onFinish={handleUploadChange}
              initialValues={developerData}
             
            >
              <div  style={{display:'flex',gap:'50px'}}>
              <Form.Item name="user_img_url" label="Profile picture">
                  <Upload {...uploadProps('user_img_url')} listType="picture-card">
                    {developerData.user_img_url ? (
                      <img onClick={() => messageApi.open({
                        type: 'info',
                        content: 'Saving profile...',
                      })} src={`${BASE_URL}${developerData.user_img_url}`} alt="avatar" style={{ width: '100%' }} />
                    ) : (
                      null
                    )}
                  </Upload>
                </Form.Item>
          <Form.Item name="ranking_img_url" label="Ranking banner">
            <Upload {...uploadProps('ranking_img_url')} listType="picture-card">
              {developerData.ranking_img_url ? (
                <img
                  src={`${BASE_URL}${developerData.ranking_img_url}`}
                  alt="ranking"
                  style={{ width: '100%' }}
                  onClick={() => messageApi.open({
                    type: 'info',
                    content: 'Saving profile...',
                  })}
                />
              ) : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
            </Form.Item>
      
                <Form.Item name="profile_bg_url" label="Profile Background">
                  <Upload {...uploadProps('profile_bg_url')} listType="picture-card">
                    {developerData.profile_bg_url ? (
                      <img onClick={() => messageApi.open({
                        type: 'info',
                        content: 'Saving profile...',
                      })}  src={`${BASE_URL}${developerData.profile_bg_url}`} alt="bg" style={{ width: '100%' }} />
                    ) : (
                      null
                    )}
                  </Upload>
                </Form.Item>
      
    
          </div>
      </Form>
      <Col md={24}> 
      <Form
              form={formDeveloper}
              layout="vertical"
              onFinish={handleSaveDeveloper}
              initialValues={developerData}
            >
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loadingDeveloper} onClick={() => messageApi.open({
                  type: 'info',
                  content: 'Saving profile...',
                })}  // Trigger message on button click
              >
                Save Profile
              </Button>
              </Form.Item>
              </Form>
       </Col>
       </Col>
        </Col>
        
      </Row>
    
      
        },
        {
          key: '2',
          label: <Text><DiscordFilled /> Discord Settings</Text>,
          children: <div>
                      <Form
                    form={formDiscord}
                    layout="vertical"
                    onFinish={handleSaveDiscord}
                    initialValues={developerData}
                  >
    
                    <Row gutter={[20,20]}>
                      <Col md={12}>
                      <Card title={<Title style={{textAlign: 'center', margin:'0px'}} level={4}><TrophyOutlined /> Boost your ranking!</Title>}>
    
    
        <Paragraph style={{textAlign: 'center',  fontSize: '1em', marginBottom: '20px' }}>
          Invite our bot to your server and let your community show their support!<br /> Here’s how it works:
        </Paragraph>
    
        <Divider />
    
        <Paragraph style={{ fontSize: '1em', display: 'flex', alignItems: 'center', gap: '8px' }}>
          <UsergroupAddOutlined />
          <Text strong style={{width:'30%'}}>Real-Time Stats:</Text> <Text style={{width:'70%'}}>The bot tracks your server’s total members and customers in real-time, so you’re always up-to-date.</Text>
        </Paragraph>
    
        <Divider />
    
        <Paragraph style={{ fontSize: '1em', display: 'flex', alignItems: 'center', gap: '8px' }}>
          <BarChartOutlined />
          <Text strong style={{width:'30%'}}>Daily Votes:</Text> <Text style={{width:'70%'}}>Each community member can vote once per day, giving you 1 vote while earning themselves 100 Vote Coins.</Text>
        </Paragraph>
    
        <Divider />
    
        <Paragraph style={{ fontSize: '1em', display: 'flex', alignItems: 'center', gap: '8px' }}>
          <CopyrightCircleTwoTone />
          <Text strong style={{width:'30%'}}>Vote Coins:</Text> <Text style={{width:'70%'}}>Vote Coins can be redeemed for discounts, gift cards, or even premium scripts—either in the developer’s shop or the main shop.</Text>
        </Paragraph>
    
        <Divider />
    
        <Paragraph style={{ fontSize: '1em', display: 'flex', alignItems: 'center', gap: '8px' }}>
          <TrophyOutlined />
          <Text strong style={{width:'30%'}}>Monthly Ranking:</Text> <Text style={{width:'70%'}}>Votes contribute to a monthly ranking that resets each month, ensuring everyone has a fresh chance at the top.</Text>
        </Paragraph>
    
        <Divider />
    
        <Paragraph style={{ fontSize: '1em', display: 'flex', alignItems: 'center', gap: '8px' }}>
          <CheckCircleOutlined />
          <Text strong style={{width:'30%'}}>Verified Status:</Text> <Text style={{width:'70%'}}>Inviting the bot is essential for becoming a verified developer and boosting your credibility on the platform.</Text>
        </Paragraph>
    
        <Divider />
    
        <Paragraph style={{ fontSize: '14px', textAlign: 'center', marginTop: '20px'}}>
        Let your community earn rewards with every vote, while you climb the leaderboard. Simple, engaging, and rewarding for everyone!
        </Paragraph>
    
        
      </Card>
    
          
    
                    </Col>
                    <Col md={12}>
                    <Col  md={24}>
                      <Title style={{marginTop:'0px'}} level={4}><DiscordFilled /> Discord Settings</Title>
                    <Form.Item name="discord_url" label="Invite URL">
                      <Input placeholder="Enter your Discord Invite URL" />
                    </Form.Item>
                    </Col>
                    <Col md={24}>
                    <Form.Item name="discord_guild_id" label="Guild ID">
                      <Input placeholder="Enter your Discord Guild ID" />
                    </Form.Item>
                    </Col>
    
                    <Col md={24}>
                    <Form.Item name="discord_customer_role_name" label="Customer Role Name">
                      <Input placeholder="Enter your Discord customer role name" />
                    </Form.Item>
                    </Col>
                    <Col md={24}>
                    <Form.Item name="discord_channel_id" label="Channel ID">
                      <Input placeholder="Enter your Discord Channel ID" />
                    </Form.Item>
                    </Col>
                    <Row gutter={[20,20]}>
                    <Col md={8} style={{paddingLeft:'20px'}}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" loading={loadingDiscord}  onClick={() => messageApi.open({
                  type: 'info',
                  content: 'Saving discord...',
                })}  // Trigger message on button click
              >
                        Save Discord Information
                      </Button>
                    </Form.Item>
                    </Col>
                    <Col md={8}>
                    <a href={botInviteLink} target="_blank" rel="noopener noreferrer">
                      
                    </a>
                    </Col>
                    <Col md={8} style={{paddingRight:'20px'}}>
                    <Button style={{float:'right'}}>Help</Button>
                    <Button style={{float:'right', marginRight:'20px'}}>Invite Bot</Button>
                    </Col>
                    {developerData.discord_total_members !== null ? (
      <>
        <Col style={{ marginLeft: '20px' }}>
          <Text>Total discord members: {developerData.discord_total_members}</Text>
        </Col>
        {developerData.discord_total_customer !== null && (
          <Col style={{ marginLeft: '20px' }}>
            <Text>Total discord customers: {developerData.discord_total_customer}</Text>
          </Col>
        )}
      </>
    ) : (
      <Col md={24} style={{ paddingLeft: '20px', paddingRight:'20px' }}>
    <Alert
          message="The bot is not in the server!"
          description="Live Discord data won’t be displayed, and users won’t be able to vote for you. Pending verification may also impact your eligibility for verified status."
          type="error"
          action={
            <Button size="small" danger>
              Invite Bot
            </Button>
          }
        />
      </Col>
    )}
    
    
                    </Row>
                    </Col>
                    </Row>
    
    
              
                  </Form>
      
                  </div>
        },
        {
          key: '3',
          label: <Text><ApiOutlined /> API Settings</Text>, // Added Tebex form here
          children: (
            <Row gutter={[40,40]}>
              <Col md={12}>
              <Card title={<Title style={{textAlign: 'center', margin:'0px'}} level={4}><ApiOutlined /> Link your products and repositories!</Title>}>
    
    
              <Paragraph style={{ textAlign: 'center', fontSize: '1em', marginBottom: '20px' }}>
      Link your products and repositories to keep your releases up-to-date!<br /> Here’s how it works:
    </Paragraph>
    
    <Divider />
    
    <Paragraph style={{ fontSize: '1em', display: 'flex', alignItems: 'center', gap: '8px' }}>
      <ApiOutlined />
      <Text strong style={{ width: '30%' }}>Tebex API:</Text> 
      <Text style={{ width: '70%' }}>Connect your public Tebex token to automatically add and update your products on our platform.</Text>
    </Paragraph>
    
    <Divider />
    
    <Paragraph style={{ fontSize: '1em', display: 'flex', alignItems: 'center', gap: '8px' }}>
      <ApiOutlined />
      <Text strong style={{ width: '30%' }}>GitHub API:</Text> 
      <Text style={{ width: '70%' }}>Link your GitHub token to display all public repositories or select specific ones to showcase.</Text>
    </Paragraph>
    
    <Divider />
    
    <Paragraph style={{ fontSize: '1em', display: 'flex', alignItems: 'center', gap: '8px' }}>
     <CaretUpOutlined />
      <Text strong style={{ width: '30%' }}>Upvotes:</Text> 
      <Text style={{ width: '70%' }}>Our community can upvote your releases and repositories, boosting popularity when appreciated.</Text>
    </Paragraph>
    
    <Divider />
    
    <Paragraph style={{ fontSize: '1em', display: 'flex', alignItems: 'center', gap: '8px' }}>
      <CheckCircleOutlined />
      <Text strong style={{ width: '30%' }}>Verified Status:</Text> 
      <Text style={{ width: '70%' }}>Linking your Tebex and GitHub accounts is crucial to becoming a verified developer, enhancing your credibility on the platform.</Text>
    </Paragraph>
    
    <Divider />
    
    
    <Paragraph style={{ fontSize: '14px', textAlign: 'center', marginTop: '20px'}}>
    Connect your Tebex and GitHub APIs just once, and let TopModders handle the rest effortlessly.
    </Paragraph>
    
    
    </Card>
              </Col>
              <Col md={12}>
              <Col md={24}>
                <Title level={4} style={{marginTop:'0px'}}><GithubOutlined /> GitHub Settings</Title>
              <Form
              form={formDeveloper} // Passing Tebex form instance
              layout="vertical"
              onFinish={handleSaveDeveloper}
              initialValues={developerData}
            >
              <Row gutter={[20,20]} style={{marginBottom:'40px'}}>
                <Col md={12}>
              <Form.Item name="github_url" label="GitHub URL" >
                <Input placeholder="Enter your GitHub URL" />
              </Form.Item>
              </Col>
              <Col md={12} >
              <Form.Item name="github_token" label="GitHub Public token">
                <Input placeholder="Enter your GitHub token" />
              </Form.Item>
              </Col>
              <Col  md={12} >
              <Button type="primary" htmlType="submit" loading={loadingDeveloper} onClick={() => messageApi.open({
                  type: 'info',
                  content: 'Saving GitHub...',
                })} 
              >
                Save GitHub Settings
              </Button>
              </Col>
              <Col md={12}>
              <Button style={{float:'right'}}>Help</Button>
              </Col>
              </Row>
            </Form>
            </Col>
            <Divider />
            <Col md={24}>
            <Title level={4} style={{marginTop:'0px'}}><ApiOutlined /> Tebex Settings</Title>
            <Form
      form={formDeveloper}
      layout="vertical"
      onFinish={(values) => handleSaveDeveloper({ tebex_url: values.tebex_url, tebex_token: values.tebex_token })} // Only Tebex fields
      initialValues={developerData}
    >
              <Row gutter={[20,20]}>
                <Col md={12}>
              <Form.Item
                name="tebex_url"
                label="Tebex URL"
                validateStatus={tebexUrlValidating ? 'validating' : tebexUrlError ? 'error' : tebexUrlSuccess ? 'success' : ''}
                help={tebexUrlError || tebexUrlSuccess || ''}
                rules={[{ required: true, message: 'Please provide a valid Tebex link!' }]}
              >
                <Input placeholder="Enter your Tebex link" onChange={(e) => validateTebexUrl(e.target.value)} />
              </Form.Item>
              </Col>
              <Col  md={12}>
              <Form.Item
                name="tebex_token"
                label="Tebex Public Token"
              >
                <Input placeholder="Enter your Tebex token" />
              </Form.Item>
              </Col>
              <Col md={12}>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loadingTebex} onClick={() => messageApi.open({
                  type: 'info',
                  content: 'Saving Tebex...',
                })}
              >
                  Save Tebex Settings
                </Button>
              </Form.Item>
              </Col>
              <Col md={12}>
              <Button style={{float:'right'}}>Help</Button>
              </Col>
              </Row>
            </Form>
            </Col>
            </Col>
            </Row>
          ),
        },
        {
          key: '6',
          label: <Text><CheckCircleOutlined /> Verified developer</Text>, // Added Tebex form here
          children: (
    <Row gutter={[20,20]}>
    
    <Col md={12}>
    <Card title={<Title style={{ textAlign: 'center', margin: '0px' }} level={4}><CheckCircleOutlined /> Verified Developer Status</Title>}>
      <Paragraph style={{ textAlign: 'center', fontSize: '1em', marginBottom: '20px' }}>
        Boost your credibility and stand out with Verified Developer status!<br /> Here’s what it offers:
      </Paragraph>
    
      <Divider />
    
      <Paragraph style={{ fontSize: '1em', display: 'flex', alignItems: 'center', gap: '8px' }}>
        <SafetyCertificateOutlined />
        <Text strong style={{ width: '30%' }}>Credibility:</Text>
        <Text style={{ width: '70%' }}>Verified Developer status appears on your profile across TopModders, making your expertise and professionalism visible to potential clients.</Text>
      </Paragraph>
    
      <Divider />
    
      <Paragraph style={{ fontSize: '1em', display: 'flex', alignItems: 'center', gap: '8px' }}>
        <FileDoneOutlined />
        <Text strong style={{ width: '30%' }}>Verification Process:</Text>
        <Text style={{ width: '70%' }}>Meet the requirements and submit a verification request. Our team will review your profile and Discord presence and provide feedback.</Text>
      </Paragraph>
    
      <Divider />
    
      <Paragraph style={{ fontSize: '1em', display: 'flex', alignItems: 'center', gap: '8px' }}>
        <ShopOutlined />
        <Text strong style={{ width: '30%' }}>Marketplace Access:</Text>
        <Text style={{ width: '70%' }}>Unlock the Marketplace to sell discount codes, gift cards, and premium scripts that users can purchase with Vote Coins.</Text>
      </Paragraph>
    
      <Divider />
    
      <Paragraph style={{ fontSize: '1em', display: 'flex', alignItems: 'center', gap: '8px' }}>
        <FireOutlined />
        <Text strong style={{ width: '30%' }}>Release of the Month (ROTM):</Text>
        <Text style={{ width: '70%' }}>Compete to have your release featured as Release of the Month, displayed on the homepage for a full month!</Text>
      </Paragraph>
    
      <Divider />
    
      <Paragraph style={{ fontSize: '1em', display: 'flex', alignItems: 'center', gap: '8px' }}>
        <UsergroupAddOutlined />
        <Text strong style={{ width: '30%' }}>Double Vote Coins:</Text>
        <Text style={{ width: '70%' }}>When users vote for a Verified Developer, they earn double the Vote Coins, enhancing their rewards and engagement.</Text>
      </Paragraph>
    
      <Divider />
    
      <Paragraph style={{ textAlign: 'center', fontSize: '1em', marginBottom: '20px' }}>
        Verified status marks a significant level of trust and recognition. While it may be challenging to achieve, the benefits are substantial.
      </Paragraph>
    </Card>
    
    
    </Col>
    <Col md={12}>
    {!developerData.verified ? (
      <List
        style={{ width: '100%' }}
        header={<div><strong>Get verified!</strong></div>}
        footer={
          <Row style={{ display: 'flex', textAlign: 'center', alignContent: 'center', alignItems: 'center' }}>
            <Col md={6}>
              <Button disabled={!allConfirmed} onClick={handleVerificationRequest}>Submit request</Button>
            </Col>
            <Col>
              <Text style={{ fontSize: '12px' }}>Verification process may take up to 2 weeks.</Text>
            </Col>
          </Row>
        }
        bordered
      >
        <Row gutter={[16, 16]}>
          {/* First Column */}
          <Col span={12}>
            {data.slice(0, Math.ceil(data.length / 2)).map((item) => (
              <List.Item key={item.label}>
                {item.value ? <CheckCircleOutlined style={{ color: 'green' }} /> : <CloseCircleOutlined style={{ color: 'red' }} />}
                {item.label}
              </List.Item>
            ))}
          </Col>
    
          {/* Second Column */}
          <Col span={12}>
            {data.slice(Math.ceil(data.length / 2)).map((item) => (
              <List.Item key={item.label}>
      {item.value === 'active_support' ? (
       <StarOutlined />
      ) : item.value ? (
        <CheckCircleOutlined style={{ color: 'green' }} />
      ) : (
        <CloseCircleOutlined style={{ color: 'red' }} />
      )}
      {item.label}
    </List.Item>
    
            ))}
          </Col>
        </Row>
      </List>
    ) : (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <CheckCircleOutlined style={{ color: 'green', fontSize: '24px' }} />
        <h3 style={{ color: 'green' }}>You are verified!</h3>
        <p>Your developer profile has been verified. Enjoy the benefits of a verified status!</p>
      </div>
    )}
    
    </Col>
    </Row>
          ),
        },
        {
          key: '7',
          label: <Text><ShopOutlined /> Shop Manager</Text>,
          children: developerData?.verified ? (
            user?.userId ? (
              <ShopManager userId={user.userId} />
            ) : (
              <Alert message="Loading user data..." type="info" />
            )
          ) : (
            <Alert
              message="Access Restricted"
              description={
                <span>
                  You need to be Verified to access the Shop Manager.{' '}
                  <a
                    onClick={() => setActiveTabKey('6')}
                  >
                    Go here
                  </a>{' '}
                  for more information.
                </span>
              }
              type="error"
              showIcon
            />
          ),
        },
        {
          key: '8',
          label: <Text><CrownOutlined /> Premium</Text>,
          children: (
    <Row gutter={[40,40]}>
    <Col md={12}>
    <Table columns={columns_premium} dataSource={data_premium} pagination={false}  bordered />
    </Col>
    <Col md={12}>
          <Card >
            <Title level={3} style={{ textAlign: 'center', marginBottom: '20px' }}>
              Premium Plans
            </Title>
            <Row gutter={[20, 20]} justify="center">
    
              <Col md={8}>
                <Card title="1 Month" style={{ borderRadius: '8px', textAlign: 'center', height:'200px',display:'flex', flexDirection:'column', justifyContent:'space-between' }}>
                <Text style={{ fontSize: '1em', color: '#595959' }}>17.99 per Month</Text>
                  <br />
                  <Text strong style={{ fontSize: '1.2em' }}>€17.99 Total</Text>
                  <Button type="primary" style={{ width: '100%', marginTop: '15px' }}>
                    Buy 1 Month
                  </Button>
                </Card>
              </Col>
    
              <Col md={8}>
                <Card title="6 Months" style={{ borderRadius: '8px', textAlign: 'center', height:'200px', display:'flex', flexDirection:'column', justifyContent:'space-between' }}>
                  <Text style={{ fontSize: '1em', color: '#595959' }}>€14.99 per Month</Text>
                  <br />
                  <Text strong style={{ fontSize: '1.2em' }}>€89.94 Total</Text>
                  <Button type="primary" style={{ width: '100%', marginTop: '15px' }}>
                    Buy 6 Months
                  </Button>
                </Card>
              </Col>
    
              <Col md={8}>
                <Card title="12 Months" style={{ borderRadius: '8px', textAlign: 'center', height:'200px', display:'flex', flexDirection:'column', justifyContent:'space-between' }}>
                  <Text style={{ fontSize: '1em', color: '#595959' }}>€12.99 per Month</Text>
                  <br />
                  <Text strong style={{ fontSize: '1.2em' }}>€155.88 Total</Text>
                  <Button type="primary" style={{ width: '100%', marginTop: '15px' }}>
                    Buy 12 Months
                  </Button>
                </Card>
              </Col>
              <Col style={{display:'flex', flexDirection:'column', alignItems:'center', gap:'20px'}}>
              <img width={250} src='https://www.chrisbarnespottery.com/image/payment/credit-cards-logos_635.png'></img>
              <img width={150} src='https://www.pngkey.com/png/full/235-2359657_security-is-one-of-the-biggest-considerations-in.png'></img>
    
              <Text style={{ fontSize: '12px', color: '#595959', textAlign:'center' }}>Modders S.R.L. - VAT Number XX1234567890 <br></br>support@topmodders.com<br></br><Link style={{ fontSize: '12px', color: '#999999', textAlign:'center' }}>Terms of service</Link> - <Link style={{ fontSize: '12px', color: '#999999', textAlign:'center' }}> Privacy</Link></Text>
      
              </Col>
    
            </Row>
          </Card>
        </Col>
    </Row>
          ),
        },
        {
          key: '9',
          label: <Text><CreditCardOutlined /> Billing</Text>, 
          children: (
    <div>
      Under development
      </div>
          ),
        },
    
      ];

export default tabItems;
