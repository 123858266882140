import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd'; // Import Tooltip from Ant Design
import { BASE_URL } from '../../../config';

// Badge tooltips mapping
// Updated badge tooltips mapping
const badgeTooltips = {
    'staff': {
      title: 'TopModders Team Developer',
      description: 'Member of the TopModders development team.',
    },
    'popular': {
      title: 'Popular Developer',
      description: 'A highly popular and well-known developer.',
    },
    'ROTM': {
      title: 'Release of the Month',
      description: 'Awarded for having a release voted as "Release of the Month".',
    },
    'ROTY': {
      title: 'Release of the Year',
      description: 'Awarded for having a release voted as "Release of the Year".',
    },
    'top1': {
      title: 'Top 1 Developer',
      description: 'Achieved the #1 rank on TopModders!',
    },
    'top10': {
      title: 'Top 10 Developer',
      description: 'Ranked among the top 10 developers on TopModders.',
    },
    'top50': {
      title: 'Top 50 Developer',
      description: 'Ranked among the top 50 developers on TopModders.',
    },
    'veteran': {
      title: 'Veteran Member',
      description: 'A long-standing member of the TopModders community.',
    },
    'partner': {
      title: 'Official Partner',
      description: 'An official partner of TopModders.',
    },
    'verified': {
      title: 'Verified Developer',
      description: 'This developer has been verified by TopModders.',
    },
    'lovedpost': {
      title: 'Loved Release',
      description: 'Has at least one release with 100 upvotes!',
    },
    'popularpost': {
      title: 'Popular Release',
      description: 'Has at least one release with over 5,000 views!',
    },
    'monthlyvotes': {
      title: 'Monthly Votes Milestone',
      description: 'Received at least 200 votes in a single month!',
    },
    'totalvotes': {
      title: 'Total Votes Milestone',
      description: 'Accumulated over 1,000 total votes!',
    },
    'star5': {
      title: '5-Star Rating',
      description: 'Received a 5-star rating from TopModders!',
    },
    // Add more badges and their titles/descriptions here
  };
  
  

  const BadgeImage = ({ badgeName }) => {
    const extensions = ['png', 'jpg', 'jpeg', 'gif'];
    const [currentExtensionIndex, setCurrentExtensionIndex] = useState(0);
    const [hasError, setHasError] = useState(false);
  
    const imageUrl = `${BASE_URL}/uploads/achievements/${badgeName}.${extensions[currentExtensionIndex]}`;
  
    useEffect(() => {
      console.log(`Trying to load image: ${imageUrl}`);
    }, [imageUrl]);
  
    const handleError = () => {
      setCurrentExtensionIndex((prevIndex) => {
        if (prevIndex < extensions.length - 1) {
          return prevIndex + 1;
        } else {
          setHasError(true);
          return prevIndex;
        }
      });
    };
  
    // Get the tooltip data for the badge
    const tooltipData = badgeTooltips[badgeName] || {
      title: `Achievement: ${badgeName}`,
      description: 'No additional information available.',
    };
  // Get the tooltip message for the badge
  const tooltipMessage = badgeTooltips[badgeName] || 'Achievement Badge';

   // Prepare the tooltip content
   const tooltipContent = (
    <div>
      <strong>{tooltipData.title}</strong>
      <p style={{ margin: 0 }}>{tooltipData.description}</p>
    </div>
  );

  if (hasError) {
    return (
      <Tooltip title={tooltipMessage}>
        <img
          src='/uploads/achievements/default.png' // Replace with your default image path
          alt={`${badgeName} Badge`}
          style={{ width: '40px', height: '40px' }}
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltipContent}>
    <img
      src={imageUrl}
      alt={`${badgeName} Badge`}
      style={{ width: '40px', height: '40px' }}
      onError={handleError}
      key={imageUrl} // Force re-render when src changes
    />
  </Tooltip>
  );
};

export default BadgeImage;
