import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Typography, Input, Button, Row, Col, Card, Space, Avatar, Spin, List, Tag, Divider, AutoComplete, Alert, Flex, theme } from 'antd';
import { UserOutlined, CheckCircleOutlined, CloseCircleOutlined, TrophyOutlined, RocketOutlined, ThunderboltOutlined, LikeOutlined,CaretUpOutlined,CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { BsDiscord } from "react-icons/bs";
import moment from 'moment';
import { Helmet } from 'react-helmet';
import axiosInstance from '../axiosConfig';
import { BASE_URL, BASE_URL_CB } from '../config';

const { Title, Paragraph, Text, H1 } = Typography;
const { Search } = Input;

const Home = () => {
  const [developers, setDevelopers] = useState([]);
  const [randomDevelopers, setRandomDevelopers] = useState([]);
  const [latestReleases, setLatestReleases] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [releaseCount, setReleaseCount] = useState(3); // Define how many releases to show initially
  const { token } = theme.useToken();
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const videoId = "96EYkQaKjN8";
  const altText = "https://dunb17ur4ymx4.cloudfront.net/packages/images/d6d173d9ac54d8f6d77ca06a561e0aa31df8aa2f.png"; 
  const [rotmData, setRotmData] = useState(null);
  const embedVideoUrl = rotmData && rotmData.video_url ? rotmData.video_url.replace('watch?v=', 'embed/') : '';



  useEffect(() => {
    fetchTopDevelopers(); // Fetch top developers when component mounts
    fetchLatestReleases(); // Fetch latest releases separately
    fetchRotm(); // Fetch ROTM when the component mounts
  }, [releaseCount]);

  const fetchRotm = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('/rotm');
      setRotmData(response.data); // Store ROTM data in state
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log('No ROTM found.');
        setRotmData(null); // No ROTM data
      } else {
        console.error('Error fetching ROTM:', error);
      }
    } finally {
      setLoading(false);
    }
  };
  

  const fetchTopDevelopers = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('/developers/top');
      const developersData = response.data;
  
      // Fetch latest releases for each developer
      const developersWithReleases = await Promise.all(
        developersData.map(async (developer) => {
          try {
            const latestRelease = await axiosInstance.get(`/releases/${developer.nickname}/latest`);
            return { ...developer, latestProduct: latestRelease.data }; // Attach latest release to developer
          } catch (error) {
            if (error.response && error.response.status === 404) {
              // Handle 404 gracefully without logging it to the console
              return { ...developer, latestProduct: null }; // No release found for this developer
            } else {
              console.error(`Error fetching latest release for developer ${developer.nickname}:`, error);
              return { ...developer, latestProduct: null }; // Handle other errors by setting latestProduct to null
            }
          }
        })
      );
  
      // Remove duplicates and select random developers
      const randomFeatured = selectRandomDevelopers(developersWithReleases, 4);
      setDevelopers(developersWithReleases); // Full list of developers
      setRandomDevelopers(randomFeatured);  // Filtered unique random developers
  
      setLoading(false);
    } catch (error) {
      console.error('Error fetching developers:', error);
      setLoading(false);
    }
  };
  
  
    
  const handlePlay = () => {
    setIsVideoLoaded(true);
  };

  const fetchLatestReleases = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('/releases', {
        params: { limit: releaseCount }
      });
  
      // Check if the response contains releases
      if (response.data && response.data.releases && response.data.releases.length > 0) {
        setLatestReleases(response.data.releases);
      } else {
        // If no releases found, set an empty array or handle it gracefully
        setLatestReleases([]);
        console.log('No releases found.');
      }
    } catch (error) {
      // Check for specific 404 error and handle it without logging
      if (error.response && error.response.status === 404) {
        setLatestReleases([]);  // Handle gracefully by setting empty releases
        console.log('No releases found (404).');
      } else {
        console.error('Error fetching releases:', error);
      }
    } finally {
      setLoading(false);  // Ensure loading state is reset after request
    }
  };
  

// Utility to filter unique developers based on their ID
const filterUniqueDevelopers = (developers) => {
  const uniqueDevelopers = [];
  const seenIds = new Set();

  developers.forEach((developer) => {
    if (!seenIds.has(developer.id)) {
      uniqueDevelopers.push(developer);
      seenIds.add(developer.id);
    }
  });

  return uniqueDevelopers;
};

// Randomly select a number of unique developers
const selectRandomDevelopers = (developers, count) => {
  const uniqueDevelopers = filterUniqueDevelopers(developers); // Remove duplicates
  const shuffled = [...uniqueDevelopers].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};



// Function to map game names to specific colors
const getGameTagColor = (game) => {
  switch (game.toLowerCase()) {
    case 'minecraft':
      return 'green';
    case 'fivem':
      return 'orange';
    case 'redm':
      return 'red';
    default:
      return 'default'; // Default tag color
  }
};

// Function to map tag names to specific colors
const getTagColor = (tag) => {
  switch (tag.toLowerCase()) {
    case 'scripts':
      return 'purple';
    case 'maps':
      return 'gold';
    case 'qbcore':
      return 'purple';
    case 'esx': 
      return 'blue';
    case 'cheap':
      return 'lime';
    default:
      return 'default'; // Default tag color
  }
};


const renderDeveloperCard = (developer) => {
  // Check if the developer has a latestProduct (latest release)
  const latestRelease = developer.latestProduct ? (
      <Link style={{color: token.colorPrimary }} href={`/release/${developer.latestProduct.id}`} size='small'  color="default">
        {developer.latestProduct.name}
        </Link>
  ) : (
    <Button size='small' color="default" disabled>No public release</Button> // Show this if no release
  );
  


  return (

      <Card
        key={developer.id}
        hoverable
        style={{width:'100%' }}
      >
        <div style={{ display: 'flex', columnGap: '10px', alignItems: 'center' }}>
          <div>
          <Avatar
            shape='square'
            src={developer.user_img_url ? `${BASE_URL}${developer.user_img_url}` : null}
            icon={!developer.user_img_url && <LikeOutlined />}
            style={{ width: '100px', height: '100px' }}
          />
          </div>
          <div style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis',display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100px', width:'100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'space-between' }}>
              <Link to={`/profile/${developer.nickname}`} style={{ fontSize: '14px', margin: '0px', color:'#888' }}>
                {developer.nickname}
              </Link>
              <Text><LikeOutlined style={{ marginRight: '5px' }} />{developer.total_votes}</Text>
            </div>

            <div>
              {developer.games?.split(',').slice(0, 1).map((game) => (
                <Tag color={getGameTagColor(game)} key={game}>{game}</Tag>
              ))}
              {developer.tags?.split(',').slice(0, 1).map((tag) => (
                <Tag color={getTagColor(tag)} key={tag}>{tag}</Tag>
              ))}
            </div>
            <div style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
            {latestRelease}
            </div>
          </div>
        </div>
      </Card>
 
  );
};

  const renderLatestRelease = (release) => (
    <List.Item key={release.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        <Text strong>{release.name}</Text>
        <small style={{ display: 'block', color: '#888' }}>
         <strong>{release.nickname} </strong> on {moment(release.created_at).format('MMMM Do YYYY')}
        </small>
      </div>
      <Space>
      <Text>
                    <Tag color='green'><CaretUpOutlined /> {release.upvotes}</Tag>
                  </Text>
                  <Text>
                    <Tag color='red'><CaretDownOutlined /> {release.downvotes}</Tag>
                  </Text>
        <Button href={`/release/${release.id}`}>
          View Release
        </Button>
      </Space>
    </List.Item>
  );
  
  // Inside render
  <List
    grid={{ gutter: 16, column: 1 }}
    dataSource={latestReleases}
    renderItem={renderLatestRelease}
  />
  

  return (
    <div className="Home">
<section style={{marginBottom:'50px'}}>
  <Row gutter={[40, 40]}>
    
    <Col md={12} style={{ textAlign: 'left', display:'flex', flexDirection:'column' }} >
      <div style={{ textAlign: 'left', display:'flex', flexDirection:'column', height:'500px', justifyContent:'space-between' }}>

        <img alt='TopModders' width="250px" src='https://i.imgur.com/xoQhN1x.png'></img>

        <Text style={{ fontSize: '16px'}}>
        The ultimate platform for discovering developers, mods, and opportunities in the FiveM and games community. Stay up-to-date with the latest releases and trends.
        </Text>

        <Text style={{ fontSize: '16px'}}>
        Shop confidently with our  <Tag color='green'> <CheckCircleOutlined /> Verified</Tag>developer system. Say goodbye to scams and compromised resources—here, you’ll only find official releases from trusted developers.
        </Text>

        <Text style={{ fontSize: '16px'}}>
        Earn <Tag color='orange'><strong>150</strong> Vote Coins</Tag>every day by logging in and voting for your favorite developers! Use your coins in our marketplace to grab exclusive discount codes, gift cards. Some deals even offer 100% discounts, giving you access to premium scripts for free!
       </Text>

<Row gutter={[20,20]}>
  <Col>
<Button size='large'> More information</Button>
      </Col>
      <Col>
<Button size='large'> Login with discord</Button>
      </Col>
      </Row>

      </div>
    </Col>
    
    <Col md={12} style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
      <Row gutter={[40, 40]} justify="end" style={{marginBottom:'40px'}}>
        <Col span={12}>
          <Card hoverable>
            <Text strong>Community</Text>
            <Text type="secondary" style={{ display: 'block', marginTop: '8px' }}>Join the conversation! Whether you need help with your server, want to vote for the ROTM, or just chat, the community is the place for you!</Text>
          </Card>
        </Col>
        <Col span={12}>
          <Card hoverable>
            <Text strong>Releases</Text>
            <Text type="secondary" style={{ display: 'block', marginTop: '8px' }}>Discover the latest mods and content updates from the FiveM and games community, seamlessly integrated from Tebex and GitHub, all in one place.</Text>
          </Card>
        </Col>
      </Row>
      <Row gutter={[40, 40]} justify="end">
        <Col span={12}>
          <Card hoverable>
            
            <Text strong>Rankings</Text>
            <Text type="secondary" style={{ display: 'block', marginTop: '8px' }}>Explore the top verified developers in the community and quickly connect with the best fit for your needs, saving you time and effort.</Text>
          </Card>
        </Col>
        <Col span={12}>
          <Card hoverable>
            <Text strong>Jobs </Text><Text style={{fontSize:'12px', color:'#888'}}>(Soon)</Text>
            <Text type="secondary" style={{ display: 'block', marginTop: '8px' }}>A secure platform that guarantees satisfaction, with payments protected until the job is successfully completed, or your money back.</Text>
          </Card>
        </Col>
      </Row>
    </Col>

  </Row>
</section>



      <section className="random-developers-section" style={{ marginBottom: '40px' }}>
        <Row gutter={[24, 24]}>
          <Col xs={24} lg={14}>
            <Title level={4} style={{ background: 'linear-gradient(90deg, rgb(251 255 0 / 26%) 0%, rgba(255, 255, 255, 0) 70%)', width: '100%', borderRadius: '8px', padding: '5px' }}>
              <TrophyOutlined /> Release of the Month
            </Title>
            
            {rotmData ? (
        <Card title={rotmData.release_name || 'Unknown Release'} hoverable extra={<Tag color='gold'>ROTM</Tag>}>
          <Row gutter={[20, 20]}>
            <Col md={10}>
              <Card style={{ display: 'flex', height: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                  <div>
                    <Text strong>The mod</Text>
                    <br />
                    <Text style={{ fontSize: '12px' }}>{rotmData.mod_description || 'No description available.'}</Text>
                  </div>
                  <div>
                    <Text strong>About {rotmData.developer_name}</Text>
                    <br></br>
                    <Text style={{ fontSize: '12px' }}>{rotmData.dev_description || 'No description available.'}</Text>
                  </div>
                  <Button href={`/profile/${rotmData.developer_name}`}>
                    Developer Profile
                  </Button>
                </div>
              </Card>
            </Col>
            <Col md={14}>
  {!isVideoLoaded ? (
    rotmData.video_url ? (
      <div>
        {/* Static image preview */}
        <img
          src={`https://img.youtube.com/vi/${rotmData.video_url.split('v=')[1]}/hqdefault.jpg`}
          alt="Release of the Month Preview"
          style={{ width: '100%', height: '280px', cursor: 'pointer', borderRadius: '8px' }}
          onClick={() => setIsVideoLoaded(true)}
        />
        {/* Play button */}
        <Button
          onClick={() => setIsVideoLoaded(true)}
          type="primary"
          size="large"
          style={{
            position: 'absolute',
            top: '45%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            cursor: 'pointer',
          }}
        >
          <CaretRightOutlined style={{ fontSize: '30px', color: '#111' }} />
        </Button>
      </div>
    ) : (
      <Text>No video available.</Text>
    )
  ) : (
<iframe
  width="100%"
  height="280px"
  src={`${embedVideoUrl}?autoplay=1`}
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
  style={{ borderRadius: '8px' }}
></iframe>

  )}
  <Button type="primary" href={`/release/${rotmData.linked_release}`} style={{ marginTop: '10px', width: '100%' }}>
    Go to Release
  </Button>
</Col>
          </Row>
        </Card>
      ) : (
        <Text>No Release of the Month available.</Text>
      )}

            <section className="latest-releases-section" style={{ marginTop: '30px' }}>
              <Title level={4} style={{ background: 'linear-gradient(90deg, rgb(255 0 0 / 18%) 0%, rgba(255, 255, 255, 0) 70%)', width: '100%', borderRadius: '8px', padding: '5px' }}>
                <RocketOutlined /> Latest Releases
              </Title>
              <Card>
                <List
                  dataSource={latestReleases}
                  renderItem={renderLatestRelease}
                  size="small"
                />
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                {releaseCount > 10 && (
                  <Button style={{ marginRight: '10px' }}  href="/release">Go to release page</Button>
                  )}
                {releaseCount < 10 && (
                  <Button style={{ marginRight: '10px' }} onClick={() => setReleaseCount(releaseCount + 3)}>Show more releases</Button>
                  )}
                  {releaseCount > 3 && releaseCount < 10 && (
                    <Button onClick={() => setReleaseCount(releaseCount - 3)}>Show less releases</Button>
                  )}
                </div>
              </Card>
            </section>
          </Col>

          <Col xs={24} lg={10}>
            <Title level={4} style={{ background: 'linear-gradient(90deg, rgb(171 39 195 / 32%) 0%, rgba(255, 255, 255, 0) 80%)', width: '100%', borderRadius: '8px', padding: '5px' }}>
              <ThunderboltOutlined /> Featured Developers
            </Title>
            {loading ? <Spin size="large" style={{ display: 'block', margin: '0 auto' }} /> : (
              <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '20px' }}>
                {randomDevelopers.map((developer) => (
                  <div key={developer.id} style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    {renderDeveloperCard(developer)}
                  </div>
                ))}
              </div>
            )}
          </Col>
        </Row>
      </section>
    </div>
  );
};
export default Home;
