import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';  // Import the Home component directly
import Callback from './pages/components/callback/Callback';
import ControlPanel from './pages/components/user/controlpanel';
import Layout from './pages/components/Layout'; 
import Ranking from './pages/Ranking'; 
import Profile from './pages/Profile';
import Marketplace from './pages/Marketplace';
import PrivateRoute from './PrivateRoute';  
import ThemeProvider from './pages/components/utils/ThemeContext';
import { ConfigProvider, App as AntApp } from 'antd';
import './App.css'
import { Helmet } from 'react-helmet';
import ReleasePage from './pages/ReleasePage';
import ReleaseDetailPage from './pages/ReleaseDetailPage';
import axios from 'axios';
import RoleSelectionManager from './pages/components/user/controlpanel/RoleSelectionManager';
axios.defaults.withCredentials = true;


function App() {
  return (
    <ConfigProvider theme={{ token: {} }}>
      <AntApp>  {/* Wrap the entire application in AntApp */}
      <div>

      </div>
        <ThemeProvider>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="ranking" element={<Ranking />} /> 
              <Route path="Release" element={<ReleasePage />} />
              <Route path="Marketplace" element={<Marketplace />} />
              <Route path="marketplace/developer/:developerId" element={<Marketplace />} /> {/* Add route for developer shop */}
              <Route path="/profile/:nickname" element={<Profile />} />
              <Route path="/release/:releaseId" element={<ReleaseDetailPage />} />

              {/* Private route for control panel */}
              <Route
                path="controlpanel"
                element={
                  <PrivateRoute>
                          <RoleSelectionManager />
                  </PrivateRoute>
                }
              />
            </Route>
            {/* Discord callback */}
            <Route path="/callback" element={<Callback />} />
          </Routes>
        </ThemeProvider>
      </AntApp>
    </ConfigProvider>
  );
}

export default App;
