import React, { useState, useEffect, useContext } from 'react';
import { List, Button, Card, Space, Tag, Typography, message, Input, Select, Pagination, Row, Col, Spin, AutoComplete, Segmented } from 'antd';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { IoDiamondOutline } from "react-icons/io5";
import { CaretUpOutlined, CaretDownOutlined, EyeOutlined, CloudServerOutlined, BarsOutlined, FireOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import { ThemeContext } from './components/utils/ThemeContext';
import { useDebounce } from './hooks/useDebounce';
import axiosInstance from '../axiosConfig';

const { Text, Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const ReleasePage = () => {
  const { releaseId } = useParams();
  const { isDarkMode } = useContext(ThemeContext);
  const [releases, setReleases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterSource, setFilterSource] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [filterPrice, setFilterPrice] = useState('');
  const [sortOption, setSortOption] = useState('newer');
  const [trending, setTrending] = useState(false);
  const [userPosts, setUserPosts] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const [hiddenGem, setHiddenGem] = useState(null);
  const [hiddenGemIds, setHiddenGemIds] = useState([]);
  const [isShaking, setIsShaking] = useState(false);
  const [isFlipping, setIsFlipping] = useState(false);
  const [loadingGem, setLoadingGem] = useState(false);
  const [hiddenGemOnLoad, setHiddenGemOnLoad] = useState(null);
  const [recentGems, setRecentGems] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const MAX_RETRIES = 5;
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const pageSize = 7;
  const darkGradient = `linear-gradient(0deg, rgba(0, 0, 0, 0.8) 10%, rgba(0, 0, 0, 1) 100%)`;
  const lightGradient = `linear-gradient(0deg, rgba(255, 255, 255, 0.8) 10%, rgba(255, 255, 255, 1) 100%)`;
  const [loadImages, setLoadImages] = useState(true);

  useEffect(() => {
      fetchReleases(); 
  }, [filterSource, filterCategory, filterPrice, sortOption, trending, userPosts, currentPage]);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3 || debouncedSearchTerm === '') {
      fetchSuggestions(debouncedSearchTerm);
    } else {
      setSuggestions([]);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    fetchHiddenGems();
  }, []);
  
  const fetchHiddenGems = async () => {
    try {
      const response = await axiosInstance.get('/hidden-gems', { withCredentials: true });
      setHiddenGemIds(response.data);
      setLoadImages(true);
  
      if (response.data.length > 0) {
        selectRandomHiddenGem(response.data, 0);
      }
    } catch (error) {
      console.error('Error fetching hidden gems:', error);
    }
  };
  
  const stripHtmlTags = (text) => {
    const noHtml = text.replace(/<\/?[^>]+(>|$)/g, "");
    return noHtml.replace(/https?:\/\/[^\s]+?\.(png|jpg)/gi, "");
  };
  

  const highlightText = (text, searchTerm) => {
    const keywords = searchTerm.split(' ').filter(Boolean).slice(0, 20);
    let highlightedText = text;
  
    keywords.forEach((keyword, index) => {
      try {
        const regex = new RegExp(`(${keyword})`, 'gi');
        const color = index % 2 === 0 ? '#ffa500' : '#00b6ff';
        highlightedText = highlightedText.replace(
          regex,
          `<span style="color:${color}; font-weight: bold;">$1</span>`
        );
      } catch (error) {
        console.warn(`Failed to highlight the keyword: ${keyword}`, error);
      }
    });
  
    return highlightedText;
  };
  
  const handleCategoryFilter = (selectedValue) => {
    setFilterCategory(selectedValue);

    if (selectedValue) {
      const selectedValueLower = selectedValue.toLowerCase().trim();

      const filteredReleases = releases.filter(release => {
        const category = release.category.toLowerCase().trim();

        if (selectedValueLower === 'esx') {
          return category.includes('esx') || (category.includes('qb') && category.includes('esx'));
        } else if (selectedValueLower === 'qbcore') {
          return category.includes('qbcore') || (category.includes('esx') && category.includes('qb'));
        } else {
          return category.includes(selectedValueLower);
        }
      });

      setReleases(filteredReleases);
    } else {
      fetchReleases();
    }
  };

  const handleSegmentChange = (value) => {
    if (value === 'All Releases') {
      setTrending(false);
      setUserPosts(false);
    } else if (value === 'Hot Releases (24h)') {
      setTrending(true);
      setUserPosts(false);
    } else if (value === 'My Posts') {
      setUserPosts(true);
      setTrending(false);
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    setLoadImages(false);
  
    try {
      const params = {
        search: debouncedSearchTerm,
        sort: sortOption,
        trending: trending ? '1' : '0',
        userPosts: userPosts ? true : null,
        page: currentPage,
        limit: pageSize,
      };
  
      if (filterSource) params.source = filterSource;
      if (filterCategory) params.category = filterCategory;
      if (filterPrice) params.priceType = filterPrice;
  
      const response = await axiosInstance.get('/releases', {
        params,
        withCredentials: true,
      });
  
      setReleases(response.data.releases);
      setTotalItems(response.data.total);
    } catch (error) {
      console.error('Error fetching releases:', error);
      showMessage('error', 'Failed to fetch releases.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const fetchSuggestions = async (searchValue) => {
    try {
      if (searchValue) {
        const response = await axiosInstance.get(`/search`, {
          params: { searchTerm: searchValue },
          withCredentials: true,
        });

        const results = response.data.map(item => ({
          value: `release-${item.id}`,
          label: (
            <div key={item.id} style={{ padding: '5px 0', display: 'flex', flexDirection: 'column' }}>
              <strong dangerouslySetInnerHTML={{ __html: highlightText(item.name, searchValue) }} />
              <span style={{ fontSize: '12px', color: '#888' }} dangerouslySetInnerHTML={{ __html: highlightText(stripHtmlTags(item.description).slice(0, 100), searchValue) }} />
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                  <Tag>{item.source === 'tebex' ? 'Tebex' : 'GitHub'}</Tag>
                  <Tag>{item.category}</Tag>
                  {item.price && parseFloat(item.price) !== 0 ? (
                    <Tag color="purple">{item.price} {item.currency}</Tag>
                  ) : (
                    <Tag color="green">Free</Tag>
                  )}
                </div>
              </div>
            </div>
          ),
        }));
        setSuggestions([...results]);
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      console.error('Error fetching search suggestions:', error);
    }
  };

  const selectRandomHiddenGem = async (hiddenGems, retryCount = 0) => {
    if (retryCount >= MAX_RETRIES || hiddenGems.length === 0) return;
  
    const randomIndex = Math.floor(Math.random() * hiddenGems.length);
    const randomGem = hiddenGems[randomIndex];
  
    try {
      const response = await axiosInstance.get(`/releases/${randomGem.release_id}`);
      if (response.data) {
        setHiddenGem({
          ...response.data,
          description_g: randomGem.description_g,
        });
      } else {
        // Retry with a different gem
        selectRandomHiddenGem(hiddenGems, retryCount + 1);
      }
    } catch (error) {
      console.error('Error fetching hidden gem:', error);
    }
  };
  

  const handleRollTheDice = () => {
    setIsShaking(true);
    setLoadingGem(true);

    setTimeout(() => {
      const availableGems = hiddenGemIds.filter(gem => gem.release_id !== hiddenGem?.release_id);

      const randomGem = availableGems[Math.floor(Math.random() * availableGems.length)];

      setTimeout(() => {
        setIsShaking(false);

        selectRandomHiddenGem([randomGem], 0);
        setLoadingGem(false);
      }, 500);
    }, 500);
  };

  const fetchReleases = async () => {
    setLoading(true);
    try {
      const params = {
        search: searchTerm,
        sort: sortOption,
        trending: trending ? '1' : '0',
        userPosts: userPosts ? true : null,
        page: currentPage,
        limit: pageSize,
      };
  
      if (filterSource) params.source = filterSource; // Only add if a filter is set
      if (filterCategory) params.category = filterCategory; // Only add if a filter is set
      if (filterPrice) params.priceType = filterPrice; // Only add if a filter is set
  
      const response = await axiosInstance.get('/releases', {
        params,
        withCredentials: true,
      });
  
      setReleases(response.data.releases);
      setTotalItems(response.data.total);
    } catch (error) {
      console.error('Error fetching releases:', error);
      showMessage('error', 'Failed to fetch releases.');
    } finally {
      setLoading(false);
    }
  };
  

  const showMessage = (type, content) => {
    messageApi.open({
      type,
      content,
    });
  };

  const handleVote = async (releaseId, voteType) => {
    try {
      const response = await axiosInstance.post(
        `/releases/${releaseId}/vote`,
        { vote_type: voteType },
        { withCredentials: true }
      );

      if (response.status === 200 || response.status === 201) {
        showMessage('success', `You have ${voteType}d the release.`);
        fetchReleases();
      } else {
        showMessage('error', `Failed to ${voteType} the release.`);
      }
    } catch (error) {
      console.error('Error submitting vote:', error);
      showMessage('error', 'Failed to submit vote. You might have already voted or need to log in.');
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSelect = (value) => {
    if (value === 'show-all') {
      window.location.href = `/release/search?q=${searchTerm}`;
    } else {
      const releaseId = value.split('-')[1];
      window.location.href = `/release/${releaseId}`;
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: '15vw',
        paddingRight: '15vw',
        marginTop: '50px'
      }}
    >
      {contextHolder}

      <Row gutter={[20, 20]} style={{ width: '100%' }}>

        <Col>

          <div style={{ display: 'flex' }}>

            <Col>
              <Row gutter={[15, 15]}>
                  <Col md={18}>
                    <AutoComplete
                      options={suggestions}
                      onSearch={handleSearchChange}
                      placeholder="Search by product name or description hints..."
                      style={{ width: '100%' }}
                      onSelect={handleSelect}
                    >
                       <Search
                          enterButton={<SearchOutlined />}
                       />
                    </AutoComplete>
                  </Col>

                  <Col md={6}>
                    <Select
                      placeholder="Filter by Source"
                      style={{ width: '100%' }}
                      onChange={(value) => setFilterSource(value)}
                    >
                      <Option value="">All</Option>
                      <Option value="Tebex">Tebex</Option>
                      <Option value="GitHub">GitHub</Option>
                    </Select>
                  </Col>

                  <Col>
                    <Segmented
                      options={[
                        { label: 'All', value: '' },
                        { label: 'Free', value: 'Free' },
                        { label: 'Paid', value: 'Paid' },
                      ]}
                      value={filterPrice}
                      onChange={setFilterPrice}
                    />
                  </Col>

                  <Col>
                    <Segmented
                      options={[
                        { label: 'All', value: '' },
                        { label: 'ESX', value: 'esx' },
                        { label: 'QBCore', value: 'qbcore' },
                      ]}
                      value={filterCategory}
                      onChange={handleCategoryFilter}
                    />
                  </Col>

                  <Col xs={24} md={18}>
                    <Row gutter={[15, 15]}>
                      <Col>
                        <Segmented
                          options={[
                            { label: 'All releases', value: 'All Releases', icon: <BarsOutlined /> },
                            { label: 'Hot release', value: 'Hot Releases (24h)', icon: <FireOutlined /> },
                            ...(localStorage.getItem('token') ? [{ label: 'My Posts', value: 'My Posts', icon: <UserOutlined /> }] : []),
                          ]}
                          value={userPosts ? 'My Posts' : trending ? 'Hot Releases (24h)' : 'All Releases'}
                          onChange={handleSegmentChange}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col>
                    <Segmented
                      options={[
                        { label: 'New', value: 'newer' },
                        { label: 'Old', value: 'older' },
                        { label: 'Popular', value: 'upvotes' },
                      ]}
                      value={sortOption}
                      onChange={setSortOption}
                    />
                  </Col>

              </Row>

              <List
                loading={loading}
                itemLayout="vertical"
                dataSource={releases}
                renderItem={(release) => (
                  <List.Item key={release.id}>
                    <Link to={`/release/${release.id}`}>
                      <Card>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Text>{release.name}</Text>
                            <div>
                              <Tag color="default">
                                <EyeOutlined /> {release.views}
                              </Tag>
                              <Tag color="green">
                                <CaretUpOutlined /> {release.upvotes}
                              </Tag>
                              <Tag color="red">
                                <CaretDownOutlined /> {release.downvotes}
                              </Tag>
                            </div>
                          </div>

                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                              <Tag>{release.source === 'tebex' ? 'Tebex' : 'GitHub'}</Tag>
                              <Tag>{release.category}</Tag>
                              {release.price && parseFloat(release.price) !== 0 ? (
                                <Text>
                                  <Tag color="purple">Paid</Tag>
                                  <Tag>
                                    {release.price} {release.currency}
                                  </Tag>
                                </Text>
                              ) : (
                                <Tag color="green">Free</Tag>
                              )}
                              {release.subcategory && (
                                <span style={{ marginLeft: '10px' }}>
                                  <Text strong>Subcategory:</Text> {release.subcategory}
                                </span>
                              )}
                            </div>
                            <div>
                              <div style={{ color: '#888', fontSize: '12px' }}>
                                {release.nickname} updated on {new Date(release.created_at).toLocaleDateString()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Link>
                  </List.Item>
                )}
              />

              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalItems}
                onChange={handlePageChange}
                style={{ padding: '25px', justifyContent: 'center' }}
                showSizeChanger={false}
              />
            </Col>

            <Col xs={24} md={8} style={{ height: '100%', display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
              <div style={{ width: '100%', gap: '10px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Card
                  style={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundImage: hiddenGem ? `${isDarkMode ? darkGradient : lightGradient}, url(${hiddenGem?.image})` : 'none',
                    transition: 'opacity 0.5s ease-in-out',
                    animation: isShaking ? 'shake 0.5s' : 'none',
                    perspective: '1000px'
                  }}
                  title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IoDiamondOutline style={{ marginRight: '5px', color: '#00b6ff' }} />
                      Hidden gem
                    </div>
                  }
                  extra={<Button onClick={handleRollTheDice}>Roll the dice</Button>}
                >
                  {loadingGem ? (
                    <Spin size="large" />
                  ) : hiddenGem ? (
                    <Link to={`/release/${hiddenGem.release_id}`}>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Text>{hiddenGem.name}</Text>
                            <Text style={{ fontSize: '12px', color: '#888' }}>{hiddenGem.nickname}</Text>
                          </div>
                          {hiddenGem.price && parseFloat(hiddenGem.price) !== 0 ? (
                            <Tag color="purple" style={{ height: 'fit-content' }}>Paid</Tag>
                          ) : (
                            <Tag color="green" style={{ height: 'fit-content' }}>Free</Tag>
                          )}
                        </div>
                        <Text style={{ color: '#888' }}>{hiddenGem.description_g}</Text>
                      </div>
                    </Link>
                  ) : (
                    'No hidden gem found.'
                  )}
                </Card>
                <Card
                  title="Featured partner"
                  extra={<Button>Website</Button>}
                  bodyStyle={{ backgroundImage: 'url(https://yt3.googleusercontent.com/B4UwzZWGUUPOtHPMjTLo8ahhky4R_fZkxZ4QHnZfM7u9lY-1eBav5t7rRFuRl4LVytlqY0AJLg=s900-c-k-c0x00ffffff-no-rj)', height: '100px', backgroundSize: 'cover', backgroundPosition: 'center' }}
                >
                </Card>

                <Card
                  title={<div><CloudServerOutlined /> Hosting partner</div>}
                  extra={<Button>Website</Button>}
                  bodyStyle={{ backgroundImage: 'url(https://blog.netsons.com/wp-content/uploads/2021/04/25.-Arrivano-i-nuovi-piani-di-Cloud-Hosting-Netsons-1.jpg)', height: '100px', backgroundSize: 'cover', backgroundPosition: 'center' }}
                >
                </Card>

                <Card style={{ height: '150px' }} title="Space for ads" extra={<Button>Website</Button>}>
                  Free space!
                </Card>
              </div>
            </Col>
          </div>
        </Col>

      </Row>
    </div>
  );
};

export default ReleasePage;
