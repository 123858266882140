import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Table, Button, Tooltip, Tag, Select, Space, Badge, Typography, Rate, Input, Drawer, AutoComplete, Row, Col, Card } from 'antd';
import { GlobalOutlined, LikeOutlined, CheckCircleOutlined, CloseCircleOutlined, UserOutlined, RightCircleFilled, CloseOutlined } from '@ant-design/icons';
import { FaTrophy } from "react-icons/fa";
import { BsDiscord } from "react-icons/bs";
import { Helmet } from 'react-helmet';
import axiosInstance from '../axiosConfig';
import { BASE_URL, BASE_URL_CB } from '../config';
import { ThemeContext } from './components/utils/ThemeContext';


const { Title, Text } = Typography;
const { Option } = Select;
const { Search } = Input;

const Ranking = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const [developers, setDevelopers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [games, setGames] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All categories');
  const [selectedGame, setSelectedGame] = useState('All games');
  const [searchTerm, setSearchTerm] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerTags, setDrawerTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const darkGradient = `linear-gradient(0deg, rgba(0, 0, 0, 0.8) 10%, rgba(0, 0, 0, 1) 100%)`;
  const lightGradient = `linear-gradient(0deg, rgba(255, 255, 255, 0.8) 10%, rgba(255, 255, 255, 1) 100%)`;

  useEffect(() => {
    fetchTopDevelopers();
  }, [selectedCategory, selectedGame, selectedTags]);

  const fetchTopDevelopers = async () => {
    try {
      const response = await axiosInstance.get('developers/top');


      const uniqueDevelopers = Array.from(new Map(response.data.map(dev => [dev.id, dev])).values());

      const allCategories = [...new Set(uniqueDevelopers.map(dev => dev.category.split(',')).flat())];
      const allGames = [...new Set(uniqueDevelopers.map(dev => dev.games.split(',')).flat())];
      const allTags = [...new Set(uniqueDevelopers.map(dev => dev.tags?.split(',')).flat())];

      setDevelopers(uniqueDevelopers);
      setCategories(['All categories', ...allCategories]);
      setGames(['All games', ...allGames]);
      setTagOptions(allTags);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching top developers:', error);
      setLoading(false);
    }
  };


  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleGameChange = (value) => {
    setSelectedGame(value);
  };

  const handleSearch = (value) => {
    setSearchTerm(value.toLowerCase());
  };

  const handleTagSelect = (value) => {
    if (!selectedTags.includes(value.toLowerCase())) {
      setSelectedTags([...selectedTags, value.toLowerCase()]);
    }
  };

  const handleRemoveTag = (tag) => {
    setSelectedTags(selectedTags.filter(t => t !== tag));
  };

  const filteredDevelopers = Array.from(
    new Map(
      developers
        .filter((developer) => {
          const matchesCategory = selectedCategory === 'All categories' || developer.category.includes(selectedCategory);
          const matchesGame = selectedGame === 'All games' || developer.games.includes(selectedGame);
          const matchesSearch = developer.nickname.toLowerCase().includes(searchTerm);
          const matchesTags = selectedTags.length === 0 || selectedTags.every(tag => developer.tags && developer.tags.toLowerCase().includes(tag));
          return matchesCategory && matchesGame && matchesSearch && matchesTags;
        })
        .map(dev => [dev.id, dev])
    ).values()
  );


  const getMetallicClass = (index) => {
    if (index === 0) return 'ribbon-metallic-gold';
    if (index === 1) return 'ribbon-metallic-silver';
    if (index === 2) return 'ribbon-metallic-bronze';
    return 'ribbon-metallic';
  };

  const getTagColor = (tag) => {
    if (tag.toLowerCase().includes('qbcore')) return 'magenta';
    if (tag.toLowerCase().includes('esx')) return 'geekblue';
    if (tag.toLowerCase().includes('cheap')) return 'lime';
    return 'default';
  };

  const StarRating = ({ rating }) => (
    <Rate allowHalf value={rating} disabled className="custom-rating" />
  );

  const TagList = ({ tags }) => {
    const visibleTags = tags.slice(0, 5);
    const extraTags = tags.length > 5 ? tags.slice(5) : [];

    const showDrawer = () => {
      setDrawerTags(tags);
      setDrawerVisible(true);
    };

    return (
      <>
        {visibleTags.map((tag, index) => (
          <Tag key={index} color={getTagColor(tag)}>{tag}</Tag>
        ))}
        {extraTags.length > 0 && (
          <Tag style={{ cursor: 'pointer' }} color="default" onClick={showDrawer}>
            +{extraTags.length} more
          </Tag>
        )}
      </>
    );
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const columns = [
    {
      title: (
        <div style={{ textAlign: 'left', margin: '0px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Title level={4} style={{ margin: 0 }}>
              Ranking
            </Title>
            <span style={{ fontSize: '12px', color: '#888', margin: '0px' }}> Results: {filteredDevelopers.length}</span>
          </div>

          <div style={{ fontSize: '14px', color: '#888', display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {selectedGame !== 'All games' && <span style={{ marginRight: '0px' }}> {selectedGame}&nbsp;</span>}
              {selectedCategory !== 'All categories' && <span> {selectedCategory}</span>}
            </div>
            {selectedTags.length > 0 && (
              <span style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', columnGap: '5px', rowGap: '5px' }}>
                {selectedTags.map(tag => (
                  <Tag
                    key={tag}
                    style={{ margin: '0px', display: 'flex', alignItems: 'center' }}
                    color={getTagColor(tag)}
                    closable
                    onClose={() => handleRemoveTag(tag)}
                  >
                    {tag}
                  </Tag>
                ))}
              </span>
            )}
          </div>

        </div>
      ),
      dataIndex: 'nickname',
      key: 'nickname',
      render: (text, record, index) => (


        <Row gutter={[20, 20]}>
          <Col md={20}>
          <Link to={`/profile/${record.nickname}`} style={{ fontSize: '18px', fontWeight: '600', margin:'0px'}}>
              {record.nickname} 
          </Link>
          </Col>
          <Col md={4} style={{display:'flex',justifyContent:"end", height:'fit-content'}}>
{record.verified ? (
                <Tooltip title={
                  <span>
                    Verified {record.games} developer.<br></br> <a href="#" style={{ color: 'lightgreen' }}>More info</a>
                  </span>
                }>
 <Tag color="green"><CheckCircleOutlined /> Verified</Tag></Tooltip>
              ) : (
                <Tooltip title={
                  <span>
                    This is not a verified {record.games} developer. Probably is a new developer.<br></br> <a href="#" style={{ color: 'darkorange' }}>More info</a>
                  </span>
                }> <Tag color="red"><CloseCircleOutlined /> Not Verified</Tag></Tooltip>
              )}
          </Col>
          <Col md={24}>
            <Badge.Ribbon className={getMetallicClass(index)} text={
              <p style={{ color: index < 3 ? '#272727' : '#272727', margin: '0px', padding: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: '5px' }}>
                <FaTrophy /> {index + 1}
              </p>
            } color={index < 3 ? undefined : ''} placement="end">
              <a href={`/profile/${record.nickname}`}>
              <img src={record.ranking_img_url ? `${BASE_URL}${record.ranking_img_url}` : `${BASE_URL}/default-banner.png`} alt="Banner" className="developer-banner" />
              </a>
            </Badge.Ribbon>

            <div style={{ display: 'flex', columnGap: '10px', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <TagList tags={record.tags ? record.tags.split(',') : []} />
              </div>
              <p style={{ margin: '0px', padding: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: '5px' }}>
                <LikeOutlined />
                {record.total_votes || 0}
              </p>
            </div>
            <Title level={5} style={{ margin: '0px', marginTop: '0px', color: 'grey', fontSize: '15px', fontWeight: '100', padding: '5px' }}>{record.motto}</Title>
          </Col>
        </Row>

      ),
    },
  ];

  return (
    <div className="ranking-container">
      <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
        <Col sm={24} md={10}>
          <Search
            placeholder="Search by nickname"
            onSearch={handleSearch}
            enterButton
          />
        </Col>
        <Col sm={24} md={5}>
          <Select value={selectedGame} onChange={handleGameChange} allowClear className="filter-select">
            {games.map((game) => (
              <Option key={game} value={game}>{game}</Option>
            ))}
          </Select>
        </Col>
        <Col sm={24} md={5}>
          <Select value={selectedCategory} onChange={handleCategoryChange} allowClear className="filter-select">
            {categories.map((category) => (
              <Option key={category} value={category}>{category}</Option>
            ))}
          </Select>
        </Col>
        <Col sm={24} md={4}>
          <AutoComplete
            options={tagOptions.map(tag => ({ value: tag }))}
            onSelect={handleTagSelect}
            placeholder="Filter by tag"
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
<Row gutter={[30,20]}>
<Col md={16}>
          <Table
            columns={columns}
            dataSource={filteredDevelopers.map((dev, idx) => ({ ...dev, rank: idx + 1 }))}
            loading={loading}
            pagination={false}
            rowKey="id"
            className="developer-table"
          />

        </Col>
        <Col md={8}>
        <Card style={{marginBottom:'30px'}}>
      <Text type='secondary'>Ranking reset every 26th of the month at midnight.</Text>
        </Card>
        <Card
  style={{
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `${isDarkMode ? darkGradient : lightGradient}, url("https://albertadentalimplants.ca/wp-content/uploads/2022/10/Placeholder-Ads-.jpg")`,
    height: '150px',
    marginBottom:'30px'
  }}
  title={
    <div style={{ display: 'flex', alignItems: 'center' }}>
      Partner Slot
    </div>
  }
  extra={
    <Button size='small' type="default">
      Go to website
    </Button>
  }
>
Partner here!<br></br>
      <Text type='secondary'>Second line</Text>
        </Card>

        <Card
  style={{
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `${isDarkMode ? darkGradient : lightGradient}, url("https://albertadentalimplants.ca/wp-content/uploads/2022/10/Placeholder-Ads-.jpg")`,
    marginBottom:'30px'
  }}
>
Premium user can randomly appear hear!<br></br>
      <Text type='secondary'>Visit developer profile</Text>
        </Card>

        <Card
  style={{
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `${isDarkMode ? darkGradient : lightGradient}, url("https://albertadentalimplants.ca/wp-content/uploads/2022/10/Placeholder-Ads-.jpg")`,
    marginBottom:'30px'
  }}
>
Premium user can randomly appear hear!<br></br>
      <Text type='secondary'>Visit developer profile</Text>
        </Card>

        <Card
  style={{
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `${isDarkMode ? darkGradient : lightGradient}, url("https://albertadentalimplants.ca/wp-content/uploads/2022/10/Placeholder-Ads-.jpg")`,
    height: '150px',
    marginBottom:'30px'
  }}
  title={
    <div style={{ display: 'flex', alignItems: 'center' }}>
      Advertisement Slot
    </div>
  }
  extra={
    <Button size='small' type="default">
      Go to website
    </Button>
  }
>
Your ads here!<br></br>
      <Text type='secondary'>Second line</Text>
        </Card>

           <Card
  style={{
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `${isDarkMode ? darkGradient : lightGradient}, url("https://albertadentalimplants.ca/wp-content/uploads/2022/10/Placeholder-Ads-.jpg")`,
    marginBottom:'30px'
  }}
>
Premium user can randomly appear hear!<br></br>
      <Text type='secondary'>Visit developer profile</Text>
        </Card>

        <Card
  style={{
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `${isDarkMode ? darkGradient : lightGradient}, url("https://albertadentalimplants.ca/wp-content/uploads/2022/10/Placeholder-Ads-.jpg")`,
    marginBottom:'30px'
  }}
>
Premium user can randomly appear hear!<br></br>
      <Text type='secondary'>Visit developer profile</Text>
        </Card>

        <Card
  style={{
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `${isDarkMode ? darkGradient : lightGradient}, url("https://albertadentalimplants.ca/wp-content/uploads/2022/10/Placeholder-Ads-.jpg")`,
    height: '150px',
    marginBottom:'30px'
  }}
  title={
    <div style={{ display: 'flex', alignItems: 'center' }}>
      Advertisement Slot
    </div>
  }
  extra={
    <Button size='small' type="default">
      Go to website
    </Button>
  }
>
Your ads here!<br></br>
      <Text type='secondary'>Second line</Text>
        </Card>     
        </Col>
        
</Row>



      <Drawer
        title="Additional Tags"
        placement="right"
        onClose={closeDrawer}
        open={drawerVisible}
      >
        {drawerTags.map((tag, index) => (
          <Tag key={index} color={getTagColor(tag)}>{tag}</Tag>
        ))}
      </Drawer>
    </div>
  );
};

export default Ranking;
