import React, { useRef, useState, useEffect } from 'react';
import { Form, Input, Button, Select, Table, Modal, message, Space, Tag, Radio, List, Tour, Row, Col, Popconfirm  } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, UnorderedListOutlined, DeleteFilled, EditFilled,EllipsisOutlined, CopyrightTwoTone  } from '@ant-design/icons';
import axios from 'axios';
import axiosInstance from '../../../../axiosConfig';

const { Option } = Select;

const ShopManager = ({ userId }) => {
  const [shopItems, setShopItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isCodeModalVisible, setIsCodeModalVisible] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [codeMode, setCodeMode] = useState('single');
  const [itemType, setitemType] = useState('discount_code');
  const [codes, setCodes] = useState([]);
  const [editingCode, setEditingCode] = useState(null);
  const [form] = Form.useForm(); // Ensure you initialize the form instance
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [releases, setReleases] = useState([]);
  const [user, setUser] = useState({ userId: '', username: '' });
  const [singleStock, setSingleStock] = useState(1); // Separate state for stock in single code mode
  
useEffect(() => {
  if (userId) {
    fetchShopItems(userId);
  }
}, [userId]);

  useEffect(() => {
    const fetchUserAndData = async () => {
      try {
        const userResponse = await axiosInstance.get('/auth/user', { withCredentials: true });
        const user = {
          userId: userResponse.data.userId || userResponse.data.id, // Handle different property names
          username: userResponse.data.username,
        };
        setUser(user);
  
        // Fetch data only if userId is available
        if (user.userId) {
          console.log('Developer ID:', user.userId);
          fetchReleases(user.userId);
          fetchShopItems(user.userId); // Ensure userId is passed
        } else {
          console.error('No userId available.');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    fetchUserAndData();
  }, []);
  

  const fetchShopItems = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/shop-items/manager', { withCredentials: true });
      setShopItems(response.data); // Sync the latest items
      console.log('Updated shop items:', response.data);
    } catch (error) {
      messageApi.error('Failed to load shop items.');
      console.error('Error fetching shop items:', error);
    } finally {
      setLoading(false);
    }
  };
  
  
  
  
  
  const fetchReleases = async (developerId) => {
    try {
      const response = await axiosInstance.get(`/releases/dev/${developerId}`);
      setReleases(response.data);
    } catch (error) {
      console.error('Error fetching releases:', error);
    }
  };
  
  useEffect(() => {
    if (userId) {
      fetchReleases(userId); // Ensure userId is passed correctly here
    }
  }, [userId]);
  
  
  

  const handleAddItem = () => {
    setIsAddModalVisible(true);
    setEditingItem(null);
    form.resetFields();
    setCodes([]);
    setCodeMode('single'); 
    setitemType('discount_code'); 
  };


  const handleEditItem = async (item) => {
    console.log('Editing item:', item); // Verify the item data
    setIsEditModalVisible(true);
    setEditingItem(item);
    form.setFieldsValue(item);
  };
  

  const handleDeleteItem = async (itemId) => {
    console.log('Deleting item with ID:', itemId); // Verify if this logs correctly
    try {
      await axiosInstance.delete(`/shop-items/${itemId}`);
      messageApi.success('Item deleted successfully!');
      fetchShopItems();
    } catch (error) {
      console.error('Error deleting item:', error);
      messageApi.error('Failed to delete item.');
    }
  };
  
  
  

  const handleAddModalOk = async () => {
    try {
      const values = await form.validateFields();
      const itemData = {
        ...values,
        owner_type: 'developer',
        linked_release: values.linked_release || null,
        discount_balance: values.discount_balance || null,
        gift_card_balance: values.gift_card_balance || null,
        codes: codeMode === 'multiple' ? codes : [values.code],
        stock: codeMode === 'multiple' ? codes.length : singleStock,
        description: values.description || '',
      };
  
      console.log('itemData prepared for saving:', itemData);
  
      await axiosInstance.post('/shop-items', itemData);
      messageApi.success('Item added successfully!');
      
      await fetchShopItems(); // Force sync with backend
      setIsAddModalVisible(false);
      form.resetFields();
      setCodes([]);
      setSingleStock(1);
    } catch (error) {
      console.error('Error saving item:', error);
      messageApi.error(error.response?.data?.error || 'Failed to save item.');
    }
  };
  
  const handleEditModalOk = async () => {
    try {
      // Validate and retrieve only the necessary fields
      const values = await form.validateFields([
        'name',
        'description',
        'price_in_coins',
      ]);
  
      const itemData = {
        name: values.name,
        description: values.description || '', // Optional fallback
        price_in_coins: Number(values.price_in_coins) || 0, // Ensure it's numeric
        stock: editingItem.stock, // Use the current stock if it's not editable
      };
  
      console.log('Updating item with data:', itemData);
  
      // Call API to update the item
      await axiosInstance.put(`/shop-items/${editingItem.id}`, itemData);
  
      messageApi.success('Item updated successfully!');
      fetchShopItems(); // Refresh the list of items
      setIsEditModalVisible(false); // Close the modal
      form.resetFields(); // Clear form values
    } catch (error) {
      if (error.errorFields) {
        console.error('Validation errors:', error.errorFields);
        messageApi.error('Please correct the highlighted fields and try again.');
      } else {
        console.error('Error saving item:', error.response?.data || error.message || error);
        messageApi.error('Failed to save item.');
      }
    }
  };
  

  const handleModalCancel = () => {
    setIsAddModalVisible(false);
    setIsEditModalVisible(false);
    setEditingItem(null); // Reset editing state
    form.resetFields();
    setCodes([]);
  };
  

  const handleShowCodes = async (itemId) => {
    setIsCodeModalVisible(true);
    try {
      const response = await axiosInstance.get(`/shop-items/${itemId}`, {
        
      });
      setCodes(response.data.codes || []);
    } catch (error) {
      console.error('Error fetching codes:', error);
      messageApi.error('Failed to load codes.');
    }
  };

  const handleCodeModalCancel = () => {
    setIsCodeModalVisible(false);
    setCodes([]);
  };


    // Add code to list when in multiple code mode
    const addCodeToItem = () => {
        const codeInput = form.getFieldValue('code');
        if (codeInput && !codes.find((code) => code.code === codeInput)) {
          setCodes([...codes, { code: codeInput, is_used: false }]);
          form.setFieldsValue({ code: '' });
        }
      };

      const deleteCode = async (codeToRemove) => {
        try {
          // Normalize for comparison
          const normalizedCodeToRemove = codeToRemove.trim().toLowerCase();
      
          // Find the code to delete
          const codeObjToDelete = codes.find(code => code.code.trim().toLowerCase() === normalizedCodeToRemove);
      
          // Check if codeObjToDelete exists and has a valid id
          if (!codeObjToDelete || !codeObjToDelete.id) {
            console.error("Error deleting code: Code not found or missing ID.", { codeToRemove, codes });
            messageApi.error("Failed to delete code: Code not found or missing ID.");
            return;
          }
      
          // Perform deletion request
          await axiosInstance.delete(`/shop-items/${editingItem.id}/codes/${codeObjToDelete.id}`, {
            
          });
      
          // Update the codes array in state
          setCodes(codes.filter(code => code.id !== codeObjToDelete.id));
      
          messageApi.success('Code deleted successfully');
        } catch (error) {
          messageApi.error('Failed to delete code.');
          console.error('Error deleting code:', error);
        }
      };
      
      

  const updateCode = async (updatedCode) => {
    if (editingCode) {
      try {
        // Normalize for comparison
        const normalizedEditingCode = editingCode.trim().toLowerCase();
        const normalizedUpdatedCode = updatedCode.trim().toLowerCase();
  
        // Find the code in `codes` array
        const codeToUpdate = codes.find(code => code.code.trim().toLowerCase() === normalizedEditingCode);
  
        if (!codeToUpdate || !codeToUpdate.id) {
          console.error("Error updating code: Code not found or missing ID.", { editingCode, codes });
          throw new Error('Code to update not found or ID missing.');
        }
  
        // Perform the update request
        await axiosInstance.put(
          `/shop-items/${editingItem.id}/codes/${codeToUpdate.id}`,
          { code: normalizedUpdatedCode },
        );
  
        // Update state
        setCodes(
          codes.map(code =>
            code.code.trim().toLowerCase() === normalizedEditingCode ? { ...code, code: updatedCode } : code
          )
        );
        setEditingCode(null);
  
        console.log("Updated codes array after edit:", codes);
      } catch (error) {
        messageApi.error('Failed to update code.');
        console.error('Error updating code:', error);
      }
    }
  };
  
  
  
  
  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },

    { title: 'Type', dataIndex: 'item_type', key: 'item_type', render: (type) => <Tag>{type}</Tag> },
    {
      title: 'Coin price',
      dataIndex: 'price_in_coins', // This should just be the key of the data property
      key: 'price_in_coins',
      render: (price_in_coins) => (
        <div>
          <CopyrightTwoTone  /> {price_in_coins}
        </div>
      ),
    },    
    {
      title: 'Used / Stock',
      key: 'codes',
      render: (text, record) => (
        <span>{record.used_codes || 0} / {record.total_codes || 0}</span>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, item) => (
        <Space size="middle">
          <Button ref={ref2} icon={<EditOutlined />} onClick={() => handleEditItem(item)}>Edit</Button>
          <Popconfirm
  title="Are you sure you want to delete this item?"
  onConfirm={async () => {
    try {
      await handleDeleteItem(item.id); // Explicitly await the async call
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  }}
  okText="Yes"
  cancelText="No"
>
  <Button ref={ref3} icon={<DeleteOutlined />} danger>Delete</Button>
</Popconfirm>

          <Button ref={ref4} icon={<UnorderedListOutlined />} onClick={() => handleShowCodes(item.id)}>View Codes</Button>
        </Space>
      ),
    },    
  ];
  

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const [open, setOpen] = useState(false);
  const steps = [
    {
      title: 'Add new item',
      description: 'Add new item to the shop.',
      target: () => ref1.current,
    },
    {
      title: 'Edit',
      description: 'Edit item price, name or description.',
      target: () => ref2.current,
    },
    {
      title: 'Delete',
      description: 'Delete the item.',
      target: () => ref3.current,
    },
    {
        title: 'List of code',
        description: 'Check, edit, delete codes',
        target: () => ref4.current,
      },
  ];
  
  return (
    <div>
      {contextHolder}

      <Tour open={open} onClose={() => setOpen(false)} disabledInteraction type="default"  steps={steps} />
    
      <Button ref={ref1} type="primary" icon={<PlusOutlined />} onClick={handleAddItem} style={{ marginBottom: 16 }}>
        Add New Item
      </Button>
      <Button style={{float:'right'}} onClick={() => setOpen(true)}>
        Help
      </Button>
      <Table columns={columns} dataSource={shopItems} loading={loading} rowKey="id" />


      {/* Code List Modal */}
      <Modal
  title="Code List"
  open={isCodeModalVisible}
  onCancel={handleCodeModalCancel}
  footer={
    <Space style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <span>Total Codes: {codes.length}</span>
      <span>Used Codes: {codes.filter(code => code.is_used).length}</span>
    </Space>
  }
>
  <List
    bordered
    dataSource={codes}
    renderItem={(code) => (
      <List.Item
        actions={[
          <Space size="small">
            {!code.is_used && (
              <Button
                icon={<EditFilled />}
                onClick={() => setEditingCode(code.code)}
                size='medium'
              />
            )}
            {codes.length > 1 && !code.is_used && (
              <Button
                icon={<DeleteFilled />}
                danger
                onClick={() => deleteCode(code.code)}
                size='medium'
              />
            )}
          </Space>
        ]}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {editingCode === code.code ? (
          <Input
            defaultValue={code.code}
            onPressEnter={(e) => updateCode(e.target.value)}
            onBlur={(e) => updateCode(e.target.value)}
            style={{ maxWidth: '70%' }}
          />
        ) : (
          <Space>
            <span style={{ fontWeight: '500' }}>{code.code}</span>
            <Tag color={code.is_used ? 'red' : 'green'}>
              {code.is_used ? 'Used' : 'Available'}
            </Tag>
          </Space>
        )}
      </List.Item>
    )}
  />
</Modal>



      {/* Add Item Modal */}
      <Modal
  title="Add New Item"
  open={isAddModalVisible}
  onCancel={handleModalCancel}
  footer={[
    <Button key="cancel" onClick={handleModalCancel}>
      Cancel
    </Button>,
    <Popconfirm
      key="save"
      title="Are you sure you want to save this item?"
      onConfirm={async () => {
        try {
          const itemData = await handleAddModalOk();
          // Proceed with API call if validation passes
          await axiosInstance.post('/shop-items', itemData);
          messageApi.success('Item added successfully!');
          fetchShopItems();
          setIsAddModalVisible(false);
          form.resetFields();
          setCodes([]);
          setSingleStock(1);
        } catch (error) {
          console.error('Error saving item:', error);
        }
      }}
      okText="Yes, save it"
      cancelText="No"
    >
      <Button type="primary">Save</Button>
    </Popconfirm>,
  ]}
>
        <Form form={form} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter the item name' }]}>
            <Input placeholder="Item Name" />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea rows={3} placeholder="Item Description" />
          </Form.Item>
          <Form.Item name="linked_release" label="Linked Release">
  <Select placeholder="Select a Release">
    {Array.isArray(releases) && releases.map((release) => (
      <Option key={release.id} value={release.id}>
        {release.name}
      </Option>
    ))}
  </Select>
</Form.Item>

          <Form.Item
  name="item_type"
  label="Type"
  rules={[{ required: true, message: 'Please select an item type' }]}
>
  <Select
    placeholder="Select Type"
    value={itemType}
    onChange={(value) => setitemType(value)} // Update itemType state on change
  >
    <Option value="discount_code">Discount Code</Option>
    <Option value="balance_card">Gift Card</Option>
  </Select>
</Form.Item>

<Row gutter={[20, 20]}>
  <Col md={12}>
    <Form.Item
      name={itemType === 'discount_code' ? 'discount_balance' : 'gift_card_balance'}
      label={itemType === 'discount_code' ? 'Discount Percentage' : 'Gift Card Balance'}
      rules={[{ required: true, message: `Please enter a ${itemType === 'discount_code' ? 'discount percentage' : 'gift card balance'}` }]}
    >
      <Input type="number" placeholder="0" step={0.01} />
    </Form.Item>
  </Col>
  <Col md={12}>
    <Form.Item
      name="price_in_coins"
      label="Price in Coins"
      rules={[{ required: true, message: 'Please enter a price in coins' }]}
    >
      <Input type="number" placeholder="0" />
    </Form.Item>
  </Col>
</Row>


          {/* Code Mode Selection */}
          <Form.Item label="Code Mode">
            <Radio.Group value={codeMode} onChange={(e) => setCodeMode(e.target.value)}>
              <Radio value="single">Single Code</Radio>
              <Radio value="multiple">Multiple Codes</Radio>
            </Radio.Group>
          </Form.Item>

          {/* Conditional Code Input */}
          {codeMode === 'single' ? (
            <>
            <Form.Item name="code" label="Code" rules={[{ required: true, message: 'Please enter a code' }]}>
              <Input placeholder="Enter code" />
            </Form.Item>
            <Form.Item label="Stock">
            <Input 
        value={singleStock} // Bind input to singleStock state
        onChange={(e) => setSingleStock(Number(e.target.value) || 1)} // Update singleStock on change
        placeholder="Enter stock"
      />
             </Form.Item>
                        </>
          ) : (
            <>
              <Form.Item label="Codes">
                <Input.Group compact>
                  <Form.Item name="code" noStyle>
                    <Input placeholder="Enter a code" style={{ width: '70%' }} />
                  </Form.Item>
                  <Button type="primary" onClick={() => addCodeToItem(form.getFieldValue('code'))}>Add Code</Button>
                </Input.Group>
                <div style={{ marginTop: 10 }}>
                  {codes.map((code) => (
                    <Tag closable key={code.code} onClose={() => deleteCode(code.code)}>
                      {code.code}
                    </Tag>
                  ))}
                </div>
              </Form.Item>
              <Form.Item label="Stock">
                <Input disabled value={codes?.length || 0} />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>

      {/* Edit Item Modal */}
      <Modal
        title="Edit Item"
        open={isEditModalVisible}
        onOk={handleEditModalOk}
        onCancel={handleModalCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter the item name' }]}>
            <Input placeholder="Item Name" />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea rows={3} placeholder="Item Description" />
          </Form.Item>
          <Form.Item name="price_in_coins" label="Price in Coins" rules={[{ required: true, message: 'Please enter a price in coins' }]}>
            <Input type="number" placeholder="0" />
          </Form.Item>
          <Form.Item name="stock" label="Stock">
            <Input disabled value={form.getFieldValue('stock')} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ShopManager;
