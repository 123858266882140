import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import axiosInstance from '../../../axiosConfig';

const Callback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const code = query.get('code'); // Get the code from query params

  useEffect(() => {
    const exchangeCodeForToken = async () => {
      if (code) {
        try {
          // Send the code to the backend, which will set the JWT token as a cookie
          const response = await axiosInstance.get(`/auth/callback?code=${code}`, {
            withCredentials: true, // Enable sending cookies
            validateStatus: false, // Prevents axios from throwing for non-200 status codes
          });

          console.log("Full response from server:", response); // Log full response for debugging

          // Check if response is valid JSON and status is OK
          if (response.headers['content-type']?.includes('application/json') && response.status === 200) {
            const { username } = response.data;

            // Redirect to the homepage or developer dashboard
            navigate('/');
          } else {
            console.error('The response was not JSON or the status was not 200:', response.data);
            console.log('Response headers:', response.headers);
            console.log('Response status:', response.status);
          }
        } catch (error) {
          // Log full error information
          if (error.response) {
            console.error('Error response from server:', error.response);
          } else if (error.request) {
            console.error('No response received from server:', error.request);
          } else {
            console.error('Error occurred during request setup:', error.message);
          }
        }
      } else {
        console.error('No code found in query params.');
      }
    };

    exchangeCodeForToken();
  }, [code, navigate]);

  return <div>Logging you in...</div>;
};

export default Callback;
