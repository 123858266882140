import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Avatar, Tag, Typography, Space, Button, Row, Col, Spin, List, Card, Rate, Modal, Popover, theme, Empty, Tabs, Menu, Divider, Popconfirm, message } from 'antd';
import { GithubOutlined, GlobalOutlined, UserOutlined, CheckCircleOutlined, CloseCircleOutlined, LikeOutlined, StarOutlined, UsergroupAddOutlined, ShopOutlined, PlusCircleOutlined, CopyrightCircleTwoTone, SendOutlined, CrownOutlined, CodeOutlined } from '@ant-design/icons';
import { BsChatDotsFill, BsCodeSquare, BsDiscord } from "react-icons/bs";
import moment from 'moment'; // For formatting dates
import { Helmet } from 'react-helmet';
import axiosInstance from '../axiosConfig';
import { BASE_URL, BASE_URL_CB } from '../config';
import { IoCodeWorking, IoSettingsOutline } from 'react-icons/io5';
import BadgeImage from './components/utils/BadgeImage';

const { Title, Text, Paragraph, Link } = Typography;

const Profile = () => {
  const { nickname } = useParams(); // Get the developer's nickname from the URL
  const [developerItems, setDeveloperItems] = useState([]);
  const [developer, setDeveloper] = useState(null);
  const [loading, setLoading] = useState();
  const [categories, setCategories] = useState([]); // Store the categories with packages
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(''); // Store description content for the modal
  const [modalVideo, setModalVideo] = useState(null); // Store video content for the modal
  const [latestProduct, setLatestProduct] = useState(null); // Latest product for the "Latest Release" section
  const [githubRepos, setGithubRepos] = useState([]); // Store GitHub repositories
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { token } = theme.useToken(); // Retrieve Ant Design theme tokens
  const backgroundColor = developer && developer.profile_bg_url
  ? 'transparent' // If there's a background image, keep it transparent
  : token.colorBgContainer; // Use theme-based container background color
  const textColor = token.colorText;
  const [minPrice, setMinPrice] = useState(0); // Minimum price filter
const [maxPrice, setMaxPrice] = useState(0); // Maximum price filter
const [shopView, setShopView] = useState('mainShop'); // Default to 'mainShop'

const findBadgeImage = (badgeName) => {
  // Assuming all badge images are in PNG format
  return `${BASE_URL}/uploads/achievements/${badgeName}.png`;
};


  useEffect(() => {
    fetchDeveloperProfile();
  }, [nickname]);

  useEffect(() => {
    console.log('Categories State:', categories);
    if (categories.length > 0) {
      const firstCategoryWithProducts = categories.find(
        (category) => category.packages && category.packages.length > 0
      );
  
      if (firstCategoryWithProducts) {
        setSelectedCategory(firstCategoryWithProducts.id.toString());
      } else {
        setSelectedCategory(null);
      }
    }
  }, [categories]);
  
  

  const [latestProducts, setLatestProducts] = useState([]); // Store multiple latest products (Tebex + GitHub)
  const defaultGithubImage = 'https://preview.redd.it/g38817mqb1361.png?auto=webp&s=61f191d6a42b645d77cbbc6ce5d6f08686b6e77a'; // Path to the default GitHub image
  
  const fetchDeveloperProfile = async () => {
    try {
      setLoading(true);
  
      // Fetch developer profile
      const response = await axiosInstance.get(`/developer/${nickname}`);
      const developerData = response.data;
  
      console.log('Developer API Response:', developerData); // Log to verify the response structure
  
      if (!developerData || !developerData.id) {
        console.error('Developer ID is missing in the API response.');
        setDeveloper(null);
        setDeveloperItems([]); // Clear developer items
        return;
      }
  
      setDeveloper(developerData); // Set the developer data
  
      // Fetch GitHub repositories
      let githubReposData = [];
      try {
        const githubReposResponse = await axiosInstance.get(`/developer/github-repos/${nickname}`);
        githubReposData = githubReposResponse.data.map((repo) => ({
          name: repo.name || 'Unknown Repo',
          description: repo.description || 'No description available.',
          created_at: repo.created_at || new Date().toISOString(),
          id: repo.id,
          url: repo.html_url || '#',
          image: defaultGithubImage,
          type: 'github',
          stargazers_count: repo.stargazers_count || 0,
          language: repo.language || 'N/A',
        }));
      } catch (githubError) {
        console.error('Error fetching GitHub repositories:', githubError);
      }
      setGithubRepos(githubReposData);
  
      // Fetch Tebex categories with packages
      let fetchedCategories = [];
      try {
        const tebexProductsResponse = await axiosInstance.get(`/developer/tebex-products/${nickname}`);
        fetchedCategories = tebexProductsResponse.data.data || [];
      } catch (tebexError) {
        console.error('Error fetching Tebex products:', tebexError);
      }
      setCategories(fetchedCategories);
  
      // Fetch Developer Shop Items using the developer ID
      let shopItems = [];
      try {
        const shopItemsResponse = await axiosInstance.get(`/shop-items/owner/${developerData.user_id}`); // Use developer.id here
        shopItems = shopItemsResponse.data || [];
        console.log('Developer Shop Items:', shopItems); // Log items for verification
      } catch (shopItemsError) {
        console.error('Error fetching shop items:', shopItemsError);
      }
      setDeveloperItems(shopItems);
  
      // Combine and process all products (Tebex and GitHub)
      const tebexProducts = fetchedCategories.flatMap((category) => category.packages || []);
      const allProducts = [
        ...tebexProducts.map((product) => ({
          ...product,
          type: 'tebex',
          image: product.image || '/default-tebex-image.jpg',
          created_at: product.created_at || new Date().toISOString(),
        })),
        ...githubReposData,
      ];
  
      // Sort products by creation date
      const sortedProducts = allProducts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setLatestProducts(sortedProducts.slice(0, 1)); // Get the latest product
    } catch (error) {
      console.error('Error fetching developer profile:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchDeveloperItems = async (developer) => {
    setLoading(true);
    console.log("Fetching items for Developer ID:", developer.user_id);
    try {
      const response = await axiosInstance.get(`/shop-items/owner/${developer.user_id}`);
      const items = response.data;
      console.log("Developer Shop Items:", items); // Log items for verification
      setDeveloperItems(items);
  
      if (items.length > 0) {
        const prices = items.map((item) => item.price_in_coins);
        setMinPrice(Math.min(...prices));
        setMaxPrice(Math.max(...prices));
      } else {
        setMinPrice(0);
        setMaxPrice(0);
      }
    } catch (error) {
      console.error('Error fetching developer items:', error);
    } finally {
      setLoading(false);
    }
  };

  const applyFilter = (items) => {
    let filteredItems = items;
  
    // Example: Apply price filter
    if (minPrice || maxPrice) {
      filteredItems = filteredItems.filter(
        (item) =>
          (!minPrice || item.price_in_coins >= minPrice) &&
          (!maxPrice || item.price_in_coins <= maxPrice)
      );
    }
  
    return filteredItems;
  };
  

  useEffect(() => {
    if (developer?.user_id) {
      console.log("Developer ID from Params:", developer.user_id);
      setShopView(developer.user_id);
      fetchDeveloperItems(developer);
    }
  }, [developer]);
  
  
  
  
  
  const handlePurchase = async (itemId) => {
    try {
      const response = await axiosInstance.post('/shop-items/purchase', { shop_item_id: itemId });
      message.success(response.data.message);
    } catch (error) {
      console.error('Error during purchase:', error);
  
    }
  };
  

  // Function to extract YouTube or Vimeo video link and return an embedded iframe
  const extractAndEmbedVideo = (description) => {
    const youtubeRegex = /https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/;
    const vimeoRegex = /https:\/\/vimeo\.com\/([0-9]+)/;

    const youtubeMatch = description.match(youtubeRegex);
    const vimeoMatch = description.match(vimeoRegex);

    if (youtubeMatch) {
      return (
        <iframe
          width="100%"
          height="315"
          src={`https://www.youtube.com/embed/${youtubeMatch[1]}`}
          title="YouTube video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    }

    if (vimeoMatch) {
      return (
        <iframe
          src={`https://player.vimeo.com/video/${vimeoMatch[1]}`}
          width="100%"
          height="315"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    }

    return null; // If no video links are found, return null
  };

  // Show modal with description and video
  const showModal = (description) => {
    setModalContent(description);
    setModalVideo(extractAndEmbedVideo(description));
    setModalVisible(true);
  };

  // Hide modal
  const hideModal = () => {
    setModalVisible(false);
    setModalContent('');
    setModalVideo(null); // Clear the video content
  };

  // Redirect the user to the package URL using the Tebex domain and package ID
  const handleBuyNow = (packageId) => {
    if (developer && developer.tebex_url) {
      const packageUrl = `${developer.tebex_url}/package/${packageId}`;
      window.open(packageUrl, '_blank');
    } else {
      window.open(developer.tebex_url, '_blank');
    }
  };

  if (loading) {
    return <Spin size="large" style={{ display: 'block', margin: '100px auto' }} />;
  }

  if (!developer) {
    return <div>Developer not found</div>;
  }

  const StarRating = ({ rating }) => (
    <Rate allowHalf value={rating} disabled style={{ color: '#fadb14' }} />
  );

  const gameColorMap = {
    'FiveM': 'orange',
    'RedM': 'red',
    'Minecraft': 'green',
    'GTA': 'purple',
    // Add more mappings based on your needs
  };
  
  const categoryColorMap = {
    'Script': 'blue',
    'Maps': 'green',
    'Cars': 'red',
    'Models': 'purple',
    'Other': 'grey',
    // Add more mappings based on your needs
  };

  const GitHubTab = ({ githubRepos }) => (
    githubRepos.length > 0 ? (
      <Row gutter={[16, 16]}>
        {githubRepos.map((repo) => (
          <Col md={12} xs={24} key={repo.id}> {/* Two columns on medium screens, full width on small screens */}
            <Card
              style={{ width: '100%' }}
              title={repo.name}
              extra={
                <Button href={repo.url} target="_blank" rel="noopener noreferrer">
                  View on GitHub {repo.created_at}
                </Button>
              }
            >
              <Paragraph>{repo.description || 'No description available'}</Paragraph>
              <Tag color="blue">{repo.language || 'N/A'}</Tag>
              <Tag icon={<StarOutlined />} color="gold">
                {repo.stargazers_count} Stars
              </Tag>
            </Card>
          </Col>
        ))}
      </Row>
    ) : (
      <Empty description="No GitHub repositories available." />
    )
  );
  
  
  const TebexTab = ({ categories, selectedCategory, setSelectedCategory, showModal, handleBuyNow }) => (
    categories.length > 0 ? (
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Menu
            mode="inline"
            selectedKeys={[selectedCategory]}
            onClick={(e) => setSelectedCategory(e.key)}
          >
            {categories
              .filter(category => category.packages && category.packages.length > 0)
              .map(category => (
                <Menu.Item key={category.id}>{category.name}</Menu.Item>
              ))}
          </Menu>
        </Col>
        <Col xs={24} md={18}>
          {selectedCategory ? (
            categories
              .filter(category => category.id.toString() === selectedCategory)
              .map(category => (
                <div key={category.id}>
                  {category.packages.length > 0 ? (
                    <List
                      grid={{ gutter: 16, column: 2 }}
                      dataSource={category.packages}
                      renderItem={(product) => (
                        <List.Item>
                          <Card
                            title={product.name}
                            extra=
                            
                            {product.total_price === 0 ? (
                              <Tag color="green">Free</Tag>
                            ) : (
                              <Tag color="purple">Paid - {product.total_price} USD</Tag>
                            )}
                          >

                            <Row gutter={[20,20]}>
                              <Col md={24}>
                              <img
                              src={product.image || '/default-tebex-image.jpg'} 
                              alt={product.name}
                              style={{ width: '100%', height: '150px', objectFit: 'cover' }}
                            />
                              </Col>
                              <Col md={12}>
                            <Button style={{width:'100%'}} onClick={() => showModal(product.description)}>
                                View Description
                              </Button>
                              </Col>
                              <Col  md={12}>
                              <Button style={{width:'100%'}} type="primary" onClick={() => handleBuyNow(product.id)}>
                                Buy Now
                              </Button>
                              </Col>
                            </Row>

                          </Card>
                        </List.Item>
                      )}
                    />
                  ) : (
                    <Empty description="No products available for this category" />
                  )}
                </div>
              ))
          ) : (
            <Empty description="Select a category to view products" />
          )}
        </Col>
      </Row>
    ) : (
      <Empty description="No Tebex products available." />
    )
  );
  

  const Tabitems = [
    {
      key: '1',
      label: 'Home',
      children:
      <>
      
      {latestProducts.length > 0 && (
        <div>
  <Title level={4}>Latest Releases</Title>
  {latestProducts.length > 0 ? (
    <Row gutter={[20, 20]}>
      {latestProducts.map(product => (
        <Col xs={24} md={8} key={product.id}>
          <Card
            title={product.name}
            extra={
              product.type === 'tebex' ? (
                product.total_price === 0 ? (
                  <Tag color="green">Free</Tag>
                ) : (
                  <Tag color="purple">Paid</Tag>
                )
              ) : (
                <Tag color="blue">GitHub</Tag>
              )
            }
          >
            <img
              src={product.image}
              alt={product.name}
              style={{ width: '100%', height: '180px', objectFit: 'cover', marginBottom: '10px' }}
            />
            <Button
              onClick={() =>
                product.type === 'tebex'
                  ? handleBuyNow(product.id)
                  : window.open(product.url, '_blank')
              }
              type="primary"
              style={{ width: '100%' }}
            >
              {product.type === 'tebex' ? 'Buy Now' : 'View on GitHub'}
            </Button>
          </Card>
        </Col>
      ))}
    </Row>
  ) : (
    <Empty description="No products or repositories available." />
  )}
</div>

)}

      </>,
    },
    {
      key: '2',
      label: 'Tebex Release',
      children: (
        <>
          {categories && categories.length > 0 ? (
            <Row gutter={16}>
              {/* Menu on the left */}
              <Col xs={24} md={4}>
              <Menu
  mode="inline"
  selectedKeys={[selectedCategory]}
  onClick={(e) => setSelectedCategory(e.key)}
  style={{height:'100%'}}
>

                  {categories
                    .filter((category) => category.packages && category.packages.length > 0)
                    .map((category) => (
                      <Menu.Item key={category.id}>
                        {category.name}
                      </Menu.Item>
                    ))}
                </Menu>
              </Col>
    
              {/* Content on the right */}
              <Col xs={24} md={18}>
              {selectedCategory ? (
  categories
    .filter((category) => category.id.toString() === selectedCategory)
    .map((category) => (
      <div key={category.id}>
        {category.description && (
          <div dangerouslySetInnerHTML={{ __html: category.description }} />
        )}

        {category.packages.length > 0 ? (
          <List
            grid={{ gutter: 16, column: 3 }}
            dataSource={category.packages}
            renderItem={(product) => (
              <List.Item>
              <Card
                title={product.name}
                extra={product.total_price === 0 ? (
                  <Tag color="green">Free</Tag>
                ) : (
                  <Tag color="purple">Paid</Tag>
                )}
              >
                <img
                  src={product.image}
                  alt={product.name}
                  style={{ width: '100%', height: '180px', objectFit: 'cover' }}
                />
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px' }}>
                  {/* View Description Button */}
                  <Button
                    onClick={() => showModal(product.description)}
                    style={{ width: '100%' }}
                  >
                    View Description
                  </Button>
                  {/* Buy Now Button */}
                  <Button
                    type="primary"
                    onClick={() => handleBuyNow(product.id)}
                    style={{ width: '100%' }}
                  >
                    Buy Now
                  </Button>
                </div>
              </Card>
            </List.Item>
            )}
          />
        ) : (
          <Empty
            description="No products available for this category"
            style={{ marginTop: '20px' }}
          />
        )}
      </div>
    ))
) : (
  <Empty description="Select a category to view products" style={{ marginTop: '20px' }} />
)}

              </Col>
            </Row>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '70vh',
              }}
            >
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{
                  height: 50,
                }}
                description={
                  <Typography.Text style={{ color: '#888', fontSize: '12px' }}>
                    This developer still has to release something,
                    <br />
                    or connect their Tebex or GitHub account.
                  </Typography.Text>
                }
              >
                <Button href="/release" size="small">
                  Go to release page
                </Button>
              </Empty>
            </div>
          )}
        </>
      ),
    },
    
    {
      key: '3',
      label: 'GitHub Repositories',
      children:
      <>
      {githubRepos.length > 0 && (
  <div style={{ marginTop: '20px' }}>
    <List
      dataSource={githubRepos}
      renderItem={repo => (
        <List.Item key={repo.id}>
          <Card
            title={repo.name}
            extra={<a href={repo.html_url} target="_blank" rel="noopener noreferrer">View on GitHub</a>}
          >
            <Paragraph>{repo.description || 'No description available'}</Paragraph>
            <Tag color="blue">{repo.language || 'N/A'}</Tag>
            <Tag icon={<StarOutlined />} color="gold">{repo.stargazers_count} Stars</Tag>
          </Card>
        </List.Item>
      )}
    />
  </div>
)}
      </>
    },
    {
      key: '4',
      label: 'Coin Shop',
      children: 'Content of Tab Pane 3',
    },
  ];
  
  const renderShopItems = (items) => {
    if (!items || items.length === 0) {
      return <Empty description="No items available in this shop" />;
    }
  
    return (
      <Row gutter={[16, 16]}>
        {items.map((item) => (
          <Col xs={24} md={12} lg={8} key={item.id}>
<Card
  style={{
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }}
  bodyStyle={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.8)), url(${item.linked_releases_image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    color: 'white',
    height: '100%',
  }}
  hoverable
  title={<Text>{item.name}</Text>}
  actions={[
    <div style={{ padding: '0 10px' }}>
      <Popconfirm
        title={`Are you sure you want to buy ${item.name} for ${item.price_in_coins} coins?`}
        onConfirm={() => handlePurchase(item.id)}
        okText="Yes"
        cancelText="No"
      >
        <Button size="large" style={{ width: '100%' }}>
          Buy for <strong>{item.price_in_coins}</strong>
          <CopyrightCircleTwoTone />
        </Button>
      </Popconfirm>
    </div>,
  ]}
>
  <Row>
    <Col md={24}>
      {item.linked_release ? (
        <>
          <Text>Linked package: </Text>
          <Link href={`/release/${item.linked_release}`}>{item.linked_releases_name}</Link>
        </>
      ) : (
        <Text>Available on all packages</Text>
      )}
    </Col>

    <Divider />

    <Col md={24}>
      <Text type="secondary" style={{ fontSize: '12px' }}>
        {item.description}
      </Text>
    </Col>

    <Divider />

    <Col md={24}>
      {item.item_type === 'discount_code' ? (
        <Tag style={{ width: 'fit-content' }} color="green">
          {item.discount_balance}% Discount
        </Tag>
      ) : (
        <Tag style={{ width: 'fit-content' }} color="blue">
          {item.gift_card_balance}€ Gift Card
        </Tag>
      )}
      <Tag style={{ width: 'fit-content' }}>{item.stock} in Stock</Tag>
      {item.linked_releases_price > 0 && (
        <Tag style={{ width: 'fit-content' }}>Price: € {item.linked_releases_price}</Tag>
      )}
    </Col>
  </Row>
</Card>

          </Col>
        ))}
      </Row>
    );
  };
  
  
  
  
  return (
    <div style={{ width: '100%'}}>

<Helmet>
        <title>{developer.nickname} profile - TopModders</title> {/* Dynamic title */}
        <meta name="description" content={developer.motto || `${developer.nickname}'s profile on TopModders`} />
        <meta name="keywords" content={`${developer.nickname}, ${developer.games}, ${developer.category}, ${developer.tags}, Developer, mods, scripts, top modders`} />

        {/* Open Graph tags for social sharing */}
        <meta property="og:title" content={`${developer.nickname} - TopModders`} />
        <meta property="og:description" content={developer.motto || `${developer.nickname}'s profile on TopModders`} />
        <meta property="og:image" content={developer.user_img_url ? `https://topmodders.com${developer.user_img_url}` : '/default-image.jpg'} />
        <meta property="og:type" content="profile" />
        <meta property="og:url" content={`https://topmodders.com/profile/${developer.nickname}`} />

        {/* Twitter card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${developer.nickname} - TopModders`} />
        <meta name="twitter:description" content={developer.motto || `${developer.nickname}'s profile on TopModders`} />
        <meta name="twitter:image" content={developer.user_img_url ? `https://topmodders.com${developer.user_img_url}` : '/default-image.jpg'} />
      </Helmet>

      {/* Profile Header with Background */}
      {developer && (
  <div
  style={{
    position: 'relative',
    backgroundImage: developer.profile_bg_url
      ? `url(${BASE_URL}${developer.profile_bg_url})`
      : 'none', // No background image if not available
    backgroundColor: developer.profile_bg_url
      ? 'transparent' // Keep it transparent if an image is present
      : token.colorBgContainer, // Use theme-based background color
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '40px 15%',
    color: token.colorText, // Use theme-based text color
  }}
>

        <Row>
          <Col xs={24} md={6}>
            <Avatar
              src={developer.user_img_url ? `${BASE_URL}${developer.user_img_url}` : null}
              icon={!developer.user_img_url && <UserOutlined />}
              size={180}
              style={{ border: '3px solid #ffffffa8', boxShadow:'0px 0px 5px 0px black' }}
              shape='square'
            />
          </Col>
          <Col xs={24} md={16} style={{background:'rgba(0,0,0,0.5)', borderRadius:'8px', padding:'10px', }}>
            <div style={{display:'flex', alignItems:'center', columnGap:'15px'}}>
            <Title level={2} style={{ color: 'white',margin:'0px', marginBottom: '5px', display:'flex', justifyContent:'center', alignItems:'center'}}>{developer.nickname}</Title>
            <StarRating style={{width: '100%'}} rating={developer.overall_rating || 0} />
            </div>

            <Space size="middle">
              {developer.verified ? (
                <Tag icon={<CheckCircleOutlined />} color="success">Verified</Tag>
              ) : (
                <Tag icon={<CloseCircleOutlined />} color="error">Not Verified</Tag>
              )}
              <Tag icon={<LikeOutlined />} color="default">
                Total votes: {developer.total_vote_ever}
              </Tag>
              <Tag icon={<UsergroupAddOutlined />} color="default">
                Followers: {developer.followers} 
              </Tag>
              <Button size='small'><PlusCircleOutlined></PlusCircleOutlined>Follow</Button>
              <Button size='small'><BsChatDotsFill></BsChatDotsFill>Message</Button>
            </Space>
            <Paragraph style={{ color: 'white', marginTop: '15px' }}>
              {developer.motto}
            </Paragraph>
          </Col>
        </Row>
      </div>
      )}


<div style={{paddingLeft:'15%', paddingRight:'15%', height:'100%'}}>

<Tabs defaultActiveKey="1">
<Tabs.TabPane key="1" tab="Home">
  {latestProducts.length > 0 ? (
    <Row gutter={[20, 20]}>
      <Col xs={24} md={16}>
        <Card title={<>About {developer.nickname}</>} extra={<>
          {developer.verified ? (
                <Tag icon={<CheckCircleOutlined />} color="success">Verified</Tag>
              ) : (
                <Tag icon={<CloseCircleOutlined />} color="error">Not Verified</Tag>
              )}
              {developer.is_premium ? <Tag color='gold'><CrownOutlined/> Premium</Tag> : ''}
        {developer.is_staff ? <Tag color='red'><CodeOutlined/> TopModders Staff</Tag> : ''}</>}>
        <List>
        <List.Item>
          <Text>Developer</Text>
       <Space wrap>
          <Space >
      <Tag color={gameColorMap[developer.games] || 'default'} key={developer.games}>
        {developer.games}
      </Tag>
      <Tag color={categoryColorMap[developer.category] || 'default'} key={developer.category}>
        {developer.category}
      </Tag>
          </Space>
          {/* Tags Section */}
          {developer.tags && (
            <Space >
              {developer.tags.split(',').map((tag) => (
                <Tag color="default" key={tag}>{tag}</Tag>
              ))}
            </Space>
          )}
        </Space>
       </List.Item>
       <List.Item>
       <Text>Discord</Text>
       
       {developer.discord_url && (
        <Row>
          <Col>
        <Tag>{developer.discord_total_members} Members</Tag>
        </Col>
        <Col> 
        <Tag>{developer.discord_total_customer} customers</Tag>
        </Col>
        <Col>
              <Button href={developer.discord_url} target="_blank" icon={<BsDiscord />} >
              Join Discord
              </Button>
              </Col>
              </Row>
            )}
         
       </List.Item>
       <List.Item>
        <Text>Tebex</Text>
       <Button href={developer.tebex_url} target="_blank" icon={<GlobalOutlined />}>
              Tebex Store
            </Button>
       </List.Item>
<List.Item>
  
  <Text>GitHub</Text>
{developer.github_url && (
              <Button href={developer.github_url} target="_blank" icon={<GithubOutlined />}>
                GitHub
              </Button>
            )}
</List.Item>
        <List.Item>
       <Text>Team</Text>
       <Space>
  <Tag color={developer.is_team ? 'cyan' : 'pink'}>
    {developer.is_team ? 'Developers Team' : 'Private Developer'}
  </Tag>
  {developer.is_team ? (
  <Tag color="cyan">
    {developer.team_members && developer.team_members.trim()
      ? `Team Members: ${developer.team_members.split(',').filter((member) => member.trim()).length}`
      : 'No Team Members'}
  </Tag>
) : null}

</Space>

       </List.Item>
       {developer.is_team && (
  <List.Item>
    <Text>Members</Text>
    {developer.team_members && (
      <Space wrap>
        {developer.team_members.split(',').map((member) => {
          const trimmedMember = member.trim(); // Remove any extra whitespace
          return (
            <Tag key={trimmedMember}>
              <a href={`/profile/${trimmedMember}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                {trimmedMember}
              </a>
            </Tag>
          );
        })}
      </Space>
    )}
  </List.Item>
)}


       <List.Item>
       <Text>Support e-mail</Text>
       <Tag>{developer.public_email}</Tag>
       </List.Item>
      <List.Item>
       <Text>Avaialable for hire</Text>
       <Tag color={developer.is_available_for_hire ? 'green' : 'red'}>
          {developer.is_available_for_hire ? 'Available for hire' : 'Not available for hire'}
        </Tag>
       </List.Item>

       <List.Item>
  {developer.badge ? (
    <Space>
      {developer.badge.split(',').map((badgeName) => {
        const trimmedBadgeName = badgeName.trim();
        return (
          <BadgeImage key={trimmedBadgeName} badgeName={trimmedBadgeName} />
        );
      })}
    </Space>
  ) : (
    <Text>No Achievements</Text>
  )}
</List.Item>
       </List>
       </Card>
      </Col>
      {latestProducts.map((product) => (
        <Col xs={24} md={8} key={product.id}>
          <Card
            title={product.name || 'Unnamed Product'}
            extra={
              product.type === 'tebex' ? (
                product.total_price === 0 ? (
                  <Tag color="green">Free</Tag>
                ) : (
                  <Tag color="purple">Paid - ${product.total_price || 'Unknown'}</Tag>
                )
              ) : (
                <Tag color="blue">GitHub</Tag>
              )
            }
          >
            <img
              src={product.image}
              alt={product.name || 'Product Image'}
              style={{ width: '100%', height: '180px', objectFit: 'cover', marginBottom: '10px' }}
            />
            <Button
              onClick={() =>
                product.type === 'tebex'
                  ? handleBuyNow(product.id)
                  : window.open(product.url, '_blank')
              }
              type="primary"
              style={{ width: '100%' }}
            >
              {product.type === 'tebex' ? 'Buy Now' : 'View on GitHub'}
            </Button>
          </Card>
        </Col>
      ))}
    </Row>
  ) : (
    <Empty description="No products or repositories available." />
  )}
</Tabs.TabPane>

  <Tabs.TabPane key="2" tab="Tebex Release">
    <TebexTab
      categories={categories}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      showModal={showModal}
      handleBuyNow={handleBuyNow}
    />
  </Tabs.TabPane>
  <Tabs.TabPane key="3" tab="GitHub Repositories">
    <Row>
    <Col md={24}>
    <GitHubTab githubRepos={githubRepos} />
    </Col>
    </Row>
  </Tabs.TabPane>
  <Tabs.TabPane key="4" tab="Coins Shop">
          {renderShopItems(developerItems)}
  </Tabs.TabPane>
</Tabs>


</div>
      {/* Modal for full description and video */}
      <Modal
        title="Product Description"
        open={modalVisible}
        onCancel={hideModal}
        footer={null}
        width={800}
      >
        {modalVideo && (
          <div style={{ marginBottom: '20px' }}>
            {modalVideo}
          </div>
        )}
        <div dangerouslySetInnerHTML={{ __html: modalContent }} />
      </Modal>

    </div>
  );
};

export default Profile;
