import React, { useState } from 'react';
import { Form, Input, Button, Steps, Select, message, Card, Typography, Space } from 'antd';
import axios from 'axios';
import axiosInstance from '../../../axiosConfig';

const { Step } = Steps;
const { Title } = Typography;
const { Option } = Select;

const DeveloperCreationForm = ({ onFinish }) => {
  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState({});
  const [form] = Form.useForm();

  const [nicknameValidating, setNicknameValidating] = useState(false);
  const [tebexUrlValidating, setTebexUrlValidating] = useState(false);
  const [nicknameError, setNicknameError] = useState(null);
  const [tebexUrlError, setTebexUrlError] = useState(null);
  const [nicknameSuccess, setNicknameSuccess] = useState(null);
  const [tebexUrlSuccess, setTebexUrlSuccess] = useState(null);

  const next = () => {
    form
      .validateFields()
      .then((values) => {
        setFormData((prev) => ({
          ...prev,
          ...values,
        }));
        setCurrent(current + 1);
      })
      .catch((errorInfo) => {
        console.error('Validation failed:', errorInfo);
      });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: 'Basic Info',
      content: (
        <>
          <Form.Item
            name="nickname"
            label={<Title level={5}>Nickname</Title>}
            validateStatus={nicknameValidating ? 'validating' : nicknameError ? 'error' : nicknameSuccess ? 'success' : ''}
            help={nicknameError || nicknameSuccess || ''}
            rules={[{ required: true, message: 'Please input your nickname!' }]}
          >
            <Input
              placeholder="Enter your nickname"
              size="large"
              onChange={(e) => {
                debouncedCheckUnique('nickname', e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            name="games"
            label={<Title level={5}>Games</Title>}
            rules={[{ required: true, message: 'Please select at least one game!' }]}
          >
            <Select mode="multiple" placeholder="Select games" size="large" style={{ width: '100%' }}>
              <Option value="FiveM">FiveM</Option>
              <Option value="RedM">RedM</Option>
              <Option value="Minecraft">Minecraft</Option>
            </Select>
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Category & Tebex Link',
      content: (
        <>
          <Form.Item
            name="category"
            label={<Title level={5}>Category</Title>}
            rules={[{ required: true, message: 'Please select a category!' }]}
          >
            <Select mode="multiple" placeholder="Select category" size="large" style={{ width: '100%' }}>
              <Option value="Script">Script</Option>
              <Option value="Maps">Maps</Option>
              <Option value="Cars">Cars</Option>
              <Option value="Models">Models</Option>
              <Option value="Other">Other</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="tebex_url"
            label={<Title level={5}>Tebex Link</Title>}
            validateStatus={tebexUrlValidating ? 'validating' : tebexUrlError ? 'error' : tebexUrlSuccess ? 'success' : ''}
            help={tebexUrlError || tebexUrlSuccess || 'e.g., https://yourstore.tebex.io or https://yourstore.tebex.io/subfolder'}
            rules={[{ required: true, message: 'Please provide a valid Tebex link!' }]}
          >
            <Input
              placeholder="Enter your Tebex link"
              size="large"
              onChange={(e) => {
                const value = e.target.value;
                const regex = /^https?:\/\/[a-zA-Z0-9\-]+\.tebex\.io(\/[a-zA-Z0-9\-\/]*)?$/;
                if (regex.test(value)) {
                  debouncedCheckUnique('tebex_url', value);
                } else {
                  setTebexUrlError('Tebex link must be in the format https://subdomain.tebex.io or https://subdomain.tebex.io/subfolder');
                  setTebexUrlSuccess(null);
                }
              }}
            />
          </Form.Item>
        </>
      ),
    },
  ];

  const handleFinish = async () => {
    try {
      const values = await form.validateFields();
      const finalFormData = { ...formData, ...values };

      await axiosInstance.post('/developer', finalFormData, {
        withCredentials: true,
      });
      message.success('Developer profile created successfully!');
      onFinish();
    } catch (error) {
      console.error('Failed to create developer profile:', error);
      message.error('Failed to create profile.');
    }
  };

  function debounce(func, delay) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const checkUniqueData = async (field, value) => {
    try {
      const response = await axiosInstance.post('/check-unique', {
        field,
        value,
      }, {
        withCredentials: true,
      });

      if (response.data.exists) {
        if (field === 'nickname') {
          setNicknameError('Nickname is already in use.');
          setNicknameSuccess(null);
        } else if (field === 'tebex_url') {
          setTebexUrlError('Tebex link is already in use.');
          setTebexUrlSuccess(null);
        }
      } else {
        if (field === 'nickname') {
          setNicknameSuccess('Nickname can be used.');
          setNicknameError(null);
        } else if (field === 'tebex_url') {
          setTebexUrlSuccess('Tebex link can be used.');
          setTebexUrlError(null);
        }
      }
    } catch (error) {
      console.error('Error checking unique data:', error);
    }
  };

  const debouncedCheckUnique = debounce(async (field, value) => {
    setNicknameError(null);
    setTebexUrlError(null);
    setNicknameSuccess(null);
    setTebexUrlSuccess(null);
    if (field === 'nickname') {
      setNicknameValidating(true);
      await checkUniqueData(field, value);
      setNicknameValidating(false);
    } else if (field === 'tebex_url') {
      setTebexUrlValidating(true);
      await checkUniqueData(field, value);
      setTebexUrlValidating(false);
    }
  }, 2000);

  return (
    <Card style={{ maxWidth: 800, margin: 'auto', padding: '20px' }}>
      <Title level={2} style={{ textAlign: 'center', marginBottom: '20px' }}>Create Developer Profile</Title>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Steps current={current}>
          {steps.map((step) => (
            <Step key={step.title} title={step.title} />
          ))}
        </Steps>
        <div className="steps-content" style={{ margin: '20px 0' }}>{steps[current].content}</div>
        <div className="steps-action" style={{ textAlign: 'center' }}>
          {current < steps.length - 1 && (
            <Button type="primary" onClick={next} style={{ marginRight: '8px' }}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={prev}>
              Previous
            </Button>
          )}
        </div>
      </Form>
    </Card>
  );
};

export default DeveloperCreationForm;
