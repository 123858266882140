import React from 'react';
import Sidebar from './Menu';
import { Outlet } from 'react-router-dom';
import {Button, Row, Col, Typography } from 'antd';
const { Text, Link } = Typography;

const Layout = () => {
  return (
    <div className="layout">
      <Sidebar />
      <div className="content"  style={{minHeight:'100vh'}}>
        <Outlet />
      </div>
      <div className='footer'>
        <Row style={{flexDirection:'column', padding:'20px'}}>

            <Row gutter={[20,20]} style={{justifyContent:'center'}}>  
            <Col>
             <a href=''>Terms of service </a>
            </Col>
            
            <Col> 
            <a href=''>Policy and privacy </a>
            </Col>

            <Col > 
            <a href=''>Cookie preference </a>
            </Col>
            <Col > 
            <a href=''>Contact us </a>
            </Col>
            <Col md={24}>
          <Text>topmodders.com © 2024</Text>
          </Col>
          </Row>

        </Row>
      </div>
    </div>
  );
};

export default Layout;
