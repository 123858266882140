import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Typography, List, Badge, Divider, Spin, message } from 'antd';
import { UserOutlined, CodeOutlined, UserSwitchOutlined } from '@ant-design/icons';
import DeveloperCreationForm from '../DeveloperCreationForm';
import UserControlPanel from './UserControlPanel';
import ControlPanel from '../controlpanel';
import axiosInstance from '../../../../axiosConfig';


const { Title, Paragraph } = Typography;

const RoleSelectionManager = () => {
  const [user, setUser] = useState(null);
  const [developerExists, setDeveloperExists] = useState(false);
  const [loading, setLoading] = useState(true);
  const [roleSelectionVisible, setRoleSelectionVisible] = useState(false);
  const [modalContentKey, setModalContentKey] = useState(null); // Key for modal content

  useEffect(() => {
    const fetchUserAndDeveloperData = async () => {
      try {
        const userResponse = await axiosInstance.get('/users/me', { withCredentials: true });
        const userData = userResponse.data;

        setUser(userData);

        if (userData.role === 'developer') {
          const developerResponse = await axiosInstance.get('/developer', { withCredentials: true });
          setDeveloperExists(!!developerResponse.data);
        }

        setRoleSelectionVisible(!userData.role);
      } catch (error) {
        console.error('Error fetching user/developer data:', error);
        message.error('Failed to fetch user data. Please try again.');
        setRoleSelectionVisible(true);
      } finally {
        setLoading(false);
      }
    };

    fetchUserAndDeveloperData();
  }, []);

  const handleRoleSelection = async (role) => {
    try {
      await axiosInstance.put('/users/role', { role }, { withCredentials: true });
      setUser((prev) => ({ ...prev, role }));
      if (role === 'developer') {
        setRoleSelectionVisible(false);
        setDeveloperExists(false);
      } else {
        setRoleSelectionVisible(false);
        message.success('You are now set as a normal user.');
      }
    } catch (error) {
      console.error('Error updating role:', error);
      message.error('Failed to update role.');
    }
  };

  const openModal = (key) => {
    setModalContentKey(key);
  };

  const closeModal = () => {
    setModalContentKey(null);
  };

  const listItems = [
    { text: 'Vote for your favorite developers', key: 'vote' },
    { text: 'Earn coins and claim rewards', key: 'earnCoins' },
    { text: 'Stay updated with top releases', key: 'updates' },
    { text: 'Share feedback with the community', key: 'community' },
    { text: 'Join events and win giveaways', key: 'events' },
    { text: 'Find developers for your projects', key: 'developers' },
  ];
  

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (roleSelectionVisible) {
    return (
      <Row gutter={[0,50]}>
        <Col style={{background:"url('https://topmodders.com/uploads/default/ranking_img.jpg') no-repeat", backgroundSize:'cover', padding:'50px'}} md={24}>
        <Row style={{ textAlign: 'center', marginBottom: '20px' }}>
  <Col md={24}>
    <Title level={2}><UserSwitchOutlined></UserSwitchOutlined> Choose your path!</Title>
    <Card style={{marginRight:'30%', marginLeft:'30%'}}>
      Choose how you'd like to use the platform: <b>Normal User</b> or <b>Developer</b>.
      <br></br>
  <span style={{ color: 'grey'}}>
    Not sure? Start as a Normal Use, you can switch to Developer anytime.
  </span>
    </Card>
  </Col>
</Row>
        </Col>
        <Col md={24}>
        <Row gutter={[50, 20]} >
        <Col xs={0} md={4}>
        </Col>
          <Col xs={24} md={8}>
            <Card
              hoverable
              onClick={() => handleRoleSelection('user')}
              title={
                <>
                  <UserOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
                  Normal User
                </>
              }
              extra={<Button>More information</Button>}
              actions={[
                <Button style={{width:'95%'}} size="large" onClick={() => handleRoleSelection('user')}>
                  Normal User
                </Button>,
              ]}
            >
              <List
                size="small"
                dataSource={listItems}
                renderItem={(item) => (
                  <List.Item>
                    <span>{item.text}</span>
                  </List.Item>
                )}
              />
            </Card>
          </Col>

          <Col xs={24} md={8}>
            <Card
              hoverable
              onClick={() => handleRoleSelection('developer')}
              title={
                <>
                  <CodeOutlined style={{ fontSize: '24px', marginRight: '10px', color: '#fa8c16' }} />
                  Developer
                </>
              }
              extra={<Button>More information</Button>}
              actions={[
                <Button size='large' style={{width:'95%'}} type="primary" onClick={() => handleRoleSelection('developer')}>
                  Developer
                </Button>,
              ]}
            >
        <Col xs={0} md={4}>
        </Col>
              <List
                size="small"
                dataSource={[
                  'Create a public developer profile',
                  'Showcase your products and work',
                  'Engage with clients and users',
                  'Become a verified developer',
                  'Open your own rewards shop',
                  'Host events and giveaways'
                ]}
                renderItem={(item) => <List.Item> {item}</List.Item>}
              />
            </Card>
          </Col>
        </Row>
        </Col>
      </Row>
    );
  }

  return (
    <>
      {developerExists ? (
        <ControlPanel />
      ) : user?.role === 'developer' ? (
        <DeveloperCreationForm onFinish={() => setDeveloperExists(true)} />
      ) : (
        <UserControlPanel onSwitchToDeveloper={() => handleRoleSelection('developer')} />
      )}
    </>
  );
};

export default RoleSelectionManager;
