// src/components/controlpanel/Description.js
import React from 'react';
import { Descriptions, Tag, Button, Badge, Tooltip } from 'antd';

const badgeImages = {
    "Top 1": "./uploads/achievements/win.gif",
    "Top 10": "https://i.ibb.co/nc2Q15z/award-9663888.png",
    "ROTM": "https://i.ibb.co/8B8tD1t/cinema-9663884.png",
    "Verified": "https://cdn-icons-png.flaticon.com/512/5972/5972778.png",
    // Add more badges as needed
  };
  

const Description = ({ developerData, setActiveTabKey, badgeImages }) => {
    const descriptionItems = [
        {
          key: '1',
          label: 'Nickname',
          children: <span>{developerData.nickname}</span>,
        },
        {
          key: '1',
          label: 'Games',
          children: <span>{developerData.games}</span>,
        },
        {
          key: '1',
          label: 'Category',
          children: <span>{developerData.category}</span>,
        },
        {
          key: '3',
          label: 'Monthly votes',
          children: <span>{developerData.total_votes}</span>,
        },
        {
          key: '4',
          label: 'Total votes',
          children: <span>{developerData.total_vote_ever}</span>,
        },
        {
          key: '5',
          label: 'TopModders Rating',
          children: <span>{developerData.overall_rating}</span>,
        },
        {
          key: '2',
          label: 'Verified status',
          children: (
            <span>
              {developerData.verified ? (
                <Tag color="green">Active</Tag>
              ) : (
                <span>
                  <Tag color="red">Inactive</Tag>{' '}
                  <Button size="small" onClick={() => setActiveTabKey('6')}>Get verified</Button>{' '}
                </span>
              )}
            </span>
          ),
        },
        {
          key: '7',
          label: 'Tebex product',
          children: (
            <span>
              {developerData.tebex_token ? (
                <Tag color="green">Active</Tag>
              ) : (
                <span>
                  <Tag color="red">Inactive </Tag>{' '}
                  <Button size="small" onClick={() => setActiveTabKey('3')}>Link token</Button>{' '}
                </span>
              )}
            </span>
          ),
        },
        {
          key: '3',
          label: 'Premium',
          children: (
            <span>
              {developerData.is_premium ? (
                <Tag color="green">Active</Tag>
              ) : (
                <span>
                  <Tag color="red">Inactive </Tag>{' '}
                  <Button size="small" onClick={() => setActiveTabKey('7')}>Go premium</Button>{' '}
                </span>
              )}
            </span>
          ),
        },
        {
          key: '6',
          label: 'Discord bot status',
          children: (
            <span>
              {' '}
              <Tag color="green">
                {' '}
                <Badge status="processing" color="lime" text="Online" />{' '}
              </Tag>{' '}
              <a href="https://discord.com/oauth2/authorize?client_id=1287858555663290489&permissions=1689504842935360&integration_type=0&scope=applications.commands+bot">
              <Button variant="outlined" size="small" color="warning">
                Invite bot
              </Button>
              </a>
            </span>
          ),
        },
        {
          key: '8',
          label: 'Coins',
          children: <span>{developerData.coins}</span>
        },
        {
          key: '9',
          label: 'Followers',
          children: <span>{developerData.followers}</span>,
          span: 1,
        },
        {
          key: '10',
          label: 'Achievements',
          children: (
            <span>
            {developerData.badge ? (
              developerData.badge.split(',').map((badge) => (
                <Tooltip key={badge.trim()} placement="bottom" title={badge.trim()}>
                  <img
                    src={badgeImages[badge.trim()] || '/images/badges/default.png'}
                    alt={`${badge.trim()} Badge`}
                    style={{ width: 48, height: 48, marginRight: 8 }}
                  />
                </Tooltip>
              ))
            ) : (
              'No Badges'
            )}
          </span>
          ),
        },
      ];
      

  return (
    <Descriptions
      span="xs"
      size="small"
      bordered
      items={descriptionItems}
      style={{width:'100vw', paddingLeft:'15vw', paddingRight:'15vw'}}
    />
  );
};

export default Description;
