import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Avatar, Dropdown, Typography, Space, Tooltip, Button, Menu, Badge, message, Modal, List, Spin, Tag, Popconfirm, Empty, Card, Tabs } from 'antd';
import { UserOutlined, ControlOutlined, LogoutOutlined, DownOutlined, SettingOutlined, GiftFilled, 
  CrownOutlined, MailOutlined, MessageOutlined, BellOutlined, CopyOutlined, GiftOutlined, CopyrightCircleTwoTone,CloseCircleOutlined,CheckCircleOutlined } from '@ant-design/icons';
import { BsDiscord } from "react-icons/bs";
import axiosInstance from '../../../axiosConfig';
import { BASE_URL, BASE_URL_CB } from '../../../config';
import Marketplace from '../../Marketplace';
import dayjs from 'dayjs';


const { Text } = Typography;

const User = ({ collapsed }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [nickname, setNickname] = useState(null);
  const [userImgUrl, setUserImgUrl] = useState(null); 
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [balance, setBalance] = useState(null); 
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [availableCodes, setAvailableCodes] = useState(0); 
  const [AvailableTasks, setAvailableTasks] = useState(0); 
  const [isDailyTaskModalVisible, setIsDailyTaskModalVisible] = useState(false);
  const [dailyTasks, setDailyTasks] = useState([]);
  const [taskLoading, setTaskLoading] = useState(false);
  const [actionableTasks, setActionableTasks] = useState(0);

  
  useEffect(() => {
    const fetchUserAndData = async () => {
      try {
        const userResponse = await axiosInstance.get('/auth/user');
  
        if (userResponse.status === 200) {
          setUser(userResponse.data);
          setIsLoggedIn(true);
  
          await Promise.all([
            fetchUserCoinBalance(),
            fetchDailyTasks(),
            fetchRewards(),
            fetchNickname(userResponse.data.username),
          ]);
  
          const notificationResponse = await axiosInstance.get('/notifications');
          setNotifications(notificationResponse.data);
        } else {
          setIsLoggedIn(false);
          setUser(null);
        }
      } catch (error) {
        console.error('Error fetching user or dependent data:', error);
        setIsLoggedIn(false);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
  
    fetchUserAndData();
  }, []);
  

  const fetchUserData = async () => {
    try {
      const userResponse = await axiosInstance.get('/auth/user'); 
      if (userResponse.status === 200) {
        setIsLoggedIn(true); 
        fetchUserCoinBalance(); 
      } else {
        setIsLoggedIn(false); 
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setIsLoggedIn(false); 
    }
  };

  const fetchDailyTasks = async () => {
    setTaskLoading(true);
    try {
        const response = await axiosInstance.get('/daily-tasks/user');
        const tasks = response.data.tasks || [];
        
        const actionableCount = tasks.filter(task => task.is_completed && !task.is_claimed).length;
        setActionableTasks(actionableCount);
        
        setDailyTasks(tasks);
    } catch (error) {
        console.error('Error fetching daily tasks:', error);
        message.error('Failed to fetch daily tasks.');
    } finally {
        setTaskLoading(false);
    }
};


const claimTaskReward = async (taskId) => {
  try {
      const response = await axiosInstance.post(`/daily-tasks/claim/${taskId}`);
      message.success(response.data.message);
      
      // Update the specific task in the UI
      setDailyTasks(prevTasks =>
          prevTasks.map(task =>
              task.id === taskId ? { ...task, is_claimed: true } : task
          )
      );
      fetchUserCoinBalance(); // Refresh user balance after claiming the reward
  } catch (error) {
      const errorMsg = error.response?.data?.error || 'Failed to claim reward.';
      message.error(errorMsg);
  }
};



const handleClaimReward = async (taskId) => {
  try {
      const response = await axiosInstance.post('/daily-tasks/claim', { taskId });
      message.success(response.data.message);
      fetchDailyTasks(); // Refresh task list after claiming
  } catch (error) {
      message.error(error.response?.data?.error || 'Failed to claim reward.');
  }
};

// Function to format the timer
const formatTimeRemaining = (timestamp) => {
  const now = new Date();
  const cooldown = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  const timeRemaining = cooldown - (now - new Date(timestamp));
  if (timeRemaining <= 0) return "Available";

  const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  return `${hours}h ${minutes}m remaining`;
};
const handleOpenDailyTaskModal = async () => {
  try {
      await axiosInstance.post('/daily-tasks/check'); // Trigger backend logic
      await fetchDailyTasks(); // Fetch updated tasks
      setIsDailyTaskModalVisible(true);
  } catch (error) {
      console.error('Error opening daily task modal:', error);
      message.error('Failed to load daily tasks.');
  }
};

  
  const handleCloseDailyTaskModal = () => {
    setIsDailyTaskModalVisible(false);
  };
  

  const fetchUserCoinBalance = async () => {
    try {
      const response = await axiosInstance.get('/shop-items/user/coins');
      setBalance(response.data.coins); 
    } catch (error) {
      console.error('Error fetching user coin balance:', error);
      message.error('Failed to fetch coin balance.');
    }
  };



  const handleCloseRewardsModal = () => {
    setIsModalVisible(false); 
  };

  const handleOpenRewardsModal = async () => {
    setLoading(true);
    try {
      await fetchRewards(); 
      setIsModalVisible(true); 
    } catch (error) {
      console.error('Error fetching rewards for modal:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmCodeUsed = async (rewardId) => {
    try {
      await axiosInstance.put(`/shop-items/rewards/${rewardId}`, {
        is_used: true,
      });
      message.success('Reward marked as used.');
      fetchRewards(); 
    } catch (error) {
      console.error('Error confirming code use:', error);
      message.error('Failed to mark reward as used.');
    }
  };

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code).then(
      () => {
        message.success('Code copied to clipboard!');
      },
      () => {
        message.error('Failed to copy code.');
      }
    );
  };

  const fetchRewards = async () => {
    setLoading(true);
    const userId = localStorage.getItem('user_id'); 
  
    console.log('Debug: Fetching rewards for user_id:', userId);
  
    try {
      const response = await axiosInstance.get(`/shop-items/rewards/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      console.log('Debug: Rewards fetched from API:', response.data);
      setRewards(response.data);
  
      
      const available = response.data.filter((reward) => !reward.is_used).length;
      setAvailableCodes(available); 
    } catch (error) {
      console.error(
        'Debug: Error fetching rewards:',
        error.response?.data || error.message
      );
      message.error(error.response?.data?.error || 'Failed to fetch rewards.');
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    const fetchUserAndNotifications = async () => {
      try {
        
        const userResponse = await axiosInstance.get('/auth/user');
        
        if (userResponse.status === 200) {
          setUser(userResponse.data); 
          
          
          const notificationResponse = await axiosInstance.get('/notifications');
          setNotifications(notificationResponse.data);

          
          fetchNickname(userResponse.data.username);
        } else {
          setUser(null); 
        }
      } catch (error) {
        console.error('Error fetching user or notifications:', error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
    fetchUserAndNotifications();
  }, []);

  const handleMarkAllNotificationsRead = async () => {
    try {
      await axiosInstance.put('/notifications/mark-all-read', {}, {
      });
      setNotifications(notifications.map(notif => ({ ...notif, is_read: true }))); 
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  const handleNotificationClick = async (notif) => {
    try {
      
      await axiosInstance.put(`/notifications/${notif.id}/mark-read`, {}, {
      });

      
      setNotifications(notifications.map(n => (n.id === notif.id ? { ...n, is_read: true } : n)));

      
      if (notif.origin_id && notif.type === 'comment') {
        navigate(`/release/${notif.origin_id}`);
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const notificationMenu = (
    <Menu style={{marginTop:'100px'}}>
      {notifications.length === 0 ? (
        <Menu.Item>No new notifications</Menu.Item>
      ) : (
        notifications.map((notif) => (
          <Menu.Item key={notif.id} onClick={() => handleNotificationClick(notif)}>
            <span style={{ fontWeight: notif.is_read ? 'normal' : 'bold' }}>{notif.message}</span>
          </Menu.Item>
        ))
      )}
      <Menu.Item>
        <Button onClick={handleMarkAllNotificationsRead}>Mark all as read</Button>
      </Menu.Item>
    </Menu>
  );

  const fetchNickname = async (username) => {
    try {
      const response = await axiosInstance.get(`/developer/username/${username}`);
      if (response.data && response.data.nickname) {
        setNickname(response.data.nickname); 

        
        fetchUserProfile(response.data.nickname);
      }
    } catch (error) {
      console.error('Error fetching nickname:', error);
    }
  };

  const fetchUserProfile = async (nickname) => {
    try {
      const response = await axiosInstance.get(`/developer/${nickname}`);
      if (response.data && response.data.user_img_url) {
        setUserImgUrl(response.data.user_img_url); 
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const handleLogout = async () => {
    try {
      
      await axiosInstance.post('/auth/logout', {}, {
        withCredentials: true, 
      });
      
      
      setUser(null);
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };
  

  const isTokenExpired = (decodedToken) => {
    if (!decodedToken || !decodedToken.exp) return true;
    const currentTime = Date.now() / 1000; 
    return decodedToken.exp < currentTime;
  };


useEffect(() => {
  console.log('Updated dailyTasks:', dailyTasks);
}, [dailyTasks]);


useEffect(() => {
  const checkDailyTasks = async () => {
      try {
          await axiosInstance.post('/daily-tasks/check');
          console.log('Daily tasks checked.');
      } catch (error) {
          console.error('Error checking daily tasks:', error);
      }
  };

  checkDailyTasks();
}, []); // Run once when the component mounts


  
  const menuItems = [
    {
      key: 'myprofile',
      label: (
        
        nickname ? (
          <Link to={`/profile/${nickname}`} target="_blank">
            My profile
          </Link>
        ) : (
          <span>Loading profile...</span>
        )
      ),
      icon: <UserOutlined />,
    },
    {
      key: 'myrewards',
      label: (
        <Link onClick={handleOpenRewardsModal}>
          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            My Rewards
            {availableCodes > 0 && (
              <Badge dot
                count={availableCodes}
              />
            )}
          </span>
        </Link>
      ),
      icon: <GiftOutlined />,
    },
    {
      key: 'dailytask',
      label: (
          <Link onClick={handleOpenDailyTaskModal}>
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                  Earn Coins
                  {actionableTasks > 0 && (
                      <Badge dot
                          count={actionableTasks} 
                      />
                  )}
              </span>
          </Link>
      ),
      icon: <CheckCircleOutlined />,
  },
    {
      key: 'controlpanel',
      label: <Link to="/controlpanel">Control Panel</Link>,
      icon: <SettingOutlined />,
    },
    {
      key: 'logout',
      label: 'Logout',
      icon: <LogoutOutlined />,
      onClick: handleLogout,
    },
  ];

  
  const menu = {
    items: menuItems,
  };

  if (loading) {
    return (
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <Avatar size="large" icon={<UserOutlined />} />
        <Text>Loading...</Text>
      </Space>
    );
  }

  const RewardList = ({ rewards, handleCopyCode, handleConfirmCodeUsed, typeLabel, balanceSuffix, tagColor }) => (
    <List
      style={{ maxHeight: '500px', overflow: 'auto', paddingRight: '20px' }}
      itemLayout="vertical"
      dataSource={rewards}
      renderItem={(reward) => {
        const formattedDate = reward.created_at
          ? dayjs(reward.created_at).format('D/MM/YYYY - HH:mm')
          : 'N/A';
  
        return (
          <Card
            style={{ marginBottom: '20px' }}
            title={
              <>
                <Text strong style={{ marginRight: '8px' }}>
                  <GiftFilled /> {reward.item_name}
                </Text>
                <Text type="secondary" style={{fontSize:'12px', fontStyle:'italic', fontWeight:'200'}}>{formattedDate}</Text>
              </>
            }
            extra={
              !reward.is_used ? (
                <Popconfirm
                  title="Confirm you have used this code?"
                  onConfirm={() => handleConfirmCodeUsed(reward.reward_id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tag color='green'>Available</Tag><Button size="small">Mark as Used</Button>
                </Popconfirm>
              ) : (
                <Tag color='red'>Code already used</Tag>
              )
            }
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Text type="secondary">
                <Link to={`/release/${reward.linked_release_id}`}>{reward.linked_release_name}</Link> -{' '}
                <Link to={`/profile/${reward.developer_name}`}>{reward.developer_name}</Link> {' '}
                <Tag color={tagColor}>{reward.balance || 'No balance'}{balanceSuffix} {typeLabel}</Tag>
              </Text>
              
              {reward.code && (
                <Tooltip title="Click to copy the code">
                  <Button icon={<CopyOutlined />} onClick={() => handleCopyCode(reward.code)} size="small">
                    {reward.code}
                  </Button>
                </Tooltip>
              )}
              
            </div>
            
            <Text type="secondary">{reward.description || 'No description provided.'}</Text>
          </Card>
        );
      }}
    />
  );
  

  

  return (
    <div className="user-container">
      {!user ? (
        <Tooltip title="Login with Discord">
          <a href={`${BASE_URL_CB}/api/auth/discord`}>
            <Button style={{ marginLeft: '8px' }}><BsDiscord /> Login with Discord</Button>
          </a>
        </Tooltip>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

          <Dropdown
            menu={menu}
            trigger={['click']}
            placement="bottom"
          >
            <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Avatar
                size={40} 
                shape='square'
                src={userImgUrl ? `${BASE_URL}${userImgUrl}` : user.avatar}
                icon={!userImgUrl && !user.avatar && <UserOutlined />}
              />
              {!collapsed && (
                <Text style={{ marginLeft: '8px' }} strong>
                  {user.username} <DownOutlined />
                  <br></br>
                  <CopyrightCircleTwoTone /> <span style={{fontSize:'12px', fontWeight:'200'}}>{balance !== null ? balance.toLocaleString() : 'Loading...'}</span>
                </Text>

              )}
            </div>
          </Dropdown>
        </div>
      )}

<Modal
  style={{ minWidth: '40%' }}
  title={
    <>
      <GiftFilled /> My Rewards
    </>
  }
  open={isModalVisible}
  onCancel={handleCloseRewardsModal}
  footer={[
    <Button key="close" onClick={handleCloseRewardsModal}>
      Close
    </Button>,
  ]}
>
  {loading ? (
    <Spin />
  ) : rewards.length ? (
    <Tabs defaultActiveKey="discount_codes">
      <Tabs.TabPane tab="Discount Codes" key="discount_codes">
        <RewardList
          rewards={rewards.filter((reward) => reward.item_type === 'discount_code')}
          handleCopyCode={handleCopyCode}
          handleConfirmCodeUsed={handleConfirmCodeUsed}
          typeLabel="Discount"
          balanceSuffix="%"
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Gift Cards" key="gift_cards">
        <RewardList
          rewards={rewards.filter((reward) => reward.item_type === 'balance_card')}
          handleCopyCode={handleCopyCode}
          handleConfirmCodeUsed={handleConfirmCodeUsed}
          typeLabel="Gift Card"
          balanceSuffix="$"
        />
      </Tabs.TabPane>
    </Tabs>
  ) : (
    <Empty description="No rewards available for your account." />
  )}
</Modal>

<Modal
  title="Daily Tasks"
  open={isDailyTaskModalVisible}
  onCancel={handleCloseDailyTaskModal}
  footer={[
    <Button key="close" onClick={handleCloseDailyTaskModal}>
      Close
    </Button>,
  ]}
>
  {taskLoading ? (
    <Spin />
  ) : dailyTasks.length ? (

<List
    itemLayout="horizontal"
    dataSource={dailyTasks}
    renderItem={(task) => (
        <List.Item
            actions={[
                // If task is completed and not yet claimed, show the claim button
                task.is_completed && !task.is_claimed ? (
                    <Button
                        type="primary"
                        onClick={() => claimTaskReward(task.id)}
                    >
                        Claim {task.reward_coins} Coins
                    </Button>
                ) : task.is_claimed ? ( // If claimed, show the "Claimed" tag and cooldown timer
                    <>
                        <Tag color="green">Claimed</Tag>
                        <Tag color="blue">{formatTimeRemaining(task.completed_at)}</Tag>
                    </>
                ) : (
                  <>
<Tag>{task.reward_coins} Coins</Tag>
                    <Tag color="red">Pending</Tag>
                    </> // If not completed, show "Pending"
                )
            ]}
        >
            <List.Item.Meta
                title={task.task_name}
                description={task.description}
            />
        </List.Item>
    )}
/>


  ) : (
    <Empty description="No daily tasks available." />
  )}
</Modal>


    </div>
  );
};



export default User;
