import React, { createContext, useState, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { theme } from 'antd'; // Ant Design theming system

// Create ThemeContext
export const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('theme') ? localStorage.getItem('theme') === 'dark' : true);

  // Toggle between dark and light modes
  const toggleTheme = () => {
    const newTheme = isDarkMode ? 'light' : 'dark';
    setIsDarkMode(!isDarkMode);
    localStorage.setItem('theme', newTheme); // Save user preference
  };

  useEffect(() => {
    document.body.className = isDarkMode ? 'dark-theme' : 'light-theme'; // Add theme class to body
  }, [isDarkMode]);

  // Define theme colors for dark and light modes
  const themeConfig = {
    algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
    token: {

      colorPrimary: '#f5772f',
    },
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      <ConfigProvider theme={themeConfig}>
        {children}
      </ConfigProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
