import React, { useState, useContext } from 'react';
import { ThemeContext } from './utils/ThemeContext';
import { Layout, Menu, Button, Typography, Drawer, Row, Col, Image } from 'antd';
import {
  HomeOutlined,
  FileProtectOutlined,
  InfoCircleOutlined,
  SunOutlined,
  MoonOutlined,
  CodeOutlined,
  TeamOutlined,
  MenuOutlined,
  TrophyOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import User from './user/User';
import { useWindowSize } from 'react-use'; // Import useWindowSize from react-use

const { Header } = Layout;
const { Title } = Typography;

const TopMenu = () => {
  const location = useLocation();
  const { isDarkMode, toggleTheme } = useContext(ThemeContext); // Get the theme state and toggle function
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { width } = useWindowSize(); // Get the window width

  const selectedKey = location.pathname;

  const items = [
    {
      key: '/',
      icon: <HomeOutlined style={{ fontSize: '18px' }} />,
      label: <Link to="/">Home</Link>,
    },
    {
      key: '/ranking',
      icon: <TrophyOutlined style={{ fontSize: '18px' }} />,
      label: <Link to="/ranking">Ranking</Link>,
    },
    {
      key: '/release',
      icon: <CodeOutlined style={{ fontSize: '18px' }} />,
      label: <Link to="/release">Releases</Link>,
    },
    {
      key: '/3',
      icon: <ShopOutlined style={{ fontSize: '18px' }} />,
      label: <Link to="/marketplace">Marketplace</Link>,
    },
    {
      key: '/job',
      icon: <TeamOutlined style={{ fontSize: '18px' }} />,
      label: <Link to="https://discord.gg/kxdVXdaewX">Community</Link>,
    },
  ];

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const isMobile = width <= 1800; // Define the breakpoint for mobile

  return (
    <Header
      style={{
        position: 'sticky',
        backgroundColor: isDarkMode ? '#1f1f1f' : '#ffffff',
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
        zIndex: 1000, // Adjusted zIndex for the header
        padding: '0 20px',
        height:'100%'
      }}
    >
      {isMobile ? (
        <Row gutter={[20, 20]} justify="space-between" align="middle" style={{ width: '100%',  paddingLeft: '15vw', paddingRight: '15vw', }}>
          <Col flex="auto">
            <a href='/'>
            <Image width="50px" src='https://i.imgur.com/3pD2W6V.png'></Image>
            </a>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
            <Button onClick={toggleDrawer} icon={<MenuOutlined />} />
          </Col>
          <Button onClick={toggleTheme}>
              {isDarkMode ? <SunOutlined /> : <MoonOutlined />}
            </Button>
        </Row>
      ) : (
        <Row  style={{ paddingLeft: '15vw', paddingRight: '15vw', width: '100%' }}>
          
          <Row  style={{ width: '100%' }}>
          <Col style={{ display: 'flex', alignItems: 'center', position:'absolute', left:'1%' }}>
          <Link style={{margin:'0px', padding:'0px'}} to={'../'}>
            <Image width={'50px'} src='https://i.imgur.com/3pD2W6V.png'></Image>
            </Link>
          </Col>

          <Col className="desktop-menu" style={{ display: 'flex', flex: 1, justifyContent: 'left', width:'100%'}}>
            <Col style={{    width:'100%'}}>
            <Menu
              mode="horizontal"
              selectedKeys={[selectedKey]}
              theme={isDarkMode ? 'dark' : 'light'}
              items={items}
              style={{ fontSize: '14px', width: '' }}
            /> </Col>
          </Col>
          <Col
          style={{
            display:'flex',
            justifyContent: 'center',
            flexDirection:'column'
          }} >
            <User collapsed={false} />
          </Col>
          <Col style={{position:'absolute', right:'1%'}}>
            <Button onClick={toggleTheme} >
              {isDarkMode ? <SunOutlined /> : <MoonOutlined />}
            </Button>
          </Col>
          </Row>
        </Row>
        
      )}

      {/* Drawer for Mobile Menu */}
      <Drawer
        title={        <User collapsed={false} /> }
        placement="right"
        onClose={toggleDrawer}
        open={drawerVisible}
        style={{ backgroundColor: isDarkMode ? '#1f1f1f' : '#ffffff', zIndex: 2000 , padding: 0}}
      >

        <Menu
          mode="inline"
          selectedKeys={[selectedKey]}
          theme={isDarkMode ? 'dark' : 'light'}
          items={items}
          onClick={toggleDrawer} // Close the drawer when a menu item is clicked
        />

      </Drawer>
      
    </Header>
  );
};

export default TopMenu;
